export default {
  ADD_NOTIFICATION (state, payload) {
    payload.seen = (payload.seen === 'true')
    state.notifications.unshift(payload)
    state.unreadCount += 1
  },
  SET_NOTIFICATION (state, payload) {
    // sort the notification by date
    // state.notifications = payload.sort((a, b) => { return b.timestamp - a.timestamp })
    // state.unreadCount = state.notifications.filter(noti => noti.seen === 'false').length

    for (const noti of payload) {
      noti.ref = JSON.parse(noti.ref)
    }
    state.notifications.push(...payload)
  },
  MARK_SEEN (state) {
    for (const notification of state.notifications) {
      if (notification.seen === false) {
        notification.seen = true
      }
    }
  },
  INCREASE_NEXT_PAGE (state) {
    state.nextPage += 1
  }
}
