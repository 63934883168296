export default {
  interestedChannels: [],
  universityChannels: [],
  courseChannels: [],
  currentChannel: {
    channel_id: 4,
    isSelected: true
  },
  universityChannel: '',
  courseChannel: '',
  courseFeedChannels:[],
  PUBLIC_CHANNEL: {
    channel_id: 4,
    isSelected: true
  },
  suggestedChannels: []
}