export const getSharePost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      author
      authorRef {
        name
        displayName
        email
        isUniEmailVerify
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        interestedChannels
        photoURL
        coverPhotoURL
        followers {
          items {
            user
            follower
          }
          nextToken
        }
        following {
          items {
            user
            follower
          }
          nextToken
        }
        isPartner
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      comments {
        items {
          id
        }
        nextToken
      }
      likes {
        items {
          id
        }
        nextToken
      }
      ogMetaData {
        items {
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      createdAt
      updatedAt
    }
  }
`;