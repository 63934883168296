import state from './channelState'
import mutations from './channelMutations'
import actions from './channelActions'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
