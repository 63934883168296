import { API, graphqlOperation } from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'
import {
  partnerByUser, 
  skillByUser, 
  educationByUser, 
  workExperienceByUser, 
  achievementByUser, 
  projectByUser,
  certificateByUser,
  getUser,
  getFollowing,
  getFollowers } from '@/graphql/queries'
import { 
  createSkill, deleteSkill,
  createEducation, updateEducation, deleteEducation,
  createWorkExperience, updateWorkExperience, deleteWorkExperience,
  createAchievement, updateAchievement, deleteAchievement,
  createProject, updateProject, deleteProject,
  createCertificate, updateCertificate, deleteCertificate,
  updateUser
} from '@/graphql/mutations'
import { getSharePost } from '@/graphql/getSharePost'
import { postsByAuthor } from '@/graphql/postsByAuthor'
import { getCompressImageUrl } from '@/utils'
import axios from 'axios'

const actions = {
  async fetchMyFeeds ({ commit, rootState } , userName) {
    const myFeedsResult = await API.graphql(graphqlOperation(postsByAuthor, {
      author: userName,
      sortDirection: 'DESC',
      filter: {
        isDeleted: {
          eq: false
        }
      }
    }))

    for (const feed of myFeedsResult.data.postsByAuthor.items) {
      feed.mediaFiles = []

      // const res = await axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelById`, {
      //   channel_id: feed.channelID 
      // })

      if (res.data.data[0]) {
        const channelData = res.data.data[0] 
        if (channelData.channel_id === 4) {
          feed.channelName = 'Public'
        } else if (channelData.channel_type !== 'Interests') {
          feed.channelName = channelData.channel_type
        } else {
          feed.channelName = channelData.channel_name
        }
      }

      if (feed.medias.length > 0) {
        const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
        feed.mediaFiles = mediaFiles
      }

      if (feed.video) {
        const videoURL = await Storage.get(feed.video)
        feed.videoURL = videoURL
      }

      if (feed.authorRef.isPartner) {
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: feed.author
        }))
  
        feed.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }

      if (feed.sharePostID) {
        const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
          id: feed.sharePostID
        }))
        if (sharePostRes.data.getPost) {
          if (sharePostRes.data.getPost.medias.length > 0) {
            const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
            sharePostRes.data.getPost.mediaFiles = mediaFiles
          }
  
          if (sharePostRes.data.getPost.video) {
            const videoURL = await Storage.get(sharePostRes.data.getPost.video)
            sharePostRes.data.getPost.videoURL = videoURL
          }

          feed.sharePost = sharePostRes.data.getPost
        }
      }
    }

    commit('SET_MY_FEEDS', {
      feeds: myFeedsResult.data.postsByAuthor.items,
      user: rootState.auth.userData.name
    })
  },
  // fetchEducation ({ commit }, name) {
  //   API.graphql(graphqlOperation(educationByUser, {
  //     user: name,
  //     sortDirection: 'DESC'
  //   })).then(res => {
  //     commit('SET_EDUCATION', res.data.educationByUser.items)
  //   }).catch(err => console.log(err))
  // },
  createEducation ({ commit }, { user, university, degree, from, to, location }) {
    API.graphql(graphqlOperation(createEducation, {
      input: {
        user,
        university,
        degree,
        from,
        to,
        location
      }
    })).then(res => {
      commit('ADD_EDUCATION', res.data.createEducation)
    }).catch(err => console.log(err))
  },
  updateEducation ({ commit }, { id, user, university, degree, from, to, index, location }) {
    API.graphql(graphqlOperation(updateEducation, {
      input: {
        id,
        user,
        university,
        degree,
        from,
        to,
        location
      }
    })).then(res => {
      commit('UPDATE_EDUCATION', { payload: res.data.updateEducation, index })
    }).catch(err => console.log(err))
  },
  deleteEducation ({ commit }, {id, index}) {
    API.graphql(graphqlOperation(deleteEducation, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_EDUCATION', index)
    }).catch(err => console.log(err))
  },
  fetchWorkExperience ({ commit }, name) {
    API.graphql(graphqlOperation(workExperienceByUser, {
      user: name,
      sortDirection: 'DESC'
    })).then(res => {
      commit('SET_EXPERIENCE', res.data.workExperienceByUser.items)
    }).catch(err => console.log(err))
  },
  createExperience ({ commit }, { user, company, position, from, to, description, location }) {
    API.graphql(graphqlOperation(createWorkExperience, {
      input: {
        user,
        company,
        position,
        from,
        to,
        description,
        location
      }
    })).then(res => {
      commit('ADD_EXPERIENCE', res.data.createWorkExperience)
    }).catch(err => console.log(err))
  },
  updateExperience ({ commit }, { id, user, company, position, from, to, description, index, location }) {
    API.graphql(graphqlOperation(updateWorkExperience, {
      input: {
        id, 
        user,
        company,
        position,
        from,
        to,
        description,
        location
      }
    })).then(res => {
      commit('UPDATE_EXPERIENCE', { payload: res.data.updateWorkExperience, index })
    }).catch(err => console.log(err))
  },
  deleteExperience ({ commit }, { id, index }) {
    API.graphql(graphqlOperation(deleteWorkExperience, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_EXPERIENCE', index)
    }).catch(err => console.log(err))
  },
  fetchAchievements ({ commit }, name) {
    API.graphql(graphqlOperation(achievementByUser, {
      user: name,
      sortDirection: 'DESC'
    })).then(res => {
      commit('SET_ACHIEVEMENTS', res.data.achievementByUser.items)
    }).catch(err => console.log(err))
  },
  createAchievement ({ commit }, { user, title, issueBy, issueDate, description, location }) {
    API.graphql(graphqlOperation(createAchievement, {
      input: {
        user,
        title,
        issueBy,
        issueDate,
        description,
        location
      }
    })).then(res => {
      commit('CREATE_ACHIEVEMENT', res.data.createAchievement)
    }).catch(err => console.log(err))
  },
  updateAchievement ({ commit }, { id, user, title, issueBy, issueDate, description, index, location }) {
    API.graphql(graphqlOperation(updateAchievement, {
      input: {
        id,
        user,
        title,
        issueBy,
        issueDate,
        description,
        location
      }
    })).then(res => {
      commit('UPDATE_ACHIEVEMENT', { payload: res.data.updateAchievement, index })
    }).catch(err => console.log(err))
  },
  deleteAchievement ({ commit }, {id, index}) {
    API.graphql(graphqlOperation(deleteAchievement, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_ACHIEVEMENT', index)
    }).catch(err => console.log(err))
  },
  fetchSkills ({ commit }, name) {
    API.graphql(graphqlOperation(skillByUser, {
      user: name
    })).then(res => {
      commit('SET_SKILLS', res.data.skillByUser.items)
    }).catch(err => console.log(err))
  },
  createSkill ({ commit }, { user, skillLabel }) {
    API.graphql(graphqlOperation(createSkill, {
      input: {
        user,
        skillLabel
      }
    })).then(res => {
      commit ('ADD_SKILL', res.data.createSkill)
    }).catch(err => console.log(err))
  },
  deleteSkill ({ commit }, { id, index }) {
    API.graphql(graphqlOperation(deleteSkill, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_SKILL', index)
    })
  },
  fetchProject ({ commit }, name) {
    API.graphql(graphqlOperation(projectByUser, {
      user: name,
      sortDirection: 'DESC'
    })).then(res => {
      commit('SET_PROJECT', res.data.projectByUser.items)
    }).catch(err => {
      console.log(err)
    })
  },
  createProject ({ commit }, { user, name, description, projectURL, startDate, endDate, isWorking, contributors }) {
    API.graphql(graphqlOperation(createProject, {
      input: {
        user,
        name,
        description,
        projectURL,
        startDate,
        endDate,
        contributors,
        isWorking
      }
    })).then(res => {
      commit('ADD_PROJECT', res.data.createProject)
    }).catch(err => {
      console.log(err)
    })
  },
  updateProject ({ commit }, { id, index, name, user, description, projectURL, contributors, startDate, endDate, isWorking }) {
    API.graphql(graphqlOperation(updateProject, {
      input: {
        id,
        user,
        name,
        description,
        startDate,
        endDate,
        projectURL,
        contributors,
        isWorking
      }
    })).then(res => {
      commit('UPDATE_PROJECT', {
        payload: res.data.updateProject,
        index
      })
    }).catch(err => {
      console.log(err)
    })
  },
  deleteProject ({ commit }, { id, index }) {
    API.graphql(graphqlOperation(deleteProject, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_PROJECT', index)
    }).catch(err => console.log(err))
  },
  fetchCertificate ({ commit }, name) {
    API.graphql(graphqlOperation(certificateByUser, {
      user: name
    })).then(res => {
      commit('SET_CERTIFICATES', res.data.certificateByUser.items)
    }).catch(err => console.log(err))
  },
  createCertificate ({ commit, rootState }, { user, name, description, completeDate, studyArea, url, grade}) {
    API.graphql(graphqlOperation(createCertificate, {
      input: {
        user,
        name,
        description,
        completeDate,
        studyArea,
        url,
        grade,
        createdAt: new Date()
      }
    })).then(res => {
      commit('ADD_CERTIFICATE', res.data.createCertificate)
    }).catch(err => console.log(err))
  },
  updateCertificate ({ commit }, { id, index, user, name, description, completeDate, studyArea, url, grade}) {
    API.graphql(graphqlOperation(updateCertificate, {
      input: {
        id, 
        user,
        name, 
        description,
        completeDate,
        studyArea,
        url,
        grade
      }
    })).then(res => {
      commit('UPDATE_CERTIFICATE', { payload: res.data.updateCertificate, index})
    }).catch(err => console.log(err))
  },
  deleteCertificate ({ commit }, { id, index }) {
    API.graphql(graphqlOperation(deleteCertificate, {
      input: {
        id
      }
    })).then(res => {
      commit('DELETE_CERTIFICATE', index)
    }).catch(err => console.log(err))
  },
  fetchProfile ({ commit }, userName) {
    API.graphql(graphqlOperation(getUser, {
      name: userName
    })).then(res => {
      commit('SET_PROFILE', res.data.getUser)
    }).catch(err => console.log(err))
  },
  updateAbout ({ commit }, {userName, about}) {
    API.graphql(graphqlOperation(updateUser, {
      input: {
        name: userName,
        about
      }
    })).then(res => {
      commit('SET_PROFILE', res.data.updateUser)
      commit('auth/UPDATE_USER_INFO', res.data.updateUser, { root: true })
    }).catch(err => console.log(err))
  },
  updateProfileHeader ({ commit }, { userName, headline, location }) {
    API.graphql(graphqlOperation(updateUser, {
      input: {
        name: userName,
        headline,
        location
      }
    })).then(res => {
      commit('SET_PROFILE', res.data.updateUser)
      commit('auth/UPDATE_USER_INFO', res.data.updateUser, { root: true })
    }).catch(err => {
      console.log(err)
    })
  },
  async fetchFollowers ({ commit }, user) {
    try {
      const res = await API.graphql(graphqlOperation(getFollowers, { user }))
      if (res.data.getFollowers && res.data.getFollowers.items) {
        for (const follower of res.data.getFollowers.items) {
          if (follower.followerInstance && follower.followerInstance.isPartner) {
            const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
              user: follower.follower
            }))
      
            follower.followerInstance.partnerData = partnerByUserRes.data.partnerByUser.items[0]
          }
        }
        commit('SET_FOLLOWERS', res.data.getFollowers)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async fetchFollowings ({ commit }, follower) {
    try {
     const res = await API.graphql(graphqlOperation(getFollowing, {
        follower
      }))
      if (res.data.getFollowing && res.data.getFollowing.items) {
        for (const following of res.data.getFollowing.items) {
          if (following.userInstance && following.userInstance.isPartner) {
          const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
            user: following.user
          }))
          following.userInstance.partnerData = partnerByUserRes.data.partnerByUser.items[0]
          }
        }
        commit('SET_FOLLOWINGS', res.data.getFollowing)
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export default actions