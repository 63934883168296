import { API, graphqlOperation } from '@aws-amplify/api'
import { 
  createConversation, 
  createUserConversation,
  createMessage,
  updateMessage, 
  updateUserConversation,
  createGroupConversation,
  createUserGroupConversation,
  updateUserGroupConversation,
  createGroupMessage,
  updateGroupMessage,
  updateGroupConversation
} from '@/graphql/mutations'
import { 
  conversationByUser, 
  listUserConversations,
  messageByConversation,
  messageByGroupConversation,
  groupConversationByUser,
  userByGroupConversation
} from '@/graphql/queries'
import { onCreateMessageByConversationId,
  onCreateGroupMessageByConversationId } from '@/graphql/subscriptions'
import axios from 'axios'

const subscriptions = []

const createMessageNotification = (msg, userName) => {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications.')
  } else if (msg.sender !== userName) {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          const text = `${msg.sender} has send you a message: ${msg.content}`
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification('Coursepal', {
              body: text,
              badge: '/img/icons/monochrome/cp_noti_96x96.png',
              icon: '/img/icons/android-chrome-192x192.png',
              vibrate: [300, 100, 400],
              tag: 'message-noti'
            })
          }).catch(err => console.log(err))
        }
      })
  }
}

const findExistingUserConversation = async (user, receiver) => {
  try {
    const res = await API.graphql(graphqlOperation(listUserConversations, {
      filter: {
        user: {
          eq: user
        },
        receiver: {
          eq: receiver
        }
      }
    }))

    if (res.data.listUserConversations.items.length !== 0) {
      return res.data.listUserConversations.items[0]
    } else {
      return null
    }
  } catch (err) {
    console.log(err)
  }
}

// const getReceiverAbout = async (convo) => {
//   if (convo.receiver) {
//     const aboutRes = await axios.post(`${process.env.VUE_APP_BASE_URL  }/api/users/getUserDetailsBasedOnUserName`, {
//       user_name : convo.receiver
//     })
//     if(convo.receiverRef != null){
//       if (aboutRes.data.status === true) {
//         convo.receiverRef.about = aboutRes.data.data[0].about
//       } else if (convo.receiverRef) {
//         convo.receiverRef.about = ''
//       }
//     }
//   }
// } 

export default {
  setChatSearchQuery ({ commit }, query) {
    commit('SET_CHAT_SEARCH_QUERY', query)
  },
  updateAboutChat ({ commit, rootState }, value) {
    commit('UPDATE_ABOUT_CHAT', {
      rootState,
      value
    })
  },
  updateStatusChat ({ commit, rootState }, value) {
    commit('UPDATE_STATUS_CHAT', {
      rootState,
      value
    })
  },

  sendChatMessage ({ state }, payload) {
    if (!state.currentConvo) return
    if (state.currentConvo.receiverRef) {
      API.graphql(graphqlOperation(createMessage, {
        input: {
          conversationID: state.currentConvo.conversationID,
          sender: state.currentConvo.user,
          content: payload.content,
          isSent: true,
          isSeen: false
        }
      }))
        .catch(err => console.log(err))
    } else if (!state.currentConvo.isBlocked) {
      API.graphql(graphqlOperation(createGroupMessage, {
        input: {
          groupConversationID: state.currentConvo.conversationID,
          sender: state.currentConvo.user,
          content: payload.content,
          isSeen: false,
          isDeleted: false
        }
      })).catch(err => console.log(err))
    } else {
      payload.vs.notify({
        time: 2500,
        title: 'Unable to send message',
        text: 'You have been blocked from this conversation',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        position: 'top-right',
        color: 'danger'
      })
    }
  },

  async getContactList ({ commit, rootState }) {
    // get contact list from api
    try {
      const conversationsRes = await API.graphql(graphqlOperation(conversationByUser, {
        user: rootState.auth.userData.name,
        sortDirection: 'DESC',
        filter: {
          isDeleted: {
            eq: false
          }
        }
      }))
      let items = [];
      for (let index = 0; index < conversationsRes.data.conversationByUser.items.length; index++) {
        const element = conversationsRes.data.conversationByUser.items[index];
        if(element.receiverRef != null){
          items.push(element);
        }        
      }
      conversationsRes.data.conversationByUser.items = items;
      
      for (const convo of conversationsRes.data.conversationByUser.items) {
        const msgRes = await API.graphql(graphqlOperation(messageByConversation, {
          conversationID: convo.conversationID,
          sortDirection: 'DESC',
          limit: 10
        }))
        convo.conversationRef.messages = {}
        convo.conversationRef.messages.items = []
        convo.conversationRef.messages.items = msgRes.data.messageByConversation.items
        convo.conversationRef.messages.items.sort((a, b) => {
          const dateA = new Date(a.createdAt)
          const dateB = new Date(b.createdAt)
          return dateA - dateB
        })
        convo.conversationRef.messages.nextToken = msgRes.data.messageByConversation.nextToken
      }

      const groupConvoRes = await API.graphql(graphqlOperation(groupConversationByUser, {
        user: rootState.auth.userData.name,
        filter: {
          isDeleted: {
            eq: false
          }
        }
      }))

      const promiseRes = groupConvoRes.data.groupConversationByUser.items.map(convo => {
        return API.graphql(graphqlOperation(messageByGroupConversation, {
          groupConversationID: convo.conversationID,
          sortDirection: 'DESC',
          limit: 10
        })).then(msgRes => {
          convo.conversationRef.messages = {}
          convo.conversationRef.messages.items = []
          convo.conversationRef.messages.items = msgRes.data.messageByGroupConversation.items
          convo.conversationRef.messages.items.sort((a, b) => {
            const dateA = new Date(a.createdAt)
            const dateB = new Date(b.createdAt)
            return dateA - dateB
          })
          convo.isGroup = true
          convo.conversationRef.messages.nextToken = msgRes.data.messageByGroupConversation.nextToken
        })
      })

      await Promise.all(promiseRes)
  
      await groupConvoRes.data.groupConversationByUser.items.map(convo => {
        return API.graphql(graphqlOperation(userByGroupConversation, {
          conversationID: convo.conversationID,
          filter: {
            or: [ 
              { isBlocked: { eq: false } }, 
              { isBlocked: { attributeExists: false } }
            ]
          }
        })).then(res => {
          convo.members = res.data.userByGroupConversation.items
            .filter(uc => uc.user !== rootState.auth.userData.name)
            .map(uc => uc.userRef)
        })
      })

      let convos = [...conversationsRes.data.conversationByUser.items, ...groupConvoRes.data.groupConversationByUser.items]

      const emptyConvo = convos.filter(c => {
        return c.conversationRef && c.conversationRef.messages.items.length === 0
      })

      const nonEmptyConvo = convos.filter(c => {
        return c.conversationRef && c.conversationRef.messages.items.length !== 0
      })

      nonEmptyConvo.sort((a, b) => {
        const lengthA = a.conversationRef.messages.items.length
        const lengthB = b.conversationRef.messages.items.length
        const dateA = new Date(a.conversationRef.messages.items[lengthA - 1].createdAt)
        const dateB = new Date(b.conversationRef.messages.items[lengthB - 1].createdAt)
        return dateB - dateA
      })

      convos = [...nonEmptyConvo, ...emptyConvo]
      await Promise.all(convos.map(convo => getReceiverAbout(convo)))
      commit('SET_CONVERSATIONS', convos)
 
    } catch (err) {
      console.log('Error in getting chat data', err)
    }
  },
  subscribeForNewMessages ({ state, commit, rootState }) {
    if (state.convos.length !== 0) {
      state.convos.map(userConversation => {
        if (userConversation.receiverRef) {
          const subscription = API.graphql({
            query: onCreateMessageByConversationId,
            variables: {
              conversationID: userConversation.conversationID
            }
          }).subscribe({
            next: ({ value }) => {
              createMessageNotification(value.data.onCreateMessageByConversationID, rootState.auth.userData.name)
              commit('ADD_MESSAGE', value.data.onCreateMessageByConversationID)
            }
          })
          subscriptions[userConversation.conversationID] = subscription 
        } else {
          const subscription = API.graphql({
            query: onCreateGroupMessageByConversationId,
            variables: {
              groupConversationID: userConversation.conversationID
            }
          }).subscribe({
            next: ({ value }) => {
              createMessageNotification(value.data.onCreateGroupMessageByConversationID, rootState.auth.userData.name)
              commit('ADD_MESSAGE', value.data.onCreateGroupMessageByConversationID)
            }
          })
          subscriptions[userConversation.conversationID] = subscription 
        }
      })
    }
  },
  async createConversation ({ commit, rootState }, userInfo) {
    try {
      // check for existing conversation
      const userConvo = await findExistingUserConversation(rootState.auth.userData.name, userInfo.name)
      if (userConvo) {
        // conversation existed
        // update isDeleted status
        const updateUserConversationRes = await API.graphql(graphqlOperation(updateUserConversation, {
          input: {
            id: userConvo.id,
            isDeleted: false
          }
        }))

        const updatedConvo = updateUserConversationRes.data.updateUserConversation
        updatedConvo.conversationRef.messages = {}
        updatedConvo.conversationRef.messages.items = []

        await getReceiverAbout(updatedConvo)

        // subscribe for new messages
        const subscription = API.graphql({
          query: onCreateMessageByConversationId,
          variables: {
            conversationID: updatedConvo.conversationID
          }
        }).subscribe({
          next: ({ value }) => {
            // notify new message
            createMessageNotification(value.data.onCreateMessageByConversationID, rootState.auth.userData.name)
            commit('ADD_MESSAGE', value.data.onCreateMessageByConversationID)
          }
        })
        subscriptions[updatedConvo.conversationID] = subscription

        commit('ADD_CONVERSATION', updatedConvo)
        commit('SET_CURRENT_CONVERSATION', updatedConvo)

      } else {
        
        // create new conversation
        const createConversationRes = await API.graphql(graphqlOperation(createConversation, {
          input: {}
        }))
        const createUserConversationRes = await API.graphql(graphqlOperation(createUserConversation, {
          input: {
            user: rootState.auth.userData.name,
            receiver: userInfo.name,
            conversationID: createConversationRes.data.createConversation.id,
            isDeleted: false
          }
        }))
  
        const createdConvo = createUserConversationRes.data.createUserConversation
        createdConvo.conversationRef.messages = {}
        createdConvo.conversationRef.messages.items = []

        await getReceiverAbout(createdConvo)

        // create user conversation for receiver instance
        API.graphql(graphqlOperation(createUserConversation, {
          input: {
            user: userInfo.name,
            receiver: rootState.auth.userData.name,
            conversationID: createConversationRes.data.createConversation.id,
            isDeleted: false
          }
        }))

        const subscription = API.graphql({
          query: onCreateMessageByConversationId,
          variables: {
            conversationID: createdConvo.conversationID
          }
        }).subscribe({
          next: ({ value }) => {
            // notify new message
            createMessageNotification(value.data.onCreateMessageByConversationID, rootState.auth.userData.name)
            commit('ADD_MESSAGE', value.data.onCreateMessageByConversationID)
          }
        })
        subscriptions[createdConvo.conversationID] = subscription
        commit('ADD_CONVERSATION', createdConvo)
        commit('SET_CURRENT_CONVERSATION', createdConvo)

      }
    } catch (err) {
      console.log(err)
    }
  },
  removeCurrentConversation ({ commit }, payload) {
    if (!payload.isGroup) {
      API.graphql(graphqlOperation(updateUserConversation, {
        input: {
          id: payload.id,
          isDeleted: true
        }
      })).then(res => {
        subscriptions[res.data.updateUserConversation.conversationID].unsubscribe()
        commit('REMOVE_CURRENT_CONVERSATION', {
          deleted: res.data.updateUserConversation, 
          isGroup: payload.isGroup
        })
      })
    } else {
      API.graphql(graphqlOperation(updateUserGroupConversation, {
        input: {
          id: payload.id,
          isDeleted: true
        }
      })).then(res => {
        subscriptions[res.data.updateUserGroupConversation.conversationID].unsubscribe()
        commit('REMOVE_CURRENT_CONVERSATION', {
          deleted: res.data.updateUserGroupConversation,
          isGroup: payload.isGroup
        })
      })
    }
  },
  selectConvo ({ commit, dispatch }, convo) {
    // update unseen message
    dispatch('updateUnseenMessage', convo)
    commit('SET_CURRENT_CONVERSATION', convo)
  },
  updateUnseenMessage ({ commit, rootState }, convo) {
    if (!convo.conversationRef.messages.items) return 
    const messages = convo.conversationRef.messages.items.filter(msg => msg.sender !== rootState.auth.userData.name)
    if (convo.receiverRef) {
      for (const msg of messages) {
        API.graphql(graphqlOperation(updateMessage, { 
          input: {
            id: msg.id,
            isSeen: true
          }
        })).then(() => {
          commit('UPDATE_MESSAGE_SEEN', msg)
        })
      }
    } else {
      // group message
      for (const msg of messages) {
        API.graphql(graphqlOperation(updateGroupMessage, { 
          input: {
            id: msg.id,
            isSeen: true
          }
        })).then(() => {
          commit('UPDATE_MESSAGE_SEEN', msg)
        })
      }
    }
  },
  createGroupConvo ({ commit, rootState }, { groupName, users }) {
    API.graphql(graphqlOperation(createGroupConversation, {
      input: {
        groupName
      }
    }))
      .then(res => {
        const convoID = res.data.createGroupConversation.id
        const members = []
        const promises = users.map(user => {
          return API.graphql(graphqlOperation(createUserGroupConversation, {
            input: {
              user: user.name,
              conversationID: convoID,
              isDeleted: false,
              isAdmin: false,
              isBlocked: false
            }
          })).then(res => {
            members.push(res.data.createUserGroupConversation.userRef)
          }).catch(err => console.log(err))
        })

        Promise.all(promises).then(() => {
          API.graphql(graphqlOperation(createUserGroupConversation, {
            input: {
              user: rootState.auth.userData.name,
              conversationID: convoID,
              isDeleted: false,
              isAdmin: true,
              isBlocked: false
            }
          })).then(createUserGroupConvoRes => {
            
            const subscription = API.graphql({
              query: onCreateGroupMessageByConversationId,
              variables: {
                groupConversationID: convoID
              }
            }).subscribe({
              next: ({ value }) => {
                // notify new message
                createMessageNotification(value.data.onCreateGroupMessageByConversationID, rootState.auth.userData.name)
                commit('ADD_MESSAGE', value.data.onCreateGroupMessageByConversationID)
              }
            })
            subscriptions[convoID] = subscription
            createUserGroupConvoRes.data.createUserGroupConversation.members = members
            commit('ADD_GROUP_CONVO', createUserGroupConvoRes.data.createUserGroupConversation)
          })
        })
      }).catch(err => console.log(err))
  },
  inviteMoreInGroupChat ({ commit, state }, { groupName, users }) {
    API.graphql(graphqlOperation(updateGroupConversation, {
      input: {
        id: state.currentConvo.conversationID,
        groupName
      }
    })).then(() => {
      const promises = users.map(user => {
        return API.graphql(graphqlOperation(createUserGroupConversation, {
          input: {
            user: user.name,
            conversationID: state.currentConvo.conversationID,
            isDeleted: false,
            isAdmin: false,
            isBlocked: false
          }
        })).then(res => {
          return res.data.createUserGroupConversation.userRef
        })
      })
      Promise.all(promises).then(res => {
        commit('UPDATE_CURRENT_GROUP_CHAT', { members: res, groupName })
      })
    })
  },
  async getChatGroups ({ commit, rootState }) {
    const conversationRes = await API.graphql(graphqlOperation(groupConversationByUser, {
      user: rootState.auth.userData.name,
      filter: {
        isDeleted: {
          eq: false
        }
      }
    }))

    let convos = conversationRes.data.groupConversationByUser.items
      
    const emptyConvo = convos.filter(c => {
      return c.conversationRef.messages.items.length === 0
    })

    const nonEmptyConvo = convos.filter(c => {
      return c.conversationRef.messages.items.length !== 0
    })

    nonEmptyConvo.sort((a, b) => {
      const lengthA = a.conversationRef.messages.items.length
      const lengthB = b.conversationRef.messages.items.length
      const dateA = new Date(a.conversationRef.messages.items[lengthA - 1].createdAt)
      const dateB = new Date(b.conversationRef.messages.items[lengthB - 1].createdAt)
      return dateB - dateA
    })

    convos = [...nonEmptyConvo, ...emptyConvo]

    convos.map(userConversation => {
      const subscription = API.graphql({
        query: onCreateGroupMessageByConversationId,
        variables: {
          groupConversationID: userConversation.conversationID
        }
      }).subscribe({
        next: ({ value }) => {
          createMessageNotification(value.data.onCreateGroupMessageByConversationID, rootState.auth.userData.name)
          commit('ADD_MESSAGE', value.data.onCreateGroupMessageByConversationID)
        }
      })
      subscriptions[userConversation.conversationID] = subscription
    })

    convos.map(convo => {
      return API.graphql(graphqlOperation(userByGroupConversation, {
        conversationID: convo.conversationID,
        filter: {
          or: [ 
            { isBlocked: { eq: false } }, 
            { isBlocked: { attributeExists: false } }
          ]
        }
      })).then(res => {
        convo.members = res.data.userByGroupConversation.items
          .filter(uc => uc.user !== rootState.auth.userData.name)
          .map(uc => uc.userRef)
      })
    })

    commit('SET_GROUP_CONVOS', conversationRes.data.groupConversationByUser.items)
  },
  removeChatState ({ commit }) {
    Promise.all(subscriptions.map(sub => sub.unsubscribe())).then(() => {
      commit('REMOVE_CHAT_STATE')
    })
  },
  blockUserFromGroup ({ commit, state }, user) {
    API.graphql(graphqlOperation(groupConversationByUser, {
      user,
      conversationID: {
        eq: state.currentConvo.conversationID
      }
    })).then(res => {
      console.log('res', res)
      if (res.data.groupConversationByUser.items.length !== 0) {
        API.graphql(graphqlOperation(updateUserGroupConversation, {
          input: {
            id: res.data.groupConversationByUser.items[0].id,
            isBlocked: true
          }
        })).then(blockRes => {
          commit('REMOVE_MEMBER_FROM_CURRENT_GROUP_CHAT', user)
        }).catch(err => console.log('updateUserGroupConversation', err))
      }
    }).catch(err => console.log('groupConversationByUser', err))
  },
  fetchMoreMessageInCurrentConvo ({ commit, state }) {
    if (state.currentConvo.conversationRef.messages.nextToken) {
      if (state.currentConvo.receiverRef) {
        API.graphql(graphqlOperation(messageByConversation, {
          conversationID: state.currentConvo.conversationID,
          sortDirection: 'DESC',
          limit: 10,
          nextToken: state.currentConvo.conversationRef.messages.nextToken
        })).then(res => {
          commit('ADD_MORE_MESSAGE', res.data.messageByConversation)
        })
      } else {
        API.graphql(graphqlOperation(messageByGroupConversation, {
          groupConversationID: state.currentConvo.conversationID,
          sortDirection: 'DESC',
          limit: 10,
          nextToken: state.currentConvo.conversationRef.messages.nextToken
        })).then(res => {
          commit('ADD_MORE_MESSAGE', res.data.messageByGroupConversation)
        })
      }
    }
  }
}
