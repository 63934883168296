export default {
  ADD_CONVERSATION (state, payload) {
    payload.isSelected = false
    state.convos.unshift(payload)
  },
  SET_CONVERSATIONS (state, payload) {
    for (const convo of payload) {
      convo.isSelected = false
      if (!convo.isGroup) {
        convo.isGroup = false
      }
    }
    state.convos = payload
  },
  SET_GROUP_CONVOS (state, payload) {
    for (const group of payload) {
      group.isSelected = false
      group.isGroup = true
    }
    state.convos = state.convos.concat([...payload])
  },
  ADD_GROUP_CONVO (state, payload) {
    payload.isSelected = false
    payload.isGroup = true
    payload.conversationRef.messages.items = []
    state.convos.unshift(payload)
  },
  ADD_MESSAGE (state, payload) {
    if (Object.keys(state.currentConvo).length !== 0 && (state.currentConvo.conversationID === payload.conversationID || state.currentConvo.conversationID === payload.groupConversationID)) {
      state.currentConvo.conversationRef.messages.items.push(payload)
      state.convos.unshift(state.currentConvo)
      state.convos.splice(state.convos.lastIndexOf(state.currentConvo), 1)
    } else {
      for (const uc of state.convos) {
        if (uc.conversationID === payload.conversationID) {
          uc.conversationRef.messages.items.push(payload)
          state.convos.unshift(uc)
          state.convos.splice(state.convos.lastIndexOf(uc), 1)
          break
        }
      }
    }
  },
  ADD_MORE_MESSAGE (state, msg) {
    state.currentConvo.conversationRef.messages.items.unshift(...msg.items)
    state.currentConvo.conversationRef.messages.nextToken = msg.nextToken
  },
  SET_CURRENT_CONVERSATION (state, payload) {
    state.convos.map(c => c.isSelected = false)
    payload.isSelected = true
    state.currentConvo = payload
  },
  REMOVE_CURRENT_CONVERSATION (state, { deleted, isGroup }) {
    state.convos = state.convos.filter(con => con.conversationID !== state.currentConvo.conversationID)
    if (state.convos.length > 0) {
      state.convos[0].isSelected = true
      state.currentConvo = state.convos[0]
    } else {
      state.currentConvo = {}
    }
  },
  UPDATE_MESSAGE_SEEN (state, msg) {
    msg.isSeen = true
  },
  REMOVE_MEMBER_FROM_CURRENT_GROUP_CHAT (state, user) {
    if (state.currentConvo.members) {
      state.currentConvo.members = state.currentConvo.members.filter(member => member.name !== user)
    }
  },
  REMOVE_CHAT_STATE (state) {
    state.currentConvo = {}
    state.convos = []
  },
  UPDATE_CURRENT_GROUP_CHAT (state, { members, groupName }) {
    state.currentConvo.members = state.currentConvo.members.concat(members)
    state.currentConvo.conversationRef.groupName = groupName
  }
}

