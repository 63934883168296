// import searchClient from "@/services/azsearch.service";

const actions = {
  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage ({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo ({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateUserRole ({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {userRole: payload.userRole})
  },

  // Note: Copied From - coursepal_courselising-web
      // eslint-disable-next-line no-unused-vars
      // executeSearch({ state, commit }) {
      //   searchClient.search(
      //     'coursepal-courses',
      //     {
      //       search: `${state.searchString}`,
      //       filter: `${state.filterString}`,
      //       top: state.resultsPerPage,
      //       skip: (state.currentPage - 1) * state.resultsPerPage,
      //       count: true,
      //       facets: ['study_area', 'course_type', 'course_category', 'city', 'university','campus','study_method'],
      //     },
      //     (err, results, raw) => {
      //       commit('SET_RESULTS', results);
      //       commit('SET_RESULTS_COUNT', raw['@odata.count']);
      //       commit('SET_FACETS', raw['@search.facets']);
      //     },
      //   );
      // },
      // setSearchString({ dispatch, commit }, value = '*') {
      //   commit('SET_SEARCHSTRING', value);
      //   dispatch('executeSearch');
      // },
      // setFilter({ dispatch, commit }, payload) {
      //   commit('SET_FILTERS', payload);
      //   dispatch('executeSearch');
      // },
    
      // clearFilters({ commit }) {
      //   commit('CLEAR_FILTERS');
      // },
      // setCurrentPage({ dispatch, commit }, page) {
      //   commit('SET_CURRENT_PAGE', page);
      //   dispatch('executeSearch');
      // },
    
      // setResultsPerPage({ dispatch, commit }, count) {
      //   commit('SET_RESULTS_PER_PAGE', count);
      //   dispatch('executeSearch');
      // },
      
      // setcourseUpdateId(context, eventid) {
      //   localStorage.setItem("pCourseId", eventid);

      // },

      // setpubUserId(context, eventid) {
      //   localStorage.setItem("pCoursepalUserObj", pub_userid);

      // },
      
}

export default actions
