export default {
  myFeeds: [],
  skills: [],
  suggestSkillList: [],
  education: [],
  experience: [],
  achievements: [],
  projects: [],
  certificates: [],
  followers: {
    items: []
  },
  followings: {
    items: []
  },
  profile: {},
  isUniEmailVerify: false
}