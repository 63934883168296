export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          items {
            follower
          }
          nextToken
        }
        following {
          items {
            user
          }
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      comments {
        items {
          id
          author
          authorRef {
            name
            displayName
            photoURL
            followers {
              items {
                follower
              }
              nextToken
            }
            following {
              items {
                user
              }
              nextToken
            }
          }
          subComments {
              items {
                id
                author
                authorRef {
                  displayName
                  photoURL
                  universityName
                  studyStatus
                  about
                  isPartner
                  followers {
                    items {
                      follower
                    }
                    nextToken
                  }
                  following {
                    items {
                      user
                    }
                    nextToken
                  }
                }
                content
                likes {
                  items {
                    id
                    user
                    subCommentID
                  }
                }
                isDeleted
              }
              nextToken
            }
          likes {
            items {
              id
              user
              commentID
            }
          }
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      mintSupply
      isMinting
      isListed
      pricePerUnit
      mintSupplySold
      mintTokenId
      nftCategory
    }
  }
`;