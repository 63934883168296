import auth from '@/auth/authService'
import firebase from 'firebase/app'
import 'firebase/auth'

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('userInfo')) || {}
}
const getPartnerData = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (userInfo && userInfo.isPartner) {
    return JSON.parse(localStorage.getItem('partnerData')) || {
      user: '',
      phone: '',
      overview: '',
      website: '',
      partnerType: '',
      mainOffice: '',
      companySize: 0,
      industry: '',
      organizationType: '',
      founded: 0,
      specialities: ''
    }
  } else {
    return {}
  }
}
export default {
  isUserLoggedIn: () => {
    let isAuthenticated = false
    if (auth.isAuthenticated()) isAuthenticated = true
    else isAuthenticated = false

    return localStorage.getItem('userInfo') && isAuthenticated
  },
  userData: getCurrentUser(),
  partnerData: getPartnerData(),
  universityInfo: {},
  email: '',
  uniEmail: '',
  isUniEmailVerified: false,
  isConnected:false,
  walletAccount:''
}
