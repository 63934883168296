/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByConversationId = /* GraphQL */ `
  subscription OnCreateMessageByConversationId($conversationID: ID!) {
    onCreateMessageByConversationID(conversationID: $conversationID) {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupMessageByConversationId = /* GraphQL */ `
  subscription OnCreateGroupMessageByConversationId($groupConversationID: ID!) {
    onCreateGroupMessageByConversationID(
      groupConversationID: $groupConversationID
    ) {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEducatorSessionBookingByUser = /* GraphQL */ `
  subscription OnUpdateEducatorSessionBookingByUser($user: String!) {
    onUpdateEducatorSessionBookingByUser(user: $user) {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      stripeConnectedAccountKey
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      stripeConnectedAccountKey
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      stripeConnectedAccountKey
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUniEmailVerify = /* GraphQL */ `
  subscription OnCreateUniEmailVerify {
    onCreateUniEmailVerify {
      id
      user
      code
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUniEmailVerify = /* GraphQL */ `
  subscription OnUpdateUniEmailVerify {
    onUpdateUniEmailVerify {
      id
      user
      code
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUniEmailVerify = /* GraphQL */ `
  subscription OnDeleteUniEmailVerify {
    onDeleteUniEmailVerify {
      id
      user
      code
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSkill = /* GraphQL */ `
  subscription OnCreateSkill {
    onCreateSkill {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSkill = /* GraphQL */ `
  subscription OnUpdateSkill {
    onUpdateSkill {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSkill = /* GraphQL */ `
  subscription OnDeleteSkill {
    onDeleteSkill {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEducation = /* GraphQL */ `
  subscription OnCreateEducation {
    onCreateEducation {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEducation = /* GraphQL */ `
  subscription OnUpdateEducation {
    onUpdateEducation {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEducation = /* GraphQL */ `
  subscription OnDeleteEducation {
    onDeleteEducation {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkExperience = /* GraphQL */ `
  subscription OnCreateWorkExperience {
    onCreateWorkExperience {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkExperience = /* GraphQL */ `
  subscription OnUpdateWorkExperience {
    onUpdateWorkExperience {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkExperience = /* GraphQL */ `
  subscription OnDeleteWorkExperience {
    onDeleteWorkExperience {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAchievement = /* GraphQL */ `
  subscription OnCreateAchievement {
    onCreateAchievement {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAchievement = /* GraphQL */ `
  subscription OnUpdateAchievement {
    onUpdateAchievement {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAchievement = /* GraphQL */ `
  subscription OnDeleteAchievement {
    onDeleteAchievement {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePartner = /* GraphQL */ `
  subscription OnCreatePartner {
    onCreatePartner {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePartner = /* GraphQL */ `
  subscription OnUpdatePartner {
    onUpdatePartner {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePartner = /* GraphQL */ `
  subscription OnDeletePartner {
    onDeletePartner {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEducator = /* GraphQL */ `
  subscription OnCreateEducator {
    onCreateEducator {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEducator = /* GraphQL */ `
  subscription OnUpdateEducator {
    onUpdateEducator {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEducator = /* GraphQL */ `
  subscription OnDeleteEducator {
    onDeleteEducator {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const onCreateExpertise = /* GraphQL */ `
  subscription OnCreateExpertise {
    onCreateExpertise {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExpertise = /* GraphQL */ `
  subscription OnUpdateExpertise {
    onUpdateExpertise {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExpertise = /* GraphQL */ `
  subscription OnDeleteExpertise {
    onDeleteExpertise {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEducatorService = /* GraphQL */ `
  subscription OnCreateEducatorService {
    onCreateEducatorService {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const onUpdateEducatorService = /* GraphQL */ `
  subscription OnUpdateEducatorService {
    onUpdateEducatorService {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const onDeleteEducatorService = /* GraphQL */ `
  subscription OnDeleteEducatorService {
    onDeleteEducatorService {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const onCreateEducatorServicePackage = /* GraphQL */ `
  subscription OnCreateEducatorServicePackage {
    onCreateEducatorServicePackage {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEducatorServicePackage = /* GraphQL */ `
  subscription OnUpdateEducatorServicePackage {
    onUpdateEducatorServicePackage {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEducatorServicePackage = /* GraphQL */ `
  subscription OnDeleteEducatorServicePackage {
    onDeleteEducatorServicePackage {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEducatorSessionBooking = /* GraphQL */ `
  subscription OnCreateEducatorSessionBooking {
    onCreateEducatorSessionBooking {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const onUpdateEducatorSessionBooking = /* GraphQL */ `
  subscription OnUpdateEducatorSessionBooking {
    onUpdateEducatorSessionBooking {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const onDeleteEducatorSessionBooking = /* GraphQL */ `
  subscription OnDeleteEducatorSessionBooking {
    onDeleteEducatorSessionBooking {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const onCreateSearchedToken = /* GraphQL */ `
  subscription OnCreateSearchedToken {
    onCreateSearchedToken {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSearchedToken = /* GraphQL */ `
  subscription OnUpdateSearchedToken {
    onUpdateSearchedToken {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSearchedToken = /* GraphQL */ `
  subscription OnDeleteSearchedToken {
    onDeleteSearchedToken {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReportPost = /* GraphQL */ `
  subscription OnCreateReportPost {
    onCreateReportPost {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReportPost = /* GraphQL */ `
  subscription OnUpdateReportPost {
    onUpdateReportPost {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReportPost = /* GraphQL */ `
  subscription OnDeleteReportPost {
    onDeleteReportPost {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOgMetaData = /* GraphQL */ `
  subscription OnCreateOgMetaData {
    onCreateOgMetaData {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOgMetaData = /* GraphQL */ `
  subscription OnUpdateOgMetaData {
    onUpdateOgMetaData {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOgMetaData = /* GraphQL */ `
  subscription OnDeleteOgMetaData {
    onDeleteOgMetaData {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubComment = /* GraphQL */ `
  subscription OnCreateSubComment {
    onCreateSubComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSubComment = /* GraphQL */ `
  subscription OnUpdateSubComment {
    onUpdateSubComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSubComment = /* GraphQL */ `
  subscription OnDeleteSubComment {
    onDeleteSubComment {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLike = /* GraphQL */ `
  subscription OnCreateLike {
    onCreateLike {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLike = /* GraphQL */ `
  subscription OnUpdateLike {
    onUpdateLike {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLike = /* GraphQL */ `
  subscription OnDeleteLike {
    onDeleteLike {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeComment = /* GraphQL */ `
  subscription OnCreateLikeComment {
    onCreateLikeComment {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLikeComment = /* GraphQL */ `
  subscription OnUpdateLikeComment {
    onUpdateLikeComment {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLikeComment = /* GraphQL */ `
  subscription OnDeleteLikeComment {
    onDeleteLikeComment {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeSubComment = /* GraphQL */ `
  subscription OnCreateLikeSubComment {
    onCreateLikeSubComment {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLikeSubComment = /* GraphQL */ `
  subscription OnUpdateLikeSubComment {
    onUpdateLikeSubComment {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLikeSubComment = /* GraphQL */ `
  subscription OnDeleteLikeSubComment {
    onDeleteLikeSubComment {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationComment = /* GraphQL */ `
  subscription OnCreateNotificationComment {
    onCreateNotificationComment {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationComment = /* GraphQL */ `
  subscription OnUpdateNotificationComment {
    onUpdateNotificationComment {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationComment = /* GraphQL */ `
  subscription OnDeleteNotificationComment {
    onDeleteNotificationComment {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationSubComment = /* GraphQL */ `
  subscription OnCreateNotificationSubComment {
    onCreateNotificationSubComment {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationSubComment = /* GraphQL */ `
  subscription OnUpdateNotificationSubComment {
    onUpdateNotificationSubComment {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationSubComment = /* GraphQL */ `
  subscription OnDeleteNotificationSubComment {
    onDeleteNotificationSubComment {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationFollow = /* GraphQL */ `
  subscription OnCreateNotificationFollow {
    onCreateNotificationFollow {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationFollow = /* GraphQL */ `
  subscription OnUpdateNotificationFollow {
    onUpdateNotificationFollow {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationFollow = /* GraphQL */ `
  subscription OnDeleteNotificationFollow {
    onDeleteNotificationFollow {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFirebaseMessaging = /* GraphQL */ `
  subscription OnCreateFirebaseMessaging {
    onCreateFirebaseMessaging {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFirebaseMessaging = /* GraphQL */ `
  subscription OnUpdateFirebaseMessaging {
    onUpdateFirebaseMessaging {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFirebaseMessaging = /* GraphQL */ `
  subscription OnDeleteFirebaseMessaging {
    onDeleteFirebaseMessaging {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFollow = /* GraphQL */ `
  subscription OnCreateFollow {
    onCreateFollow {
      user
      follower
      userInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFollow = /* GraphQL */ `
  subscription OnUpdateFollow {
    onUpdateFollow {
      user
      follower
      userInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFollow = /* GraphQL */ `
  subscription OnDeleteFollow {
    onDeleteFollow {
      user
      follower
      userInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserConversation = /* GraphQL */ `
  subscription OnCreateUserConversation {
    onCreateUserConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserConversation = /* GraphQL */ `
  subscription OnUpdateUserConversation {
    onUpdateUserConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserConversation = /* GraphQL */ `
  subscription OnDeleteUserConversation {
    onDeleteUserConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserGroupConversation = /* GraphQL */ `
  subscription OnCreateUserGroupConversation {
    onCreateUserGroupConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserGroupConversation = /* GraphQL */ `
  subscription OnUpdateUserGroupConversation {
    onUpdateUserGroupConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserGroupConversation = /* GraphQL */ `
  subscription OnDeleteUserGroupConversation {
    onDeleteUserGroupConversation {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupConversation = /* GraphQL */ `
  subscription OnCreateGroupConversation {
    onCreateGroupConversation {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupConversation = /* GraphQL */ `
  subscription OnUpdateGroupConversation {
    onUpdateGroupConversation {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupConversation = /* GraphQL */ `
  subscription OnDeleteGroupConversation {
    onDeleteGroupConversation {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupMessage = /* GraphQL */ `
  subscription OnCreateGroupMessage {
    onCreateGroupMessage {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupMessage = /* GraphQL */ `
  subscription OnUpdateGroupMessage {
    onUpdateGroupMessage {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupMessage = /* GraphQL */ `
  subscription OnDeleteGroupMessage {
    onDeleteGroupMessage {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookmarkJob = /* GraphQL */ `
  subscription OnCreateBookmarkJob {
    onCreateBookmarkJob {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookmarkJob = /* GraphQL */ `
  subscription OnUpdateBookmarkJob {
    onUpdateBookmarkJob {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookmarkJob = /* GraphQL */ `
  subscription OnDeleteBookmarkJob {
    onDeleteBookmarkJob {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJob = /* GraphQL */ `
  subscription OnCreateJob {
    onCreateJob {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const onUpdateJob = /* GraphQL */ `
  subscription OnUpdateJob {
    onUpdateJob {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const onDeleteJob = /* GraphQL */ `
  subscription OnDeleteJob {
    onDeleteJob {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventBookmark = /* GraphQL */ `
  subscription OnCreateEventBookmark {
    onCreateEventBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventBookmark = /* GraphQL */ `
  subscription OnUpdateEventBookmark {
    onUpdateEventBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventBookmark = /* GraphQL */ `
  subscription OnDeleteEventBookmark {
    onDeleteEventBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourseBookmark = /* GraphQL */ `
  subscription OnCreateCourseBookmark {
    onCreateCourseBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourseBookmark = /* GraphQL */ `
  subscription OnUpdateCourseBookmark {
    onUpdateCourseBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourseBookmark = /* GraphQL */ `
  subscription OnDeleteCourseBookmark {
    onDeleteCourseBookmark {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCertificate = /* GraphQL */ `
  subscription OnCreateCertificate {
    onCreateCertificate {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCertificate = /* GraphQL */ `
  subscription OnUpdateCertificate {
    onUpdateCertificate {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCertificate = /* GraphQL */ `
  subscription OnDeleteCertificate {
    onDeleteCertificate {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCourseFeed = /* GraphQL */ `
  subscription OnCreateCourseFeed {
    onCreateCourseFeed {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const onUpdateCourseFeed = /* GraphQL */ `
  subscription OnUpdateCourseFeed {
    onUpdateCourseFeed {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const onDeleteCourseFeed = /* GraphQL */ `
  subscription OnDeleteCourseFeed {
    onDeleteCourseFeed {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const onCreateCourseFeedSubscriber = /* GraphQL */ `
  subscription OnCreateCourseFeedSubscriber {
    onCreateCourseFeedSubscriber {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourseFeedSubscriber = /* GraphQL */ `
  subscription OnUpdateCourseFeedSubscriber {
    onUpdateCourseFeedSubscriber {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourseFeedSubscriber = /* GraphQL */ `
  subscription OnDeleteCourseFeedSubscriber {
    onDeleteCourseFeedSubscriber {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTopic = /* GraphQL */ `
  subscription OnCreateTopic {
    onCreateTopic {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTopic = /* GraphQL */ `
  subscription OnUpdateTopic {
    onUpdateTopic {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTopic = /* GraphQL */ `
  subscription OnDeleteTopic {
    onDeleteTopic {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
