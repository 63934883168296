import state from './notificationState.js'
import mutations from './notificationMutations.js'
import actions from './notificationActions'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
