import state from './profileState.js'
import mutations from './profileMutations.js'
import actions from './profileActions.js'
import getters from './profileGetters'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
