/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://xxd24ovsjzdupl6qdmgcnun3hi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-v55jnq4xxnfg5aczzjybxkmqsu",
    "aws_cloud_logic_custom": [
        {
            "name": "cpRestAPI",
            "endpoint": "https://l2gl5yt9wd.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:2097f313-ff4d-4bf7-8353-96ec803277a0",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_iUnOVM06Q",
    "aws_user_pools_web_client_id": "39r1pe0jtvui1kdutfmvtr1f86",
    "oauth": {
        "domain": "coursepalconnect9941d786-9941d786-production.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://mypals.app/login",
        "redirectSignOut": "https://mypals.app/login",
    
      
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "coursepalconnectbucket165351-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
