import { API, graphqlOperation } from 'aws-amplify'
import { getSuggestedFollows, 
  getUser,
  getCourseFeedSubscriber, 
  getFollowers, getFollowing, partnerByUser, courseFeedSubscriptionByCourseFeed } from '@/graphql/queries'
import { createFollow, deleteFollow,
  createNotificationFollow } from '@/graphql/mutations'
import axios from 'axios'

const actions = {
  async fetchSuggestedFollows ({ commit, rootState }, { channelID, channelType }) {
    const { name, university, course } = rootState.auth.userData
    const res = await API.graphql(graphqlOperation(getSuggestedFollows, {
      user: name,
      university,
      course,
      channelID,
      channelType
    }))
    for (const user of res.data.getSuggestedFollows) {
      if (user.isPartner) {
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: user.name
        }))
  
        user.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }
    }
    commit('SET_SUGGESTED_FOLLOWS', { items: res.data.getSuggestedFollows }) 
  },
  async fetchCourseFeedSuggestFollow ({ commit }, { id }) {
    try {
      const res = await API.graphql(graphqlOperation(getCourseFeedSubscriber, {
        id
      }))

      const cfRes = await  API.graphql(graphqlOperation(courseFeedSubscriptionByCourseFeed, {
        courseFeed: res.data.getCourseFeedSubscriber.courseFeed
      }))

    

      for (const cf of cfRes.data.courseFeedSubscriptionByCourseFeed.items) {
        if (cf.userRef.isPartner) {
          const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
            user: cf.user
          }))
    
          cf.userRef.partnerData = partnerByUserRes.data.partnerByUser.items[0]
        }
      }
      commit('SET_SUGGESTED_FOLLOWS', { items: cfRes.data.courseFeedSubscriptionByCourseFeed.items.map(item => item.userRef) }) 
    } catch (err) {
      console.log('fetchCourseFeedSuggestFollow', err)
    }
  },
  followPartner ({ commit, rootState }, partnerName) {
    API.graphql(graphqlOperation(createFollow, {
      input: {
        user: partnerName,
        follower: rootState.auth.userData.name
      }
    }))
      .then(() => {
        // update list of follower in userData
        API.graphql(graphqlOperation(getUser, { name: rootState.auth.userData.name }))
          .then(res => {
            commit('auth/UPDATE_USER_INFO', res.data.getUser, { root: true })
          })
      })
      .catch(err => console.log(err))
  },
  followUser ({ commit, rootState }, user) {
    API.graphql(graphqlOperation(createFollow, {
      input: {
        user,
        follower: rootState.auth.userData.name
      }
    }))
      .then(() => {
        API.graphql(graphqlOperation(getUser, { name: rootState.auth.userData.name }))
          .then(getUserRes => {
            commit('auth/UPDATE_USER_INFO', getUserRes.data.getUser, { root: true })
          })
      })
      .catch(err => console.log(err))
  },
  followUserInSearch ({ commit, rootState }, friend) {
    API.graphql(graphqlOperation(createFollow, {
      input: {
        user: friend.name,
        follower: rootState.auth.userData.name
      }
    }))
      .then(() => {
        // update list of follower in userData
        API.graphql(graphqlOperation(getUser, { name: rootState.auth.userData.name }))
          .then(res => {
            commit('auth/UPDATE_USER_INFO', res.data.getUser, { root: true })
          })
      })
      .catch(err => console.log(err))
  },
  followUserInSuggestedList ({ commit, rootState }, { friend, index }) {
    API.graphql(graphqlOperation(createFollow, {
      input: {
        user: friend.name,
        follower: rootState.auth.userData.name
      }
    }))
      .then(() => {
        API.graphql(graphqlOperation(createNotificationFollow, {
          input: {
            type: 'FOLLOW',
            seen: false,
            sender: rootState.auth.userData.name,
            receiver: friend.name,
            timestamp: Date.now()
          }
        }))
          .then(res => {
            axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, res.data.createNotificationFollow)
              .catch(err =>  commit('SET_ERROR', err, { root: true }))
            commit('REMOVE_SUGGESTED_FOLLOW', index)
          })
      })
      .catch(err => console.log(err))
  },
  unfollowCurrentAuthor ({ rootState, commit }, author) {
    API.graphql(graphqlOperation(deleteFollow, {
      input: {
        user: author,
        follower: rootState.auth.userData.name
      }
    }))
      .then(() => {
        API.graphql(graphqlOperation(getUser, { name: rootState.auth.userData.name }))
          .then(res => commit('auth/UPDATE_USER_INFO', res.data.getUser, {root: true}))
      })
      .catch(err => console.log(err))
  },
  async fetchFollowers ({ commit, rootState }) {
    const res = await API.graphql(graphqlOperation(getFollowers, {
      user: rootState.auth.userData.name
    }))

    for (const follower of res.data.getFollowers.items) {
      if (follower.followerInstance && follower.followerInstance.isPartner) {
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: follower.follower
        }))
  
        follower.followerInstance.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }
    }

    commit('SET_FOLLOWERS', res.data.getFollowers)
  },
  async fetchFollowings ({ commit, rootState }) {
    const res = await API.graphql(graphqlOperation(getFollowing, {
      follower: rootState.auth.userData.name
    }))

    for (const following of res.data.getFollowing.items) {
      if (following.userInstance && following.userInstance.isPartner) {
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: following.user
        }))
        following.userInstance.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }
    }

    commit('SET_FOLLOWINGS', res.data.getFollowing)
  }
}

export default actions