import state from './followState.js'
import mutations from './followMutations.js'
import actions from './followActions.js'
import getters from './followGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
