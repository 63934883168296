export const updateSubComment = /* GraphQL */ `
  mutation UpdateSubComment(
    $input: UpdateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    updateSubComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        studyStatus
        photoURL
        coverPhotoURL
        followers {
          items {
            follower
          }
          nextToken
        }
        following {
          items {
            user
          }
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        createdAt
        updatedAt
      }
      content
      commentID
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;