export const postsByAuthor = /* GraphQL */ `
  query PostsByAuthor(
    $author: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByAuthor(
      author: $author
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        # isMinting
        # mintTokenId
        authorRef {
          name
          isUniEmailVerify
          displayName
          # email
          # university
          # universityName
          studyArea
          studyAreaName
          course
          courseName
          followers {
            items {
              follower
            }
          }
          following {
            items {
              user
            }
          }
          studyStatus
          about
          interestedChannels
          photoURL
          isPartner
          createdAt
          updatedAt
        }
        content
        medias
        comments {
        items {
          id
          author
          authorRef {
            displayName
            photoURL
            universityName
            studyStatus
            about
            followers {
              items {
                user
                follower
              }
              nextToken
            }
            following {
              items {
                user
                follower
              }
              nextToken
            }
          }
          content
          subComments {
            items {
              id
              author
              authorRef {
                displayName
                photoURL
                universityName
                studyStatus
                about
                followers {
                  items {
                    follower
                  }
                  nextToken
                }
                following {
                  items {
                    user
                  }
                  nextToken
                }
              }
              content
              likes {
                items {
                  id
                  user
                  subCommentID
                }
              }
              isDeleted
            }
            nextToken
          }
          likes {
            items {
              id
              user
              commentID
            }
          }
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
        }
        likes {
          items {
            id
            user
            postID
            createdAt
            updatedAt
          }
          nextToken
        }
        timestamp
        isDeleted
        channelID
        createdAt
        updatedAt
        video
        ogMetaData {
          items {
            id
            postID
            ogTitle
            ogDescription
            ogVideoUrl
            ogVideoHeight
            ogVideoWidth
            ogVideoType
            ogImageUrl
            ogImageHeight
            ogImageWidth
            ogImageType
            ogDate
            ogSiteName
            ogUrl
            requestUrl
            createdAt
            updatedAt
          }
          nextToken
        }
        
      }
      nextToken
    }
  }
`;