import state from './authState.js'
import mutations from './authMutations.js'
import actions from './authActions.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
