
import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import auth from './auth/auth'
import newsfeed from './newsfeed/newsfeed'
import notification from './notification/notification'
// import universityList from './universityList/universityList'
import follow from './follow/follow'
import channel from './channel/channel'
import search from './search/search'
// import job from './job/job'
import chat from './chat/chat'
import profile from './profile/profile'
// import course from './course/course'
// import event from './event/event'
// import zmSession from './zoom-session/zmSession'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth,
    newsfeed,
    notification,
    // universityList,
    follow,
    channel,
    search,
    // job,
    chat,
    profile,
    // course,
    // event,
    // zmSession
  },
  strict: process.env.NODE_ENV !== 'production'
})
