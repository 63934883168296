import { API, graphqlOperation } from 'aws-amplify'
import { searchedTokenByUser } from '../../graphql/queries'
import { createSearchedToken, updateSearchedToken } from '../../graphql/mutations'

const actions = {
  fetchSearchedTokens ({ commit, rootState }) {
    API.graphql(graphqlOperation(searchedTokenByUser, {
      user: rootState.auth.userData.name
    }))
      .then(res => {
        if (res.data.searchedTokenByUser.items.length === 0) {
          // create instance
          API.graphql(graphqlOperation(createSearchedToken, { input: {
            user: rootState.auth.userData.name,
            tokens: [],
            timestamp: Date.now()
          }}))
        } else {
          commit('SET_SEARCHED_TOKENS', res.data.searchedTokenByUser.items[0])
        }
      })
      .catch(err => {
        commit('SET_ERROR', err, { root: true })
      })
  },
  updateSearchedToken ({ commit, rootState, state }, token) {
    const searchTokens = [...state.searchedTokens, token]
    const uniqueTokens = searchTokens.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    API.graphql(graphqlOperation(updateSearchedToken, { input: {
      id: state.searchTokensID,
      user: rootState.auth.userData.name,
      tokens: uniqueTokens
    }}))
      .then(res => {
        commit('SET_SEARCHED_TOKENS', res.data.updateSearchedToken)
      })
      .catch(err =>  commit('SET_ERROR', err, { root: true }))
  }
}

export default actions
