import Vue from 'vue'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'
Amplify.configure(aws_exports)




// Auth redirect issue fix

// import awsconfig from "./aws-exports";

// awsconfig.oauth.redirectSignIn = `${window.location.origin}/login/`;
// awsconfig.oauth.redirectSignOut = `${window.location.origin}/login/`;

// Amplify.configure(awsconfig);

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-167580936-1'
});
 
// Vue-tour
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

// Configuration Vue Social Sharing
Vue.use(VueSocialSharing, {
  networks: {
    fakeblock: 'https://mypals.app/post?url=@url',
  }
})

// ImageUploader
import ImageUploader from 'vue-image-upload-resize'
Vue.use(ImageUploader);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);




// vue-loading-overlay
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
// Vue.component('loading-overlay', Loading)


import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Custom CSS
import '@/assets/css/custom.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'

// Firebase
import '@/firebase/firebaseConfig' 



// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// // Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import './registerServiceWorker'

// Feather font icon
require('./assets/css/iconfont.css')



import VueRx from 'vue-rx'
Vue.use(VueRx)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Vue.config.productionTip = false
// import ZoomVideo from '@zoom/videosdk'
// const client = ZoomVideo.createClient()
// client.init('en-US', `${window.location.origin}/lib`)
// Vue.prototype.$zmClient = client

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

/* 
what situation require offline syncing

create | remove | update  post | comment | sub-comment | like

what are needed to synchronize ?

feeds from subscribed channels


*/