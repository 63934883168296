
export const getNewsfeed = /* GraphQL */ `
query GetNewsfeed(
  $name: String
  $channelID: String
  $limit: Int
  $postNextToken: String
  $likeNextToken: String
  $commentNextToken: String
) {
  getNewsfeed(
    name: $name
    channelID: $channelID
    limit: $limit
    postNextToken: $postNextToken
    likeNextToken: $likeNextToken
    commentNextToken: $commentNextToken
  ) {
    newsfeed {
    actor
    action
    postRef {
      id
      author
      video       
      authorRef {
        # enableNftNotification
        # enablePostNotification
        # stripeAccountKey
        # stripeConnectedAccountKey
         name
        displayName
        isUniEmailVerify
        # email
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        interestedChannels
        photoURL
        followers {
          items {
            user
            follower
          }
          nextToken
        }
        following {
          items {
            user
            follower
          }
          nextToken
        }
        isPartner
        createdAt
        updatedAt
      }
      content    
      medias
      
      
      comments {
        items {
          id
          author
          authorRef {
            displayName
            photoURL
            universityName
            isUniEmailVerify
            studyStatus
            about
            isPartner
            followers {
              items {
                user
                follower
              }
              nextToken
            }
            following {
              items {
                user
                follower
              }
              nextToken
            }
          }
          content
          subComments {
            items {
              id
              author
              authorRef {
                displayName
                photoURL
                universityName
                isUniEmailVerify
                studyStatus
                about
                isPartner
                followers {
                  items {
                    follower
                  }
                  nextToken
                }
                following {
                  items {
                    user
                  }
                  nextToken
                }
              }
              content
              likes {
                items {
                  id
                  user
                  subCommentID
                }
              }
              isDeleted
            }
            nextToken
          }
          likes {
            items {
              id
              user
              commentID
            }
          }
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      sharePostID  
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      createdAt
      updatedAt           
      # mintSupply
      # isMinting
      
      # isListed
      # pricePerUnit
      # mintSupplySold
      # mintTokenId
      # nftCategory
    }
  }
  postNextToken
  likeNextToken
  commentNextToken
}
}
`;