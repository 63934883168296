import { API, graphqlOperation } from 'aws-amplify'
import { createFirebaseMessaging, updateFirebaseMessaging, 
  updateNotification, updateNotificationComment, updateNotificationFollow } from '../../graphql/mutations'
import { getFirebaseMessaging, getNotifications } from '../../graphql/queries'
import messaging from '@/firebase/firebaseConfig'

const actions = {
  setupFirebaseMessaging ({ commit, rootState }) {
    if (!messaging) return
    const sendTokenToServer = (token) => {
      API.graphql(graphqlOperation(getFirebaseMessaging, {
        user: rootState.auth.userData.name
      })).then(res => {
        if (res.data.getFirebaseMessaging) {
          if (res.data.getFirebaseMessaging.token !== token) {
            API.graphql(graphqlOperation(updateFirebaseMessaging, {
              input: {
                id: res.data.getFirebaseMessaging.id,
                user: res.data.getFirebaseMessaging.user,
                token
              }
            }))
            // .then(() => console.log('update token success'))
          }
        } else {
          API.graphql(graphqlOperation(createFirebaseMessaging, {
            input: {
              user: rootState.auth.userData.name,
              token
            }
          }))
          // .then(() => console.log('create new token success'))
        }
      }).catch(err => console.log(err))
    }
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        sendTokenToServer(currentToken)
      }
    }).catch(err => console.log(err))
    
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        sendTokenToServer(refreshedToken)
      }).catch(err => {
        commit('SET_ERROR', err, { root: true })
      })
    })

    messaging.onMessage((payload) => {
      const notification = payload.data
      notification.ref = JSON.parse(payload.data.ref)
      console.log(notification)
      if (notification.receiver === rootState.auth.userData.name) {
        commit('ADD_NOTIFICATION', notification)
      }
    })
  },
  fetchNotifications ({ commit, rootState, state }) {
    API.graphql(graphqlOperation(getNotifications, {
      user: rootState.auth.userData.name,
      limit: 20,
      nextPage: state.nextPage
    }))
      .then(res => {
        commit('SET_NOTIFICATION', res.data.getNotifications)
      })
      .catch(err => {
        commit('SET_ERROR', err, { root: true })
      })
  },
  markSeen ({ commit, state }) {
    const notSeenNotification = state.notifications.filter(notification => { return !notification.seen })
    Promise.all(notSeenNotification.map(ntf => {
      let operation = ''
      if (ntf.refTable === 'Notification') {
        operation = updateNotification
      } else if (ntf.refTable === 'NotificationComment') {
        operation = updateNotificationComment
      } else {
        operation = updateNotificationFollow
      }
      return API.graphql(graphqlOperation(operation, {
        input: {
          id: ntf.id,
          seen: true
        }
      }))
    })).catch(err =>  commit('SET_ERROR', err, { root: true }))
    commit('MARK_SEEN')
  }
}

export default actions