export default {

  SET_FEEDS (state, payload) { 
    for (const feed of payload.feeds) {
      feed.postRef.isLiked = false
      for (const like of feed.postRef.likes.items) {
        if (like.postID === feed.postRef.id && like.user === payload.user) {
          feed.postRef.isLiked = true
          break
        }
      }
      feed.postRef.viewAllComments = false
      
      for (const comment of feed.postRef.comments.items) {
        if (comment.likes.items.length === 0) comment.isLiked = false
        else {
          for (const like of comment.likes.items) {
            if (like.user === payload.user) {
              comment.isLiked = true
            } else comment.isLiked = false
          }
        }

        for (const subComment of comment.subComments.items) {
          if (subComment.likes.items.length === 0) subComment.isLiked = false
          else {
            for (const like of subComment.likes.items) {
              if (like.user === payload.user) {
                subComment.isLiked = true
              } else subComment.isLiked = false
            }
          }
        }
      }
    }
    state.feeds = payload.feeds
    state.isLoading = false
    state.postNextToken = payload.postNextToken
    state.likeNextToken = payload.likeNextToken
    state.commentNextToken = payload.commentNextToken
  },
  APPEND_FEEDS (state, { nextPageFeeds, user, postNextToken, likeNextToken, commentNextToken }) {
    for (const feed of nextPageFeeds) {
      feed.postRef.isLiked = false
      for (const like of feed.postRef.likes.items) {
        if (like.postID === feed.postRef.id && like.user === user) {
          feed.postRef.isLiked = true
          break
        }
      }
      feed.postRef.viewAllComments = false
      
      for (const comment of feed.postRef.comments.items) {
        if (comment.likes.items.length === 0) comment.isLiked = false
        else {
          for (const like of comment.likes.items) {
            if (like.user === user) {
              comment.isLiked = true
            } else comment.isLiked = false
          }
        }

        for (const subComment of comment.subComments.items) {
          if (subComment.likes.items.length === 0) subComment.isLiked = false
          else {
            for (const like of subComment.likes.items) {
              if (like.user === user) {
                subComment.isLiked = true
              } else subComment.isLiked = false
            }
          }
        }
      }
    }

    state.feeds.push(...nextPageFeeds)

    state.postNextToken = postNextToken
    state.likeNextToken = likeNextToken
    state.commentNextToken = commentNextToken
  },



  SET_CURRENT_PAGE (state, value) {
    state.currentPage = value
  },
  SET_MY_FEEDS (state, payload) {
    state.myFeeds = payload.items.sort((a, b) => { return b.timestamp - a.timestamp })
  }, 
  SET_LIKE (state, postIndex) {
    state.feeds[postIndex].isLiked = true
  },
  ADD_POST (state, post) {
    post.isLiked = false
    state.feeds.unshift({
      action: '',
      actor: '',
      postRef: post,
      createdAt: post.createdAt
    })
  },
  UPDATE_POST (state, { updatePost, feedIndex }) {
    state.feeds[feedIndex].postRef = updatePost
    state.isEditorActive = false
  },
  UPDATE_SINGLE_POST (state, { updatePost }) {
    state.currentPost = updatePost
  },
  SPLICE_CURRENT_POST_MEDIAS (state, index) {
    state.currentPost.mediaFiles.splice(index, 1);
    state.currentPost.medias.splice(index, 1);
  },
  DELETE_POST (state, index) {
    state.feeds.splice(index, 1)
  },
  ADD_LIKE (state, { feed, currentLike }) {
    feed.isLiked = true
    feed.countLikes += 1
    feed.likes.items.unshift(currentLike)
  },
  DELETE_LIKE (state, { feed, user }) {
    feed.isLiked = false
    feed.countLikes -= 1
    // remove the user's like in the likes array
    feed.likes.items = feed.likes.items.filter((like) => {
      return like.user !== user
    })
  },
  CREATE_COMMENT (state, payload) {
    payload.createdComment.isLiked = false
    payload.feed.comments.items.unshift(payload.createdComment)
  },
  UPDATE_COMMENT (state, { feed, commentIndex, comment }) {
    feed.comments.items[commentIndex].content = comment.content
  },
  DELETE_COMMENT (state, { feed, commentIndex }) {
    feed.comments.items.splice(commentIndex, 1)
  },
  CREATE_SUB_COMMENT (state, { comment, subComment }) {
    subComment.isLiked = false
    comment.subComments.items.unshift(subComment)
  },
  UPDATE_SUB_COMMENT (state, { comment, subCommentIndex, subComment, isLiked }) {
    subComment.isLiked = isLiked
    comment.subComments.items.splice(subCommentIndex, 1, subComment)
  },
  DELETE_SUB_COMMENT (state, { comment, subCommentIndex }) {
    comment.subComments.items.splice(subCommentIndex, 1)
  },
  CREATE_LIKE_COMMENT (state, { feedIndex, commentIndex, createdLike }) {
    const currentComment = state.feeds[feedIndex].postRef.comments.items[commentIndex]
    currentComment.isLiked = true
    currentComment.likes.items.push(createdLike)
  },
  DELETE_LIKE_COMMENT (state, { feedIndex, commentIndex, deletedLike }) {
    const currentComment = state.feeds[feedIndex].postRef.comments.items[commentIndex]
    currentComment.isLiked = false
    currentComment.likes.items = currentComment.likes.items.filter((like) => {
      return like.user !== deletedLike.user
    })
  },
  CREATE_LIKE_SUB_COMMENT (state, { comment, subCommentIndex, createdLike }) {
    const currentSubComment = comment.subComments.items[subCommentIndex]
    currentSubComment.isLiked = true
    currentSubComment.likes.items.push(createdLike)
  },
  DELETE_LIKE_SUB_COMMENT (state, {comment, subCommentIndex, deletedLike }) {
    const currentSubComment = comment.subComments.items[subCommentIndex]
    currentSubComment.isLiked = false
    currentSubComment.likes.items = currentSubComment.likes.items.filter((like) => {
      return like.user !== deletedLike.user
    })
  },
  TOGGLE_VIEW_ALL_COMMENTS (state, payload) {
    state.feeds[payload.feedIndex].viewAllComments = !state.feeds[payload.feedIndex].viewAllComments
  },
  INCREASE_PAGE (state) {
    state.nextPage += 1
  },
  SET_CURRENT_POST (state, { currentPost, user }) {
    if (currentPost.likes.items.length === 0) {
      currentPost.isLiked = false
    } else {
      currentPost.isLiked = false
      for (const like of currentPost.likes.items) {
        if (like.postID === currentPost.id && like.user === user) {
          currentPost.isLiked = true
          break
        }
      }
    }

    currentPost.viewAllComments = false
    
    for (const comment of currentPost.comments.items) {
      if (comment.likes.items.length === 0) comment.isLiked = false
      else {
        for (const like of comment.likes.items) {
          if (like.user === user) {
            comment.isLiked = true
          } else comment.isLiked = false
        }
      }

      for (const subComment of comment.subComments.items) {
        if (subComment.likes.items.length === 0) subComment.isLiked = false
        else {
          for (const like of subComment.likes.items) {
            if (like.user === user) {
              subComment.isLiked = true
            } else subComment.isLiked = false
          }
        }
      }
      comment.subComments.items.sort((a, b) => { return b.timestamp - a.timestamp })
    }
    currentPost.comments.items.sort((a, b) => { return b.timestamp - a.timestamp })
    
    state.currentPost = currentPost
  },
  ACTIVATE_EDIT_POST_FORM (state, { editPost, editFeedIndex, isEditorActive }) {
    state.editPost = editPost
    state.editFeedIndex = editFeedIndex
    state.isEditorActive = isEditorActive
  },
  CANCEL_EDIT_POST (state) {
    state.isEditorActive = false
  },
  SET_IS_EDITOR_ACTIVE (state, value) {
    state.isEditorActive = value
  },
  SET_IS_LOADING (state, value) {
    state.isLoading = value
  },
 
}