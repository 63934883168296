import axios from 'axios'
import { API, graphqlOperation } from '@aws-amplify/api'
import { updateUser } from '../../graphql/mutations'
import { courseFeedSubscriptionByUser } from '@/graphql/queries'

const actions = {
  fetchChannels ({ commit, rootState }) {
    if (rootState.auth.userData.interestedChannels) {
      const promises = rootState.auth.userData.interestedChannels.map(channelID => {
        return axios.post(`${ process.env.VUE_APP_BASE_URL }/getChannelById`, {
          channel_id: channelID
        })
      })

      Promise.all(promises).then(res => {
        const data = []
        for (const r of res) {
          if (r !== undefined && r.data.data[0] !== undefined) {
            data.push(r.data.data[0])
          }
        }
        commit('SET_INTEREST_CHANNELS', data)
      })
    }
    if (rootState.auth.userData.universityChannels) {
      const promises = rootState.auth.userData.universityChannels.map(channelID => {
        return axios.post(`${ process.env.VUE_APP_BASE_URL }/getChannelById`, {
          channel_id: channelID
        })
      })

      Promise.all(promises).then(res => {
        const data = []
        for (const r of res) {
          if (r !== undefined && r.data.data[0] !== undefined) {
            data.push(r.data.data[0])
          }
        }
        commit('SET_UNIVERSITY_CHANNELS', data)
      })
    }

    if (rootState.auth.userData.courseChannels) {
      const promises = rootState.auth.userData.courseChannels.map(channelID => {
        return axios.post(`${ process.env.VUE_APP_BASE_URL }/getChannelById`, {
          channel_id: channelID
        })
      })

      Promise.all(promises).then(res => {
        const data = []
        for (const r of res) {
          if (r !== undefined && r.data.data[0] !== undefined) {
            data.push(r.data.data[0])
          }
        }
        commit('SET_COURSE_CHANNELS', data)
      })
    }

    const { course, university } = rootState.auth.userData 
    // get university channel
    axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByUniversityId`, {
      university_id: university
    }).then(res => {
      if (res.data.data[0] !== undefined) {
        commit('SET_UNIVERSITY_CHANNEL', res.data.data[0])
      }
    })
       // get course channel
    axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByUniversityIdAndCourseid`, {
      university_id: university,
      course_id: course
    }).then(res => {
      if (res.data.data[0] !== undefined) {
        commit('SET_COURSE_CHANNEL', res.data.data[0])
      }
    })

    API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
      user: rootState.auth.userData.name
    })).then(res => {
      if (res.data.courseFeedSubscriptionByUser.items) {
        commit('SET_COURSEFEED_CHANNEL', {
          channels: res.data.courseFeedSubscriptionByUser.items,
          id: ''
        })
      }
    }).catch(err => {
      console.log('courseFeedSubscriptionByUser', err)
    })
    
    // get suggested channels
    axios.post(`${process.env.VUE_APP_BASE_URL  }/getChannelByChannelType`, {
      channel_type: 'INTERESTS'
    }).then(res => {
      commit('SET_SUGGESTED_CHANNELS', res.data.data)
    })
  },
  async fetchCourseFeedChannels ({ commit, rootState }, { id }) {
    try {
      const res = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
        user: rootState.auth.userData.name
      }))
      if (res.data.courseFeedSubscriptionByUser.items) {
        commit('SET_COURSEFEED_CHANNEL', {
          channels: res.data.courseFeedSubscriptionByUser.items,
          id
        })
      }
    } catch (err) {
      console.log('courseFeedSubscriptionByUser', err)
    }
  },
  async switchChannel ({ commit, dispatch }, { channel, type, router, loader }) {
    if (type === 'COURSE') {
      console.log('channel', channel)
      router.push(`/coursefeed/${channel.id}`)
      return
    }    
    let channelID = ''
    if (type === 'COURSE') {
      channelID = channel.id
    } else {
      channelID = channel.channel_id
    }    
    commit('newsfeed/SET_IS_LOADING', true, {root: true})
    commit('SWITCH_ACTIVE_STATE', {channel, type})
    commit('SET_CURRENT_CHANNEL', channel)
    commit('newsfeed/SET_CURRENT_PAGE', 0, { root: true })
    loader()
    if (type === 'UNIVERSITYCOURSE') {
      channel.courseFeed = channelID;
      type = 'COURSE'
    }
    await dispatch('newsfeed/fetchPosts', {channel, type}, { root: true }).then(() => {
      commit('newsfeed/SET_IS_LOADING', false, { root: true })
    })
    let channelType = type;
    if (type === 'UNIVERSITY_CHANNELS') {
      channelType = 'UNIVERSITY';
    }else if(type === 'UNIVERSITYCOURSE_CHANNELS'){
      channelType = 'UNIVERSITYCOURSE';
    }
    dispatch('follow/fetchSuggestedFollows', {
      channelID,
      channelType: channelType
    }, { root: true })
    loader.close()
  },
  activateSuggestedChannel ({ commit }, channelIndex) {
    commit('ACTIVATE_SUGGESTED_CHANNEL', channelIndex)
  },
  updateFollowChannels ({ commit, state, rootState, dispatch }) {
    const followChannels = state.suggestedChannels.filter(channel => channel.isActive)
    if (followChannels !== null) {
      API.graphql(graphqlOperation(updateUser, {
        input: {
          name: rootState.auth.userData.name,
          interestedChannels: followChannels.map(channel => channel.channel_id)
        }
      })).then(res => {
        dispatch('auth/updateUser', res.data.updateUser, { root: true })
        commit('UPDATE_INTEREST_CHANNELS', res.data.updateUser.interestedChannels)
      })
    }
  },
  updateFollowUniversityChannels ({ commit, state, rootState, dispatch },followChannels ) {
    if (followChannels !== null) {
      API.graphql(graphqlOperation(updateUser, {
        input: {
          name: rootState.auth.userData.name,
          universityChannels: followChannels.map(channel => channel.channel_id)
        }
      })).then(res => {
        dispatch('auth/updateUser', res.data.updateUser, { root: true })
        commit('UPDATE_UNIVERSITY_CHANNELS', followChannels)
      })
    }
  },
  updateFollowCourseChannels ({ commit, state, rootState, dispatch },followChannels, resolve ) {
    if (followChannels !== null) {
      API.graphql(graphqlOperation(updateUser, {
        input: {
          name: rootState.auth.userData.name,
          courseChannels: followChannels.map(channel => channel.channel_id)
        }
      })).then(res => {
        dispatch('auth/updateUser', res.data.updateUser, { root: true })
        commit('UPDATE_COURSE_CHANNELS', followChannels)
      })
    }
  }
}

export default actions