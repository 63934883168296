import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/offline',
      component: () => import('@/views/pages/OfflineFallback.vue')
    },
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/connect',
          component: () => import('@/views/pages/ConnectPage.vue')
        },
        {
          path: '/connect',
          name: 'connect',
          component: () => import('@/views/pages/ConnectPage.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/partner/:userName',
          name: 'partner-profile',
          component: () => import('@/views/profile/partner/PartnerProfile.vue'),
          meta: {
            authRequired: true
          }
        },
        // {
        //   path: '/general-profile',
        //   name: 'general-profile',
        //   component: () => import('@/views/profile/general-profile/GeneralProfile.vue'),
        //   meta: {
        //     authRequired: true
        //   }
        // },
        {
          path: '/post',
          name: 'post',
          component: () => import('@/views/pages/SinglePost.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/user-profile',
          name: 'user-setting-profile',
          component: () => import('@/views/pages/user-settings/UserProfile.vue')
        },
        {
          path: '/profile/:user_name',
          name: 'profile',
          component: () => import('@/views/profile/profile.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/my-profile/:user_name',
          name: 'my-profile',
          component: () => import('@/views/profile/MyProfile.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/educator-profile/:user_name',
          name: 'educator-profile',
          component: () => import('@/views/educator-profile/EducatorProfile.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/job-portal',
          name: 'job-portal',
          component: () => import('@/views/pages/job-portal/JobPortal.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/:user/job-portal',
          name: 'user-job-portal',
          component: () => import('@/views/pages/job-portal/JobPortal.vue'),
          meta: {
            authRequired: true
          }
        },
 
        {
          path: '/courses',
          name: 'courses',
          component: () => import('@/views/pages/course/Courses.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/coursefeed/:id',
          name: 'coursefeed',
          component: () => import('@/views/pages/coursefeeds/Coursefeed.vue'),
          meta: {
            authRequired: true
          } 
        },
        {
          path: '/course-details/:id',
          name: 'course-details',
          component: () => import('@/views/pages/course/CourseDetails.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/service-details/:id',
          name: 'service-details',
          component: () => import('@/views/profile/partner/EducatorServices/ServiceDetails.vue'),
          meta: {
            authRequired: true
          }
        },
        // {
        //   path: '/events',
        //   name: 'events',
        //   component: () => import('@/views/pages/event/Events.vue'),
        //   meta: {
        //     authRequired: true
        //   }
        // },

        {
          path: '/coursefeeds',
          name: 'coursefeeds',
          component: () => import('@/views/pages/coursefeeds/Coursefeeds.vue'),
          meta: {
            authRequired: true
          }
        },

        {
          path: '/sessions',
          name: 'Sessions',
          component: () => import('@/views/pages/sessions/Sessions.vue'),
          meta: {
            authRequired: true
          }
        },

        {
          path: '/my-listings',
          name: 'my-listings',
          component: () => import('@/views/pages/my-listings/MyListings.vue'),
          meta: {
            authRequired: true
          }
        },
        {
          path: '/sessionlisting-details/:id',
          name: 'sessionlisting-details',
          component: () => import('@/views/pages/my-listings/session-listing/SessionListingDetails.vue'),
          meta: {
            authRequired: true
          }
        },

        // {
        //   path: '/my-pals',
        //   name: 'my-pals',
        //   component: () => import('@/views/pages/CoursepalMobile.vue'),
        //   meta: {
        //     authRequired: true
        //   }
        // },
        
        // {
        //   path: '/form-wizard',
        //   name: 'extra-component-form-wizard',
        //   component: () => import('@/views/form-wizard/FormWizard.vue'),
        //   meta: {
        //     authRequired: true
        //   }
        // },
        {
          path: '/user/settings',
          name: 'user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            authRequired: true
          }
        },
     


        {
          path: '/course-lists',
          name: 'course-lists',
          component: () => import('@/views/pages/courselisting/CourseList.vue'),
          meta: {
            authRequired: true
          }
        },

        {
          path: '/CourseView/:id',
          name: 'CourseView',
          component: () => import('@/views/pages/courselisting/courseview/CourseView.vue'),
          meta: {
            authRequired: true
          }
        },

        {
          path: '/my-application',
          name: 'my-application',
          component: () => import('@/views/pages/courselisting/myapplication/MyApplication.vue'),
          meta: {
            authRequired: true
          }
        },

        // {
        //   path: '/user-channels',
        //   name: 'user-channels',
        //   component: () => import('@/views/pages/user-settings/UserChannels.vue')
        // },
        {
          path: '/search',
          name: 'search',
          component: () => import('@/views/pages/Search.vue'),
          meta: {
            authRequired: true
          }
        },

       
        
        {
          path: '/chat',
          name: 'chat',
          component: () => import('@/views/chat/Chat.vue'),
          meta: {
            no_scroll: true,
            authRequired: true
          }
        },
        {
          path: '/chat/:pair',
          name: 'chat-pair',
          component: () => import('@/views/chat/Chat.vue'),
          meta: {
            no_scroll: true,
            authRequired: true
          }
        },
        {
          path: '/calendar',
          name: 'calendar',
          component: () => import('./views/calendar/SimpleCalendar.vue'),
        },
       
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/onboarding',
          name: 'onboarding',
          component: () => import('@/views/onboarding/Onboarding.vue')
        },
        {
          path: '/educator-onboarding',
          name: 'educator-onboarding',
          component: () => import('@/views/educator-onboarding/EducatorOnboarding.vue')
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/recover-password/:token',
          name: 'recover-password',
          component: () => import('@/views/pages/RecoverPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/verify-account',
          name: 'verify-account',
          component: () => import('@/views/pages/VerifyAccount.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/howitworks',
          name: 'howitworks',
          component: () => import('@/views/pages/HowItWorks.vue'),
          meta: {
            rule: 'editor'
          }
        },
        // {
        //   path: '/nft-lists',
        //   name: 'nft-web',
        //   component: () => import('@/views/pages/NFTWeb.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/for-educators',
        //   name: 'become-an-educator',
        //   component: () => import('@/views/pages/BecomeAnEducator.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/privacy-policy',
          name: 'page-privacypolicy',
          component: () => import('@/views/pages/PrivacyPolicy.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/legal',
          name: 'legal',
          component: () => import('@/views/pages/Legal.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/user-agreement',
          name: 'user-agreement',
          component: () => import('@/views/pages/UserAgreement.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/eula',
          name: 'EULA',
          component: () => import('@/views/pages/EULA.vue'),
          meta: {
            rule: 'editor'
          }
        },
        // {
        //   path: '/partnerwithus',
        //   name: 'page-partnerwithus',
        //   component: () => import('@/views/pages/partner-with-us/PartnerWithUs.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        
        // {
        //   path: '/pricing',
        //   name: 'page-pricing',
        //   component: () => import('@/views/pages/Pricing.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/reset-password',
          name: 'reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/comingsoon',
          name: 'coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-500',
          name: 'error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/not-authorized',
          name: 'not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/maintenance',
          name: 'maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const isLoggedIn = userInfo && Object.keys(userInfo).length !== 0
    if (isLoggedIn) {
      return next()
    } else {
      router.push({ path: '/login' }).catch(() => {})
    }
  }
  return next()
})


export default router
