const getters = {
  photoURLs: (state) => {
    const photoURLs = []
    const myFeeds = state.myFeeds
    myFeeds.map(feed => {
      if (feed.mediaFiles) {
        feed.mediaFiles.map(image => {
          photoURLs.push({ postID: feed.id, image })
        })
      }
    })
    
    return photoURLs
  },
  videoURLs: (state) => {
    const videoURLs = []
    const myFeeds = state.myFeeds
    myFeeds.map(feed => {
      if (feed.videoURL) {
        videoURLs.push({ postID: feed.id, videoURL: feed.videoURL })
      }
    })
    return videoURLs
  }
}

export default getters
