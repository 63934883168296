const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON (state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE (state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
    state.verticalNavMenuWidth = width
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE (state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex((item) => item.url === payload.url)

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList['pages'].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url === payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED (state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE (state, list) {
    let downToUp                   = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited      = state.starredPages.slice(0, 10)
    state.starredPages             = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  TOGGLE_CONTENT_OVERLAY (state, val) { state.bodyOverlay       = val   },
  UPDATE_PRIMARY_COLOR (state, val)   { state.themePrimaryColor = val   },
  UPDATE_THEME (state, val)           { state.theme             = val   },
  UPDATE_WINDOW_WIDTH (state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y (state, val) { state.scrollY           = val   },
  SET_ERROR (state, error) { state.error = error 
  console.log(error)},


  // Note: Copied From - coursepal_courselising-web
  SET_RESULTS(state, data) {
    state.results = data;
  },

  SET_FACETS(state, data) {
    state.facets = data;
  },

  SET_RESULTS_COUNT(state, count) {
    state.resultsCount = count;
  },
  SET_SEARCHSTRING(state, value) {
    state.searchString = value;
  },
  SET_FILTERS(state, payload) {
    if (payload.selected == null) {
      delete state.filters[payload.facet];
    } else {
      state.filters[payload.facet] = Array.isArray(payload.selected)
        ? payload.selected
        : payload.selected.split();
    }

    let allFilters = [];
    let allFiltersString = '';

    const keys = Object.keys(state.filters);
    keys.map((key) => {
      const filterArray = [];
      let filterString = '';
      state.filters[key].map((selectedValue) => {
        // handle query string for numbers or strings, add quotes depending
        const filter = typeof selectedValue === 'number'
          ? selectedValue
          : `'${selectedValue}'`;
        return filterArray.push(`${key} eq ${filter}`);
      });
      filterString += filterArray.join(' or ');
      return allFilters.push(`(${filterString})`);
    });

    allFilters = allFilters.filter((f) => f.length && f !== '()');
    allFiltersString = allFilters.join(' and ');
    state.filterString = allFiltersString;
  },

  CLEAR_FILTERS(state) {
    state.filters = {};
    state.filterString = '';
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },

  SET_RESULTS_PER_PAGE(state, count) {
    state.resultsPerPage = count;
  },
}



export default mutations

