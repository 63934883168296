import state from './chatState.js'
import mutations from './chatMutations.js'
import actions from './chatActions.js'
import getters from './chatGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
