export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          items {
            user
          }
          nextToken
        }
        following {
          items {
            follower
          }
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;