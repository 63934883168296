<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
    <!-- <session-card ref="sessionCard" />
    <session-notify ref="sessionNotify" /> -->
    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
  </div>
</template>

<script>
import Vue from 'vue'
import themeConfig from '@/../themeConfig.js'
// import SessionCard from './SessionCard.vue'
// import SessionNotify from './SessionNotify.vue'
// import VTour from '@/layouts/components/coursepal/VTour'
// import { getEducatorService, listEducatorSessionBookings, userByEmail } from '@/graphql/queries' 
// import { API, graphqlOperation } from '@aws-amplify/api'
// import { onUpdateEducatorSessionBookingByUser } from '@/graphql/subscriptions'

export default {
  name: 'MyPals.app',
   metaInfo: {
    title: 'MyPals, Create memes and images using AI ',
    titleTemplate: '%s',
    htmlAttrs: {
      lang: 'en'
    }
  },
  components: {
    // SessionCard,
    // SessionNotify,
    // VTour
  },
  data () {
    return {
      vueAppClasses: [],
      zmDataSubscription: ''
    }
  },
  watch: {
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
      
    },
    // async setupZmSession () {
    //   try {
    //     this.$store.dispatch('zmSession/fetchOnlineSessions')

    //     const zmDataSubscription = API.graphql({
    //       query: onUpdateEducatorSessionBookingByUser,
    //       variables: {
    //         user: this.$store.state.auth.userData.name
    //       }
    //     }).subscribe({
    //       next: async ({ value }) => {
    //         if (value.data.onUpdateEducatorSessionBookingByUser.isOnline && !this.$store.state.auth.userData.isPartner) {
    //           const zmSignature = value.data.onUpdateEducatorSessionBookingByUser.zoomSignature
    //           const zmPassword = value.data.onUpdateEducatorSessionBookingByUser.zoomPassword
    //           const educatorServiceID = value.data.onUpdateEducatorSessionBookingByUser.educatorServiceID
    //           const getRes = await API.graphql(graphqlOperation(getEducatorService, {
    //             id: educatorServiceID
    //           }))

    //           const zmSessionName = getRes.data.getEducatorService.title
    //           this.$sessionNotify.zmSignature = zmSignature
    //           this.$sessionNotify.zmPassword = zmPassword
    //           this.$sessionNotify.zmSessionName = zmSessionName
    //           this.$sessionNotify.active = true
    //         }
    //         // popup
    //         this.$store.commit('zmSession/UPDATE_SESSION', value.data.onUpdateEducatorSessionBookingByUser)
    //       }
    //     })
    
    //     this.zmDataSubscription = zmDataSubscription
    
    //     // const listRes = await API.graphql(graphqlOperation(listEducatorSessionBookings, {
    //     //   input: {
    //     //     filter: {
    //     //       user: {
    //     //         eq: this.$store.state.auth.userData.name
    //     //       }
    //     //     }
    //     //   }
    //     // }))

    //     // console.log(listRes.data.listEducatorSessionBookings.items)
        
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }
  },
  mounted () {
    // this.$store.dispatch("executeSearch");
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // Vue.prototype.$sessionCard = this.$refs.sessionCard
    // Vue.prototype.$sessionNotify = this.$refs.sessionNotify

    // if (this.$store.state.auth.userData.name && !this.$store.state.auth.userData.isPartner) {
    //   this.setupZmSession()
    // }
  },
  async created () {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    if (this.zmDataSubscription) {
      this.zmDataSubscription.unsubscribe()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
