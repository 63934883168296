const mutations = {
  SET_MY_FEEDS (state, payload) {
    for (const feed of payload.feeds) {
      for (const like of feed.likes.items) {
        if (like.postID === feed.id && like.user === payload.user) {
          feed.isLiked = true
          break
        }
      }
    }
    state.myFeeds = payload.feeds
  },
  SET_EDUCATION (state, payload) {
    state.education = payload
  },
  ADD_EDUCATION (state, payload) {
    state.education.unshift(payload)
  },
  UPDATE_EDUCATION (state, { payload, index }) {
    state.education.splice(index, 1, payload)
  },
  DELETE_EDUCATION (state, index) {
    state.education.splice(index, 1)
  },
  SET_EXPERIENCE (state, payload) {
    state.experience = payload
  },
  ADD_EXPERIENCE (state, payload) {
    state.experience.unshift(payload)
  },
  UPDATE_EXPERIENCE (state, { payload, index }) {
    state.experience.splice(index, 1, payload)
  },
  DELETE_EXPERIENCE (state, index) {
    state.experience.splice(index, 1)
  },
  SET_ACHIEVEMENTS (state, payload) {
    state.achievements = payload
  },
  CREATE_ACHIEVEMENT (state, payload) {
    state.achievements.unshift(payload)
  },
  UPDATE_ACHIEVEMENT (state, { payload, index }) {
    state.achievements.splice(index, 1, payload)
  },
  DELETE_ACHIEVEMENT (state, index) {
    state.achievements.splice(index, 1)
  },
  SET_PROJECT (state, payload) {
    state.projects = payload
  },
  ADD_PROJECT (state, payload) {
    state.projects.unshift(payload)
  },
  UPDATE_PROJECT (state, { payload, index }) {
    state.projects.splice(index, 1, payload)
  },
  DELETE_PROJECT (state, index) {
    state.projects.splice(index, 1)
  },
  SET_CERTIFICATES (state, payload) {
    state.certificates = payload
  },
  ADD_CERTIFICATE (state, payload) {
    state.certificates.unshift(payload)
  },
  UPDATE_CERTIFICATE (state, { payload, index }) {
    state.certificates.splice(index, 1, payload)
  },
  DELETE_CERTIFICATE (state, index) {
    state.certificates.splice(index, 1)
  },
  SET_SKILLS (state, payload) {
    state.skills = payload
  },
  ADD_SKILL (state, payload) {
    state.skills.unshift(payload)
  },
  DELETE_SKILL (state, index) {
    state.skills.splice(index, 1)
  },
  SET_PROFILE (state, payload) {
    state.profile = payload
  },
  UPDATE_UNI_EMAIL_VERIFY (state, payload) {
    state.isUniEmailVerify = payload
  },
  SET_FOLLOWERS (state, followers) {
    state.followers = followers
  },
  SET_FOLLOWINGS (state, followings) {
    state.followings = followings
  }
}

export default mutations