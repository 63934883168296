import Vue from 'vue'
import axios from 'axios'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(' ')
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', function (value, limit) {
  if (value) {
    if (limit > value.length) return value
    const lastSpaceIndex = value.substring(limit, value.length).indexOf(' ')
    const displayString = `${value.substring(0, limit + lastSpaceIndex)}...`
    return displayString
  }
})


Vue.filter('tailing', function (value, tail) {
  return value + tail
})

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM'
      hours = hours % 12 || 12
      return `${hours}:${min} ${time}`
    }
    return `${hours}:${min}`
  }
})

Vue.filter('year', function (value) {
  if (value) {
    const date = new Date(value)
    return date.getFullYear()
  }
})

Vue.filter('date', function (value, fullDate = false) {
  value = String(value)
  const date = value.slice(8, 10).trim()
  const month = value.slice(4, 7).trim()
  const year = value.slice(11, 15)

  if (!fullDate) return `${date} ${month}`
  else return `${date} ${month} ${year}`
})

Vue.filter('month', function (val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
  }

})

Vue.filter('csv', function (value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('ignoreHTMLTags', function (value) {
  return value.replace(/<\w+[ \w=":#]+>.*<\/[\w ]+>/g, `"${value}"`)
})

Vue.filter('formatPostContent', function (value) {
  // find all links
  const hyperLinks = [...value.matchAll(/(https?:\/\/)[\w\d\%\./&?=-]*/g)]
  const htmlTags = [...value.matchAll(/<\w+[ \w=":#]+>.*<\/[\w ]+>/g, `"${value}"`)]
  
  htmlTags.map(match => {
    let rep = match[0].replaceAll('<', '&lt;')
    rep = rep.replaceAll('>', '&gt;')
    value = value.replace(match[0], rep)
  })

  let newStr = value
  hyperLinks.map(match => {
    newStr = value.replace(match[0], `<a href="${match[0]}" target="_blank">${match[0]}</a>`)
  })

  const hashTagMatches = [...value.matchAll(/( |^|\n)#\w+/g)]
  hashTagMatches.map(m => {
    newStr = newStr.replaceAll(m[0], `<a style="color: #007bff;" href="/search?query=${m[0].trim().substring(1, m[0].length)}">${m[0]}</a>`)
  })

  const mentionMatches = value.match(/\@[\w\.-_]+/g)
  if (mentionMatches) {
    mentionMatches.map(m => {
      newStr = newStr.replaceAll(m, `<a style="color: #007bff;" href="/profile/${m.substring(1, m.length)}">${m}</a>`)
    })
  }

  return newStr
})

Vue.filter('partnerTypeFormatter', function (partnerType) {
  if (partnerType) {
    const result = partnerType[0].toUpperCase() + partnerType.substring(1, partnerType.length).toLowerCase()
    return result
  } else return ''
})

Vue.filter('k_formatter', function (num) {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
})

Vue.filter('elapsedTime', function (startTime) {
  const x        = new Date(startTime)
  const now      = new Date()
  let timeDiff = now - x
  timeDiff    /= 1000

  const seconds = Math.round(timeDiff)
  timeDiff    = Math.floor(timeDiff / 60)

  const minutes = Math.round(timeDiff % 60)
  timeDiff    = Math.floor(timeDiff / 60)

  const hours   = Math.round(timeDiff % 24)
  timeDiff    = Math.floor(timeDiff / 24)

  const days    = Math.round(timeDiff % 365)
  timeDiff    = Math.floor(timeDiff / 365)

  const years   = timeDiff

  if (years > 0) {
    return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`
  } else if (days > 0) {
    return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`
  } else if (hours > 0) {
    return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`
  } else if (minutes > 0) {
    return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`
  } else if (seconds > 0) {
    return  'just now'
  }
  return 'Just Now'
})

// Vue.filter('getUniversityName', async function (universityID) {
//   const promise = await axios.post(`${process.env.VUE_APP_BASE_URL  }/getInstituteDetails`, {
//     inst_id: universityID
//   }).then(res => res.data.data[0].univ_name)

//   return promise
// })

Vue.filter('formatLineBreak', function (content) {
  return content.replaceAll(/\n/g, '<br/>')
})

Vue.filter('formatLikeCount', function (likes) {
  if (likes < 2) {
    return `${likes} like`
  } else {
    return `${likes} likes`
  }
})

Vue.filter('formatCommentCount', function (comments) {
  if (comments < 2) {
    return `${comments} comment`
  } else {
    return `${comments} comments`
  }
})

Vue.filter('formatProfileDate', function(date) {
  if (date === null) {
    return "Present";
  } else {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  }
})

Vue.filter('formatUserSettingDate', function(date) {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
})

Vue.filter('formatEducatorArrayString', function(arr) {
  if (arr.length === 0) return ''
  let ret = ''
  for (const item of arr) {
    ret += item + ', '
  }
  return ret
})
