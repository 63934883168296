export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      author
      isMinting
      pricePerUnit
      isListed
      mintSupply
      mintSupplySold
      mintTokenId
      nftCategory
      video
      authorRef {
        stripeConnectedAccountKey
        stripeAccountKey
        name
        displayName
        enableNftNotification
        enablePostNotification
        email
        loginType
        isUniEmailVerify
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      comments {
        items {
          id
          author
          authorRef {
            displayName
            photoURL
            universityName
            isUniEmailVerify
            studyStatus
            about
            isPartner
            followers {
              items {
                user
                follower
              }
              nextToken
            }
            following {
              items {
                user
                follower
              }
              nextToken
            }
          }
          content
          subComments {
            items {
              id
              author
              authorRef {
                displayName
                photoURL
                universityName
                isUniEmailVerify
                studyStatus
                about
                isPartner
                followers {
                  items {
                    follower
                  }
                  nextToken
                }
                following {
                  items {
                    user
                  }
                  nextToken
                }
              }
              content
              likes {
                items {
                  id
                  user
                  subCommentID
                }
              }
              isDeleted
            }
            nextToken
          }
          likes {
            items {
              id
              user
              commentID
            }
          }
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;