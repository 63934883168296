import router from '@/router'
// import {
//   Client
// } from "@hubspot/api-client";
import {
  API,
  graphqlOperation
} from '@aws-amplify/api'
import {
  Auth
} from '@aws-amplify/auth'

import {
  createUser,
  updateUser,
  createPartner,
  deleteUser,
  deletePartner,
  createFollow,
  updateUniEmailVerify
} from '@/graphql/mutations'
import {
  getUser,
  partnerByUser,
  getFollow,
  userByEmail,
  uniEmailVerifyByUser
} from '@/graphql/queries'
import axios from 'axios'
import awsExport from '@/aws-exports'
import {
  v4 as uuidv4
} from 'uuid'

import {
  createSuccessObject,
  createErrorObject
} from '@/utils.js'

import S3 from 'aws-s3'
import {
  userByEmailAndLoginType
} from '../../graphql/queries'
const config = {
  bucketName: awsExport.aws_user_files_s3_bucket,
  dirName: 'profile',
  region: awsExport.aws_user_files_s3_bucket_region,
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY
}

const S3Client = new S3(config)

const defaultProfileImageURL = process.env.VUE_APP_DEFAULT_IMAGE_URL

const cloudFrontUrl = process.env.VUE_APP_CLOUDFRONT_URL
const BUCKET_NAME = awsExport.aws_user_files_s3_bucket
const getCompressImageUrl = (imageKey, width, height, fit) => {
  const imageRequest = JSON.stringify({
    bucket: BUCKET_NAME,
    key: imageKey,
    edits: {
      resize: {
        width,
        height,
        fit
      }
    }
  })
  const url = `${cloudFrontUrl}/${btoa(imageRequest)}`
  return url
}

const IMAGE_WIDTH = 180
const IMAGE_HEIGHT = 180

const notifyError = (err, msg) => {
  if (msg) {
    return {
      time: 2500,
      title: 'Error',
      text: msg,
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      position: 'top-right',
      color: 'danger'
    }
  } else if (err && err.message) {
    return {
      time: 2500,
      title: 'Error',
      text: err.message,
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      position: 'top-right',
      color: 'danger'
    }
  }
}

const createInitalFollow = async (follower) => {
  const getFollowRes = await API.graphql(graphqlOperation(getFollow, {
    user: 'MyPals',
    follower
  }))

  if (getFollowRes && !getFollowRes.data.getFollow) {
    await API.graphql(graphqlOperation(createFollow, {
      input: {
        user: 'MyPals',
        follower
      }
    }))
  }
}

const partnerPostLogin = async (userData, router, commit) => {
  // get partner data
  const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
    user: userData.name
  }))

  if (partnerByUserRes.data.partnerByUser) {
    const partnerData = partnerByUserRes.data.partnerByUser.items[0]
    commit('SET_PARTNER_DATA', partnerData)
    // go to onboarding
    if (userData.isFirstLogin) {
      if (partnerData.partnerType === 'EDUCATOR') {
        await sendWelcomeEmail(userData)
        router.push('/educator-onboarding')
      }
    } else {
      router.push('/connect')
    }
  } else {
    payload.notify(notifyError(null, 'Partner data not found'))
  }
}
const sendWelcomeEmail = async (userData) => {
  let url = `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`;
  //  let url = "http://localhost:7000/api/sendAppMail";
  axios.post(url, {
      template_code: 'welcome_user',
      to: userData.email,
      bcc: 'hello@mypals.app',
      username: userData.displayName,
    })
    .then(res =>
      console.log(res)
    )
}

const studentPostLogin = async (userData, router, commit) => {
  const uniEmailRes = await API.graphql(graphqlOperation(uniEmailVerifyByUser, {
    user: userData.name
  }))

  if (uniEmailRes.data.uniEmailVerifyByUser.items.length !== 0) {
    const {
      isVerify,
      uniEmail
    } = uniEmailRes.data.uniEmailVerifyByUser.items[0]
    commit('UPDATE_UNI_EMAIL_VERIFY', {
      isVerify,
      uniEmail
    })
  }
  if (userData.isFirstLogin) {
    await sendWelcomeEmail(userData)
  }
  if (userData.isFirstLogin || userData.studyStatus === '') {
    router.push('/onboarding')
  } else {
    router.push('/connect')
  }
}

export default {
  async login({
    dispatch,
    state,
    commit
  }, payload) {
    try {
      // If user is already logged in notify and exi
      const user = await Auth.signIn(payload.userDetails.email, payload.userDetails.password)
      if (!user) {
        payload.notify(null, 'Unable to login, cannot find user data')
        return
      }

      const getUserRes = await API.graphql(graphqlOperation(userByEmailAndLoginType, {
        email: user.attributes.email,
        loginType: {
          eq: 'email'
        }
      }))

      const userData = getUserRes.data.userByEmailAndLoginType.items[0]
      console.log(userData)
      if (!userData) {
        payload.notify(null, 'Unable to login, cannot find user data')
        return
      }

      dispatch('fetchUniversityInfo', userData.university)
      commit('UPDATE_USER_INFO', userData)
   

      // create follow
      await createInitalFollow(userData.name)

      if (userData.isPartner) {
        await partnerPostLogin(userData, payload.router, commit)
      } else {
        await studentPostLogin(userData, payload.router, commit)
      }
      if (!userData.stripeAccountKey) {
        API.post('cpRestAPI', '/stripeaccount/customer/create', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              email: userData.email,
              input: {
                name: userData.name
              }
            }
          }).then(updateUserRes => {
            if (updateUserRes.success === true) {
              if (updateUserRes.response.updateUser.stripeAccountKey) {
                userData.stripeAccountKey = updateUserRes.response.updateUser.stripeAccountKey
              }
              commit('UPDATE_USER_INFO', userData)
              // create follow

            }
          })
          .catch(err => {
            console.log(err);
          })
      } else {
        // user has already created
        dispatch('fetchUniversityInfo', userData.university)
        commit('UPDATE_USER_INFO', userData)
      }

    } catch (err) {
      if (process.env === 'development') {
        console.log(err)
      }
      payload.notify(notifyError(err, ''))
      if (err.name === 'UserNotConfirmedException') {
        commit('SET_EMAIL', payload.userDetails.email)
        payload.router.push('/verify-account')
      }
    }
  },
  async registerUser({

    dispatch,
    state,
    commit
  }, payload) {
    // create user using firebase
    try {

      const {
        username,
        email,
        hearAboutUs,
        hearAboutUsOther,
        password
      } = payload.userDetails

      const {
        user
      } = await Auth.signUp({
        username: email,
        password
      })

      const getUserRes = await API.graphql(graphqlOperation(getUser, {
        name: username,
        filter: {
          loginType: {
            eq: 'email'
          }
        }
      }))
      // username already exists
      if (getUserRes.data.getUser) {
        payload.notify(notifyError(null, 'Sorry, this username not available, please try another name.'))
      } else if (user) {
        await API.graphql(graphqlOperation(createUser, {
          input: {
            name: username,
            displayName: username,
            email,
            loginType: 'email',
            university: 0,
            studyArea: 0,
            course: 0,
            studyStatus: 'Creator',
            isPartner: false,
            isFirstLogin: true,
            photoURL: defaultProfileImageURL,
            enablePostNotification: true,
            enableNftNotification: true
          }
        }))
        var RowData = {
          username: username,
          displayName: username,
          email: email,
          phoneNumber: '',
          photoURL: defaultProfileImageURL,
          providerId: 'password',
          uid: ''
        };

        dispatch("LoginInsertion", RowData);


        payload.notify({
          title: 'Account created, please verify your account',
          text: 'Verification link sent to your email address!',
          iconPack: 'feather',
          icon: 'icon-check',
          position: 'top-right',
          color: 'success'
        })

        commit('SET_EMAIL', email)



        // const hubspotClient = new Client({
        //   // "apiKey": process.env.VUE_APP_SIGN_UP
        //   "accessToken": process.env.VUE_APP_SIGN_UP
        // });

        // const properties = {
        //   "company": "MyPals",
        //   "email": email, 
        //   "firstname": username,
        //   "lastname": "",
        //   "phone": "",
        //   "address": "location",
        //   "website": "https://mypals.app/"
        // };
        

        // await axios.post('https://api.coursepal.app/api/createHubspotContact', {
        //    properties
        // }).then(function (response) {
        //   console.log(response);
        // })
        router.push('/verify-account')

      }
    } catch (err) {
      payload.notify(notifyError(err, ''))
    }
  },
  // async LoginInsertion({
  //   commit
  // }, UserData) {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("username", UserData.username);
  //   urlencoded.append("emailid", UserData.email);
  //   urlencoded.append("source_signin", UserData.providerId);
  //   urlencoded.append("profile_pic", UserData.photoURL);
  //   urlencoded.append("first_name", UserData.displayName);
  //   urlencoded.append("phoneNumber", UserData.phoneNumber);
  //   urlencoded.append("uid", UserData.uid);
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: urlencoded,
  //     redirect: "follow"
  //   };
    // const rawResponse = await fetch(
    //   "https://api.coursepal.app/api/LoginInsertion",
    //   requestOptions
    // );

  //   const response = await rawResponse.json();
  //   if (response.status == true) {}
  // },
  async confirmSignUp({
    state
  }, payload) {
    try {
      await Auth.confirmSignUp(state.email, payload.code)
      payload.notify({
        title: 'Verification successful',
        text: 'Account created',
        iconPack: 'feather',
        icon: 'icon-check',
        position: 'top-right',
        color: 'success'
      })
    } catch (err) {
      console.log('error confirming sign up', err)
    }
  },
  // fetchUniversityInfo({
  //   commit
  // }, universityId) {
    // axios.post(`${process.env.VUE_APP_BASE_URL  }/getInstituteDetails`, {
    //     inst_id: universityId
    //   })
    //   .then(res => commit('SET_UNIVERSITY_INFO', res.data.data[0]))
  // },
  logout({
    commit,
    rootState,
    dispatch
  }) {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('partnerData')
    dispatch('chat/removeChatState', null, {
      root: true
    })
    commit('REMOVE_STATE_DATA', rootState)
    commit('REMOVE_USER_DATA', {
      router
    })

  },
  updateUserProfile({
    commit,
    rootState
  }, payload) {
    const {
      displayName,
      university,
      universityName,
      studyArea,
      studyAreaName,
      course,
      courseName,
      studyStatus,
      birthday,
      gender
    } = payload
    if (payload.updateAvatarImage !== '') {
      S3Client.uploadFile(payload.updateAvatarImage, `profile-image-${uuidv4()}`)
        .then(res => {
          // resize image
          const photoURL = getCompressImageUrl(res.key, IMAGE_WIDTH, IMAGE_HEIGHT, 'fill')
          // update the url to dynamodb database
          API.graphql(graphqlOperation(updateUser, {
            input: {
              name: rootState.auth.userData.name,
              displayName,
              university,
              universityName,
              studyArea,
              studyAreaName,
              course,
              courseName,
              studyStatus,
              photoURL,
              birthday,
              gender
            }
          })).then(resUpdateUser => {
            commit('UPDATE_USER_INFO', resUpdateUser.data.updateUser)
            payload.notify(createSuccessObject('Success', 'Update profile succeeded!'))
          })
        }).catch(err => {
          payload.notify(createErrorObject('Error', 'Unable to update profile!'))
          if (process.env === 'development') {
            console.log(err)
          }
        })
    } else {
      API.graphql(graphqlOperation(updateUser, {
        input: {
          name: rootState.auth.userData.name,
          displayName,
          university,
          universityName,
          studyArea,
          studyAreaName,
          course,
          courseName,
          studyStatus,
          birthday,
          gender
        }
      })).then(resUpdateUser => {
        // commit update user
        commit('UPDATE_USER_INFO', resUpdateUser.data.updateUser)
        payload.notify(createSuccessObject('Success', 'Update Profile succeeded!'))
      }).catch(err => {
        if (process.env === 'development') {
          console.log(err)
        }
        payload.notify(createErrorObject('Error', 'Unable to update profile'))
      })
    }
  },
  updateUser({
    commit
  }, updatedUserData) {
    commit('UPDATE_USER_INFO', updatedUserData)
  },
  updateUserProfileImages({
    commit,
    rootState
  }, payload) {
    const promise1 = new Promise((resolve) => {
      if (payload.updateAvatarImage !== '') {
        S3Client.uploadFile(payload.updateAvatarImage, `profile-image-${uuidv4()}`)
          .then(res => {
            // resize image
            const photoURL = getCompressImageUrl(res.key, IMAGE_WIDTH, IMAGE_HEIGHT, 'fill')
            API.graphql(graphqlOperation(updateUser, {
              input: {
                name: rootState.auth.userData.name,
                photoURL
              }
            })).then(resUpdateUser => {
              commit('UPDATE_USER_INFO', resUpdateUser.data.updateUser)
            })
            // update this profile image into crm       
            axios.post(`${process.env.VUE_APP_BASE_URL  }/api/updateProfilePicUrlByEmail`, {
              email: payload.email,
              profile_pic: res.location
            }).then(result => {
              if (result.data.status === true) {
                // update profile in firebase
                resolve(res.location)
              }
            })
          })
      } else {
        resolve('')
      }
    })

    const promise2 = new Promise((resolve) => {
      if (payload.updateCoverImage !== '') {
        S3Client.uploadFile(payload.updateCoverImage, `profile-cover-image-${uuidv4()}`)
          .then(res => {
            // update this profile image into crm       
            axios.post(`${process.env.VUE_APP_BASE_URL  }/api/updateProfileCoverPicUrlByEmail`, {
              email: payload.email,
              profile_cover_pic: res.location
            }).then(result => {
              if (result.data.status === true) {
                resolve(res.location)
              }
            })
          })
      } else {
        resolve('')
      }
    })
    Promise.all([promise1, promise2]).then((values) => {
      payload.callback(values)
    })
  },
  async registerPartnerUser({
    dispatch,
    commit
  }, payload) {
    // create user using firebase
    let createUserRes = null
    let createPartnerRes = null
    try {
      const {
        username,
        displayName,
        email,
        phone,
        overview,
        website,
        partnerType,
        mainOffice,
        companySize,
        industry,
        organizationType,
        founded,
        specialities,
        password
      } = payload.userDetails

      const {
        user
      } = await Auth.signUp({
        username: email,
        password
      })

      if (user) {
        // create user data on dynamodb
        createUserRes = await API.graphql(graphqlOperation(createUser, {
          input: {
            name: username,
            displayName,
            email,
            loginType: 'email',
            photoURL: defaultProfileImageURL,
            university: '0', // required default fields for user
            course: '0',
            studyStatus: 'Creator',
            studyArea: '',
            isPartner: true,
            isFirstLogin: true
          }
        }))

        var RowData = {
          username: username,
          displayName: username,
          email: email,
          phoneNumber: '',
          photoURL: defaultProfileImageURL,
          providerId: 'password',
          uid: ''
        };
        dispatch("LoginInsertion", RowData);

        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: createUserRes.data.createUser.name
        }))

        if (partnerByUserRes.data.partnerByUser.items.length !== 0) {
          payload.notify(notifyError(null, 'Partner profile is already existed'))
          return false
        }

        createPartnerRes = await API.graphql(graphqlOperation(createPartner, {
          input: {
            user: createUserRes.data.createUser.name,
            phone,
            overview,
            website,
            partnerType,
            mainOffice,
            companySize,
            industry,
            organizationType,
            founded,
            specialities
          }
        }))

        // auto login after registeration
        payload.notify({
          title: 'Account created please verify your account',
          text: 'Verification link send to your email!',
          iconPack: 'feather',
          icon: 'icon-check',
          position: 'top-right',
          color: 'success'
        })
        commit('SET_EMAIL', email)
        return true
      }
    } catch (err) {
      if (err.errors) {
        payload.notify(createErrorObject('Error', err.errors[0].message))
      }

      if (err.code === 'UsernameExistsException') {
        payload.notify(notifyError(err, ''))
      }

      if (createUserRes) {
        await API.graphql(graphqlOperation(deleteUser, {
          input: {
            name: createUserRes.data.createUser.name
          }
        }))
      }

      if (createPartner && createPartnerRes.data.createPartner) {
        await API.graphql(graphqlOperation(deletePartner, {
          input: {
            id: createPartnerRes.data.createPartner.id
          }
        }))
      }
      payload.notify(notifyError(err, ''))
      return false
    }
  },
  isUserLoggedIn() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo && Object.keys(userInfo).length !== 0) {
      return true
    } else return false
  },
  loginWithFacebook() {
    Auth.federatedSignIn({
      provider: 'Facebook'
    })
  },
  loginWithApple() {
    Auth.federatedSignIn({
      provider: 'SignInWithApple'
    })
  },
  loginWithGoogle() {
    Auth.federatedSignIn({
      provider: 'Google'
    })
  },
  async finishOAuthSignIn({
    commit,
    dispatch
  }, router) {
    const user = await Auth.currentAuthenticatedUser()
    const attributes = user.attributes
    const identities = JSON.parse(attributes.identities)
    const providerType = identities[0].providerType
    const picture = attributes.picture
    const res = await API.graphql(graphqlOperation(userByEmailAndLoginType, {
      email: attributes.email,
      loginType: {
        eq: providerType
      }
    }))
    console.log(attributes);
    var RowData = {
      username: attributes.email,
      displayName: attributes.name,
      
      email: attributes.email,
      phoneNumber: '',
      photoURL: attributes.picture,
      providerId: JSON.parse(attributes.identities)[0].providerType,
      uid: JSON.parse(attributes.identities)[0].userId
    };
    dispatch("LoginInsertion", RowData);
    if (!res.data.userByEmailAndLoginType.items[0]) {
      // prompt a popup asking about their username
      let userData = {
        email: attributes.email,
        displayName: attributes.name,
        photoURL: attributes.picture,
      }
      commit('UPDATE_USER_INFO', userData)
      await sendWelcomeEmail(userData)
      router.push({
        name: 'onboarding',
        params: {
          createUser: true,
          email: attributes.email,
          providerType,
          picture,
          displayName:attributes.name
        }
      }).catch(() => {})
    } else {

      if (!res.data.userByEmailAndLoginType.items[0].stripeAccountKey) {
        API.post('cpRestAPI', '/stripeaccount/customer/create', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              email: res.data.userByEmailAndLoginType.items[0].email,
              input: {
                name: res.data.userByEmailAndLoginType.items[0].name
              }
            }
          }).then(updateUserRes => {
            if (updateUserRes.success === true) {
              let userData = res.data.userByEmailAndLoginType.items[0]
              if (updateUserRes.response.updateUser.stripeAccountKey) {
                userData.stripeAccountKey = updateUserRes.response.updateUser.stripeAccountKey
              }
              // user has already created
              commit('UPDATE_USER_INFO', userData)
              if (!userDataObj.studyStatus) {
                router.push('/onboarding')
              } else {
                router.push('/connect')
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
      } else {
        // user has already created
        commit('UPDATE_USER_INFO', res.data.userByEmailAndLoginType.items[0])
        if (!res.data.userByEmailAndLoginType.items[0].studyStatus) {
          router.push('/onboarding')
        } else {
          router.push('/connect')
        }
      }

    }
  },
  async onboardUpdate({
    commit,
    state
  }, {
    studyStatus,
    university,
    course,
    studyArea,
    imageFile,
    displayName,
    headline,
    birthday,
    gender,
    selectedChannels,
    location,
    router,
    notify,
    loading
  }) {
    try {
      let photoRes = ''
      if (imageFile instanceof File) {
        photoRes = await S3Client.uploadFile(imageFile, `profile-cover-image-${uuidv4()}`)
      }
      if (displayName == '' || displayName == undefined) {
        displayName = userName;
      }
      loading();
      const updateUserRes = await API.graphql(graphqlOperation(updateUser, {
        input: {
          name: state.userData.name,
          studyStatus,
          university: university.university_id,
          universityName: university.univ_name,
          course: course.course_id,
          courseName: course.course_name,
          studyArea: studyArea.stu_area_id,
          studyAreaName: studyArea.study_area,
          displayName,
          headline, // about,
          photoURL: photoRes ? photoRes.location : (state.userData.photoURL ? state.userData.photoURL : ''),
          interestedChannels: selectedChannels,
          birthday: new Date(birthday),
          gender,
          isFirstLogin: false,
          location
        }
      }))
      loading.close();
      commit('UPDATE_USER_INFO', updateUserRes.data.updateUser)
      router.push('/connect')
    } catch (err) {
      console.log(err)
    }
  },
  async onboardCreate({
    commit,
    state,
    dispatch
  }, {
    userName,
    studyStatus,
    university,
    course,
    studyArea,
    imageFile,
    headline,
    birthday,
    gender,
    selectedChannels,
    location,
    router,
    email,
    providerType,
    displayName,
    notify,
    loading
  }) {
    let photoRes = ''
    if (imageFile instanceof File) {
      photoRes = await S3Client.uploadFile(imageFile, `profile-cover-image-${uuidv4()}`)
    }
    if (displayName == '' || displayName == undefined) {
      displayName = userName;
    }
    loading();
    API.graphql(graphqlOperation(createUser, {
      input: {
        name: userName,
        displayName: displayName,
        email,
        loginType: providerType,
        university: university.university_id,
        universityName: university.univ_name,
        course: course.course_id,
        courseName: course.course_name,
        studyArea: studyArea.stu_area_id,
        studyAreaName: studyArea.study_area,
        studyStatus,
        photoURL: photoRes ? photoRes.location : (state.userData.photoURL ? state.userData.photoURL : ''),
        isPartner: false,
        isFirstLogin: true,
        isUniEmailVerify: false,
        interestedChannels: selectedChannels,
        birthday: new Date(birthday),
        gender,
        location,
        enablePostNotification: true,
        enableNftNotification: true,
        headline // about
      }
    })).then(res => {
      // update user
      dispatch('fetchUniversityInfo', res.data.createUser.university)
      commit('UPDATE_USER_INFO', res.data.createUser)
      // move to connect page
      API.graphql(graphqlOperation(getFollow, {
        user: 'MyPals',
        follower: userName
      })).then(getFollowRes => {
        if (getFollowRes && !getFollowRes.data.getFollow) {
          API.graphql(graphqlOperation(createFollow, {
            input: {
              user: 'MyPals',
              follower: userName
            }
          })).then(createFollowRes => {
            loading.close();
            router.push('/connect')
          }).catch(err => console.log('createFollow', err))
        } else {
          loading.close();
          router.push('/connect')
        }
      })
    }).catch(err => console.log(err))
  },
  sendUniEmailVerifyCode({
    state
  }, {
    uniEmail,
    notify,
    loading
  }) {
    API.post('cpRestAPI', '/sendUniEmailVerifyCode', {
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          user: state.userData.name,
          uniEmail
        }
      }).then(res => {
        notify(createSuccessObject('Success', 'An email has been sent to your university email address'))
        loading.close()
      })
      .catch(err => {
        notify(createErrorObject('Error', 'Unable to send verify code to university email address'))
        console.log(err)
        loading.close()
        throw new Error(err)
      })
  },
  verifyUniEmailCode({
    state,
    commit
  }, {
    verifyCode,
    uniEmail,
    notify
  }) {
    API.graphql(graphqlOperation(uniEmailVerifyByUser, {
      user: state.userData.name
    })).then(res => {
      if (res.data.uniEmailVerifyByUser.items && res.data.uniEmailVerifyByUser.items.length !== 0) {
        const uniEmailVerify = res.data.uniEmailVerifyByUser.items[0]
        if (verifyCode === uniEmailVerify.code.toString()) {
          API.graphql(graphqlOperation(updateUser, {
            input: {
              name: uniEmailVerify.user,
              uniEmail,
              isUniEmailVerify: true
            }
          })).then(updateRes => {
            notify(createSuccessObject('Success', 'University Email Verify!'))
            commit('UPDATE_USER_INFO', updateRes.data.updateUser)
          }).catch(err => console.log(err))
        } else {
          notify(createErrorObject('Error', 'Verify code mismatch'))
        }
      }
    }).catch(err => {
      console.log(err)
    })
  },
  fetchUniEmail({
    state,
    commit
  }) {
    API.graphql(graphqlOperation(uniEmailVerifyByUser, {
      user: state.userData.name
    })).then(res => {
      if (res.data.uniEmailVerifyByUser.items.length !== 0) {
        const uniEmailVerify = res.data.uniEmailVerifyByUser.items[0]
        commit('UPDATE_UNI_EMAIL_VERIFY', {
          isVerify: uniEmailVerify.isVerify,
          uniEmail: uniEmailVerify.uniEmail
        })
      }
    }).catch(err => console.log(err))
  },
  updateIntro({
    commit,
    state
  }) {
    API.graphql(graphqlOperation(updateUser, {
      input: {
        name: state.userData.name,
        isIntroClosed: true
      }
    })).then(res => {
      commit('UPDATE_USER_INFO', res.data.updateUser)
    }).catch(err => console.log(err))
  }
}
