export default {
  unreadMessages: (state, getters, rootState) => {
    let res = []
    const currentUserName = rootState.auth.userData.name
    if (state.convos.length !== 0) {
      for (const convo of state.convos) {
        const messagesLength = convo.conversationRef.messages.items ? convo.conversationRef.messages.items.length : 0
        if (messagesLength > 0) {
          const lastMessage = convo.conversationRef.messages.items[messagesLength - 1]
          if (lastMessage.sender !== currentUserName && !lastMessage.isSeen) {
            res.push({lastMessage, convo})
          }
        }
      }
    }
    return res
  }
}
