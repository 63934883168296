export default {
  UPDATE_USER_INFO (state, payload) {
    state.userData = payload
   
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        state.userData[property] = payload[property]
        userInfo[property] = payload[property]
      }
    }
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
 
  SET_UNIVERSITY_INFO (state, payload) {
    state.universityInfo = payload
  },
  SET_WALLET_CONNECTION(state,val){
    state.isConnected=val
  },
  SET_WALLET_ACCOUNT(state,val){
    state.walletAccount=val
  },
  REMOVE_USER_DATA (state, { router }) {
    state.userData = {}
    router.push('/login').catch(() => {})
  },
  /* Removing the state data. */
  
  REMOVE_STATE_DATA (state, rootState) {
    rootState.follow.suggestedFollows = []
    rootState.newsfeed.feeds = []
    rootState.notification.notifications = []
  },
  SET_PARTNER_DATA (state, payload) {
    const {
      id,
      user,
      phone,
      overview,
      website,
      partnerType,
      mainOffice,
      companySize,
      industry,
      organizationType,
      founded,
      specialities
    } = payload

    state.partnerData = {
      id,
      user,
      phone,
      overview,
      website,
      partnerType,
      mainOffice,
      companySize,
      industry,
      organizationType,
      founded,
      specialities
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.isPartner) {
      localStorage.setItem('partnerData', JSON.stringify(state.partnerData))
    }
  },
  UPDATE_PARTNER_DATA (state, payload) {
    state.partnerData = payload
    localStorage.setItem('partnerData', JSON.stringify(payload))
  },
  SET_EMAIL (state, payload) {
    state.email = payload
  },
  UPDATE_UNI_EMAIL_VERIFY (state, payload) {
    state.isUniEmailVerified = payload.isVerify
    state.uniEmail = payload.uniEmail
  }
}
