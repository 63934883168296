import state from './newsfeedStates.js'
import mutations from './newsfeedMutations.js'
import actions from './newsfeedActions.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
