import {
  API,
  graphqlOperation
} from '@aws-amplify/api'
import {
  Storage
} from '@aws-amplify/storage'
import {
  postsByAuthor,
  partnerByUser
} from '@/graphql/queries'
import {
  getNewsfeed
} from '@/graphql/getNewsfeed'
import {
  getSharePost
} from '@/graphql/getSharePost'
import {
  getPost
} from '@/graphql/getPost'
import {
  createLike,
  deleteLike,
  updateComment,
  deleteComment,
  deleteSubComment,
  createLikeComment,
  deleteLikeComment,
  createLikeSubComment,
  deleteLikeSubComment,
  createNotification,
  createNotificationComment,
  createNotificationSubComment,
  createOgMetaData,
  createReportPost
} from '@/graphql/mutations'

import {
  createPost
} from '@/graphql/createPost'
import {
  createComment
} from '@/graphql/createComment'
import {
  createSubComment
} from '@/graphql/createSubComment'
import {
  updatePost
} from '@/graphql/updatePost'
import {
  updateSubComment
} from '@/graphql/updateSubComment'

import axios from 'axios'
import {
  getCompressImageUrl
} from '@/utils'
import {
  createSuccessObject
} from '../../utils'

const prepareNewsfeedData = async (name, channelID, limit, postNextToken, likeNextToken, commentNextToken) => {
  const result = await API.graphql(graphqlOperation(getNewsfeed, {
    name,
    channelID: channelID.toString(),
    limit,
    postNextToken,
    likeNextToken,
    commentNextToken
  }))

  if (!result.data.getNewsfeed.newsfeed) return []
  for (const feed of result.data.getNewsfeed.newsfeed) {
    feed.postRef.mediaFiles = []
    if (feed.postRef.medias && feed.postRef.medias.length > 0) {
      const mediaFiles = feed.postRef.medias.map(m => getCompressImageUrl(m, 600))
      feed.postRef.mediaFiles = mediaFiles
    }

    if (feed.postRef.video) {
      const url = await Storage.get(feed.postRef.video)
      feed.postRef.videoURL = url
    }
    if (feed.postRef.authorRef.isPartner) {
      const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
        user: feed.postRef.author
      }))
      feed.postRef.partnerData = partnerByUserRes.data.partnerByUser.items[0]
    }

    if (feed.postRef.sharePostID) {
      const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
        id: feed.postRef.sharePostID
      }))
      if (sharePostRes.data.getPost && sharePostRes.data.getPost.medias.length > 0) {
        const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
        sharePostRes.data.getPost.mediaFiles = mediaFiles
      }

      if (sharePostRes.data.getPost.authorRef.isPartner) {
        const sharePostPartnerByUser = await API.graphql(graphqlOperation(partnerByUser, {
          user: sharePostRes.data.getPost.author
        }))
        sharePostRes.data.getPost.partnerData = sharePostPartnerByUser.data.partnerByUser.items[0]
      }
      feed.postRef.sharePost = sharePostRes.data.getPost
    }
  }
  return result.data.getNewsfeed
}

const actions = {
  async createPost({
    commit,
    rootState
  }, payload) {
    try {
      let uploadResults = []
      if (payload.medias && payload.medias.length > 0) {
        uploadResults = payload.medias.map(m => Storage.put(m.name, m, {
          contentType: m.type,
          // progressCallback(progress) {
          //   payload.uploadProgressbar.objectName = m.name
          //   payload.uploadProgressbar.isShow = true
          //   payload.uploadProgressbar.percent = (progress.loaded / progress.total) * 100.0
          // }
        }))
        uploadResults = await Promise.all(uploadResults)
      }

      let uploadVideo = ''
      if (payload.video) {
        uploadVideo = await Storage.put(payload.video.name, payload.video, {
          contentType: payload.video.type,
          progressCallback(progress) {
            payload.uploadProgressbar.objectName = payload.video.name,
              payload.uploadProgressbar.isShow = true
            payload.uploadProgressbar.percent = (progress.loaded / progress.total)
          }
        })
      }
      let channelID = ''
      if (payload.channelID) {
        channelID = payload.channelID
      } else if (rootState.channel.currentChannel.channel_id) {
        channelID = rootState.channel.currentChannel.channel_id
      } else if (rootState.channel.currentChannel.courseFeed) {
        channelID = rootState.channel.currentChannel.courseFeed
      }

      const createPostRes = await API.graphql(graphqlOperation(createPost, {
        input: {
          author: payload.author,
          content: payload.content,
          medias: uploadResults.map(ur => ur.key),
          video: uploadVideo.key,
          timestamp: Date.now(),
          isDeleted: false,
          channelID,
          sharePostID: payload.sharePostID ? payload.sharePostID : '',
          mintSupply: payload.mintSupply,
          isMinting: payload.isMinting,
          isListed: payload.isListed,
          pricePerUnit: payload.pricePerUnit,
          mintSupplySold: payload.mintSupplySold,
          mintTokenId:payload.mintTokenId,
          nftCategory: payload.nftCategory
        }
      }))
      

      if (payload.hasOpenGraph) {
        const {
          ogTitle,
          ogDescription,
          ogVideo,
          ogImage,
          ogDate,
          ogSiteName,
          ogUrl,
          requestUrl
        } = payload.ogMetaData

        const ogVideoUrl = ogVideo && ogVideo.url ? ogVideo.url : ''
        const ogVideoHeight = ogVideo && ogVideo.height ? ogVideo.height : 0
        const ogVideoWidth = ogVideo && ogVideo.width ? ogVideo.width : 0
        const ogVideoType = ogVideo && ogVideo.type ? ogVideo.type : ''

        const ogImageUrl = ogImage && ogImage.url ? ogImage.url : ''
        const ogImageHeight = ogImage && ogImage.height ? ogImage.height : 0
        const ogImageWidth = ogImage && ogImage.width ? ogImage.width : 0
        const ogImageType = ogImage && ogImage.type ? ogImage.type : ''

        const ogRes = await API.graphql(graphqlOperation(createOgMetaData, {
          input: {
            postID: createPostRes.data.createPost.id,
            ogTitle,
            ogDescription,
            ogVideoUrl,
            ogVideoHeight,
            ogVideoWidth,
            ogVideoType,
            ogImageUrl,
            ogImageHeight,
            ogImageWidth,
            ogImageType,
            ogDate,
            ogSiteName,
            ogUrl,
            requestUrl
          }
        }))

        if (ogRes.data.createOgMetaData) {
          createPostRes.data.createPost.ogMetaData.items = [ogRes.data.createOgMetaData]
        }
      }

      let mediaFiles = createPostRes.data.createPost.medias.map(m => Storage.get(m))
      mediaFiles = await Promise.all(mediaFiles)
      createPostRes.data.createPost.mediaFiles = mediaFiles
      // payload.uploadProgressbar.isShow = false

      if (createPostRes.data.createPost.video) {
        const url = await Storage.get(createPostRes.data.createPost.video)
        createPostRes.data.createPost.videoURL = url
        // payload.uploadProgressbar.isShow = false
      }

      if (createPostRes.data.createPost.authorRef.isPartner) {
        // get partner data
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: createPostRes.data.createPost.author
        }))

        createPostRes.data.createPost.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }

      let currentChannelID = ''
      if (rootState.channel.currentChannel.courseFeed) {
        currentChannelID = rootState.channel.currentChannel.courseFeed
      } else if (rootState.channel.currentChannel.channel_id) {
        currentChannelID = rootState.channel.currentChannel.channel_id
      }

      if (createPostRes.data.createPost.channelID === currentChannelID.toString()) {
        const createdPost = createPostRes.data.createPost
        if (createdPost.sharePostID) {
          const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
            id: createdPost.sharePostID
          }))

          if (sharePostRes.data.getPost.medias && sharePostRes.data.getPost.medias.length > 0) {
            const mediaFiles = sharePostRes.data.getPost.medias.map(m => getCompressImageUrl(m, 600))
            sharePostRes.data.getPost.mediaFiles = mediaFiles
          }
          createdPost.sharePost = sharePostRes.data.getPost
        }

        // detect mention inside post content and send notification
        const mentionedUsers = payload.content.match(/\@[\w\.-_]+/g)

        if (mentionedUsers && mentionedUsers.length !== 0) {
          for (const mentioned of mentionedUsers) {
            API.graphql(graphqlOperation(createNotification, {
                input: {
                  sender: rootState.auth.userData.name,
                  receiver: mentioned.substring(1, mentioned.length),
                  type: 'MENTION',
                  seen: false, 
                  postID: createPostRes.data.createPost.id,
                  timestamp: Date.now()
                }
              }))
              .then(notiRes => {
                axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notiRes.data.createNotification)
              })
          }
        }
        
        commit('ADD_POST', createdPost)
      
        return createPostRes
      }
    } catch (err) {
      console.log('error!!!')
      console.log(err)
      payload.$vs.notify({
        title: 'Error',
        text: 'Failed to create post',
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'danger',
        position: 'top-right'
      })
    }
  },
  async updatePost({
    commit
  }, {
    id,
    content,
    medias,
    feedIndex
  }) {
    try {
      const updatePostRes = await API.graphql(graphqlOperation(updatePost, {
        input: {
          id,
          medias,
          content
        }
      }))

      let mediaFiles = updatePostRes.data.updatePost.medias.map(m => Storage.get(m))
      mediaFiles = await Promise.all(mediaFiles)
      updatePostRes.data.updatePost.mediaFiles = mediaFiles

      if (updatePostRes.data.updatePost.video) {
        const url = Storage.get(updatePostRes.data.updatePost.video)
        updatePostRes.data.updatePost.videoURL = url
      }

      if (feedIndex !== null) {
        commit('UPDATE_POST', {
          updatePost: updatePostRes.data.updatePost,
          feedIndex
        })
      } else {
        commit('UPDATE_SINGLE_POST', {
          updatePost: updatePostRes.data.updatePost
        })
      }
    } catch (err) {
      console.log('updatePost', err)
    }
  },
  async setCurrentPost({
    commit
  }, feed) {
    try {
      commit('UPDATE_SINGLE_POST', {
        updatePost: feed
      })
    } catch (err) {
      console.log('updatePost', err)
    }
  },
  deletePost({
    commit,
    state
  }, feedIndex) {
    const currentPost = state.feeds[feedIndex].postRef
    API.graphql(graphqlOperation(updatePost, {
        input: {
          id: currentPost.id,
          isDeleted: true
        }
      }))
      .then(() => {
        commit('DELETE_POST', feedIndex)
      })
      .catch(err => console.log('deletePost', err))
  },
  deleteSinglePost({
    commit
  }, postID) {
    API.graphql(graphqlOperation(updatePost, {
      input: {
        id: postID,
        isDeleted: true
      }
    }))
  },
  toggleLikePost({
    commit,
    rootState
  }, {
    feed
  }) {
    const currentPost = feed
    if (!currentPost.isLiked) {
      API.graphql(graphqlOperation(createLike, {
        input: {
          user: rootState.auth.userData.name,
          postID: currentPost.id,
          createdAt: new Date(Date.now())
        }
      })).then(res => {
        commit('ADD_LIKE', {
          feed,
          currentLike: {
            id: res.data.createLike.id,
            user: rootState.auth.userData.name,
            postID: currentPost.id
          }
        })
        // add like to feeds state
        // change the isLike value of the current post
        API.graphql(graphqlOperation(createNotification, {
            input: {
              sender: rootState.auth.userData.name,
              receiver: res.data.createLike.post.author,
              type: 'LIKE',
              seen: false,
              postID: res.data.createLike.postID,
              timestamp: Date.now()
            }
          }))
          .then(notificationRes => {
            axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notificationRes.data.createNotification)
              .catch(err => console.log(err))
          })
          .catch(err => console.log(err))
      })
    } else {
      // unlike post
      // find the like
      let currentLike = ''
      for (const like of feed.likes.items) {
        if (like.user === rootState.auth.userData.name) {
          currentLike = like
        }
      }
      API.graphql(graphqlOperation(deleteLike, {
          input: {
            id: currentLike.id
          }
        }))
        .then(() => {
          commit('DELETE_LIKE', {
            feed,
            user: rootState.auth.userData.name
          })
        })
    }
  },
  async fetchPosts({
    commit,
    rootState,
    state
  }, {
    channel,
    type
  }) {
    try {
      let channelID = ''
      if (type === 'COURSE') {
        channelID = channel.courseFeed
      } else {
        channelID = channel.channel_id
      }
      const feeds = await prepareNewsfeedData(rootState.auth.userData.name, channelID, state.LIMIT, null, null, null)
      commit('SET_FEEDS', {
        feeds: feeds.newsfeed,
        user: rootState.auth.userData.name,
        postNextToken: feeds.postNextToken,
        likeNextToken: feeds.likeNextToken,
        commentNextToken: feeds.commentNextToken
      })
    } catch (err) {
      console.log(err)
    }
  },

  // async fetchNfts({
  //   commit,
  //   rootState,
  //   state
  // }, {
  //   channel,
  //   type
  // }) {



  //   try {
  //     let user = ''
  //     let channelID = ''
  //     if (type === 'COURSE') {
  //       channelID = channel.courseFeed
  //     } else {
  //       channelID = channel.channel_id
  //     }
  //     if (rootState.auth.userData.name == undefined) {
  //       user = 'MyPals'
  //     } else {
  //       user = rootState.auth.userData.name
  //     }

  //     const feeds = await prepareNewsfeedData(user, channelID, 800, null, null, null)
  //     commit('SET_NFTS', {
  //       feeds: feeds.newsfeed,
  //       user: rootState.auth.userData.name,
  //       postNextTokenNft: feeds.postNextToken,
  //       likeNextToken: feeds.likeNextToken,
  //       commentNextToken: feeds.commentNextToken
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // },

  



  async fetchNextPage({
    commit,
    rootState,
    state
  }, {
    channelID
  }) {
    try {
      const nextPageFeeds = await prepareNewsfeedData(rootState.auth.userData.name,
        channelID, state.LIMIT,
        state.postNextToken,
        state.likeNextToken,
        state.commentNextToken)
      console.log(nextPageFeeds)
      if (nextPageFeeds.postNextToken !== state.postNextToken) {
        commit('APPEND_FEEDS', {
          nextPageFeeds: nextPageFeeds.newsfeed,
          user: rootState.auth.userData.name,
          postNextToken: nextPageFeeds.postNextToken,
          likeNextToken: nextPageFeeds.likeNextToken,
          commentNextToken: nextPageFeeds.commentNextToken
        })
      }
    } catch (err) {
      console.log(err)
    }
  },

  async fetchNextNFT({
    commit,
    rootState,
    state
  }, {
    channelID
  }) {
    try {

      const nextPageFeeds = await prepareNewsfeedData(rootState.auth.userData.name,
        channelID, state.LIMIT,
        state.postNextToken, null, null)



      if (nextPageFeeds.postNextToken !== state.postNextToken) {
        commit('APPEND_NFTS', {
          nextPageFeeds: nextPageFeeds.newsfeed,
          user: rootState.auth.userData.name,
          postNextToken: nextPageFeeds.postNextToken,
          likeNextToken: nextPageFeeds.likeNextToken,
          commentNextToken: nextPageFeeds.commentNextToken
        })
      }
    } catch (err) {
      console.log(err)
    }
  },
  async fetchMyFeeds({
    commit,
    rootState
  }) {
    const myFeedsResult = await API.graphql(graphqlOperation(postsByAuthor, {
      author: rootState.auth.userData.name,
      filter: {
        isDeleted: {
          eq: false
        }
      }
    }))

    for (const feed of myFeedsResult.data.postsByAuthor.items) {
      feed.mediaFiles = []
      if (feed.medias && feed.medias.length > 0) {
        const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
        feed.mediaFiles = mediaFiles
      }
    }
    commit('SET_MY_FEEDS', myFeedsResult.data.postsByAuthor)
  },
  createComment({
    commit,
    rootState
  }, payload) {
    const {
      photoURL
    } = rootState.auth.userData
    const newComment = {
      author: payload.author,
      authorRef: {
        photoURL
      },
      content: payload.content,
      postID: payload.feed.id,
      timestamp: Date.now(),
      isDeleted: false
    }

    API.graphql(graphqlOperation(createComment, {
        input: {
          author: newComment.author,
          content: newComment.content,
          postID: newComment.postID,
          timestamp: newComment.timestamp,
          isDeleted: false
        }
      }))
      .then(res => {
        commit('CREATE_COMMENT', {
          feed: payload.feed,
          stateIndex: payload.feedStateIndex,
          createdComment: res.data.createComment
        })

        const mentionedUsers = newComment.content.match(/\@[\w\.-_]+/g)
        if (mentionedUsers && mentionedUsers.length !== 0) {
          for (const mentioned of mentionedUsers) {
            API.graphql(graphqlOperation(createNotificationComment, {
                input: {
                  sender: rootState.auth.userData.name,
                  receiver: mentioned.substring(1, mentioned.length),
                  type: 'MENTION',
                  seen: false,
                  commentID: res.data.createComment.id,
                  timestamp: Date.now()
                }
              }))
              .then(notiRes => {
                axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notiRes.data.createNotificationComment)
              })
          }
        }

        API.graphql(graphqlOperation(createNotification, {
            input: {
              sender: rootState.auth.userData.name,
              receiver: res.data.createComment.post.author,
              type: 'COMMENT',
              seen: false,
              postID: res.data.createComment.postID,
              timestamp: Date.now()
            }
          }))
          .then(notificationRes => {
            // call send notification api
            axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notificationRes.data.createNotification)
          })
      })
  },
  editComment({
    commit
  }, {
    feed,
    comment,
    feedIndex,
    commentIndex,
    editCommentInput
  }) {
    API.graphql(graphqlOperation(updateComment, {
        input: {
          id: comment.id,
          content: editCommentInput
        }
      }))
      .then(res => {
        commit('UPDATE_COMMENT', {
          feed,
          feedIndex,
          commentIndex,
          comment: res.data.updateComment
        })
      })
  },
  deleteComment({
    commit
  }, {
    feed,
    comment,
    commentIndex
  }) {
    API.graphql(graphqlOperation(deleteComment, {
        input: {
          id: comment.id
        }
      }))
      .then(() => {
        commit('DELETE_COMMENT', {
          feed,
          commentIndex
        })
      })
  },
  toggleLikeComment({
    commit,
    rootState
  }, {
    feedIndex,
    commentIndex,
    comment
  }) {
    if (!comment.isLiked) {
      API.graphql(graphqlOperation(createLikeComment, {
          input: {
            user: rootState.auth.userData.name,
            commentID: comment.id
          }
        }))
        .then(res => {
          API.graphql(graphqlOperation(createNotificationComment, {
              input: {
                sender: rootState.auth.userData.name,
                receiver: res.data.createLikeComment.comment.author,
                type: 'LIKE',
                seen: false,
                commentID: res.data.createLikeComment.commentID,
                timestamp: Date.now()
              }
            }))
            .then(notificationRes => {
              // call send notification api
              axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notificationRes.data.createNotificationComment)
                .catch(err => console.log(err))
            })

          commit('CREATE_LIKE_COMMENT', {
            feedIndex,
            commentIndex,
            createdLike: res.data.createLikeComment
          })
        })
        .catch(err => console.log(err))
    } else {
      // delete like comment
      // find the like id
      let currentLike = {}
      for (const like of comment.likes.items) {
        if (like.user === rootState.auth.userData.name) {
          currentLike = like
        }
      }
      API.graphql(graphqlOperation(deleteLikeComment, {
          input: {
            id: currentLike.id
          }
        }))
        .then(res => {
          commit('DELETE_LIKE_COMMENT', {
            feedIndex,
            commentIndex,
            deletedLike: res.data.deleteLikeComment
          })
        })
        .catch(err => console.log(err))
    }
  },
  createSubComment({
    commit,
    rootState
  }, {
    comment,
    subCommentInput
  }) {
    API.graphql(graphqlOperation(createSubComment, {
        input: {
          author: rootState.auth.userData.name,
          content: subCommentInput,
          commentID: comment.id,
          isDeleted: false,
          timestamp: Date.now()
        }
      }))
      .then(res => {
        const mentionedUsers = subCommentInput.match(/\@[\w\.-_]+/g)
        if (mentionedUsers && mentionedUsers.length !== 0) {
          for (const mentioned of mentionedUsers) {
            API.graphql(graphqlOperation(createNotificationSubComment, {
                input: {
                  sender: rootState.auth.userData.name,
                  receiver: mentioned.substring(1, mentioned.length),
                  type: 'MENTION',
                  seen: false,
                  subCommentID: res.data.createSubComment.id,
                  timestamp: Date.now()
                }
              }))
              .then(notiRes => {
                axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notiRes.data.createNotificationSubComment)
                  .catch(err => console.log(err))
              })
          }
        }

        API.graphql(graphqlOperation(createNotificationComment, {
            input: {
              sender: rootState.auth.userData.name,
              receiver: res.data.createSubComment.comment.author,
              type: 'REPLY',
              seen: false,
              commentID: res.data.createSubComment.commentID,
              timestamp: Date.now()
            }
          }))
          .then(notificationRes => {
            // call send notification api
            axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notificationRes.data.createNotificationComment)
          })
        commit('CREATE_SUB_COMMENT', {
          comment,
          subComment: res.data.createSubComment
        })
      })
      .catch(err => console.log(err))
  },
  updateSubComment({
    commit
  }, {
    comment,
    subCommentIndex,
    subComment,
    editSubCommentInput
  }) {
    API.graphql(graphqlOperation(updateSubComment, {
        input: {
          id: subComment.id,
          content: editSubCommentInput
        }
      }))
      .then(res => {
        commit('UPDATE_SUB_COMMENT', {
          comment,
          subCommentIndex,
          subComment: res.data.updateSubComment,
          isLiked: subComment.isLiked
        })
      })
      .catch(err => {
        console.log(err)
      })
  },
  deleteSubComment({
    commit
  }, {
    comment,
    subCommentIndex,
    subComment
  }) {
    API.graphql(graphqlOperation(deleteSubComment, {
        input: {
          id: subComment.id
        }
      }))
      .then(() => {
        commit('DELETE_SUB_COMMENT', {
          comment,
          subCommentIndex
        })
      })
  },
  toggleLikeSubComment({
    commit,
    rootState
  }, {
    comment,
    subCommentIndex,
    subComment
  }) {
    if (!subComment.isLiked) {
      // create like
      API.graphql(graphqlOperation(createLikeSubComment, {
          input: {
            user: rootState.auth.userData.name,
            subCommentID: subComment.id
          }
        }))
        .then(res => {
          // send notification
          commit('CREATE_LIKE_SUB_COMMENT', {
            comment,
            subCommentIndex,
            createdLike: res.data.createLikeSubComment
          })
          API.graphql(graphqlOperation(createNotificationSubComment, {
              input: {
                sender: rootState.auth.userData.name,
                receiver: res.data.createLikeSubComment.subComment.comment.author,
                type: 'LIKE',
                seen: false,
                subCommentID: res.data.createLikeSubComment.subCommentID,
                timestamp: Date.now()
              }
            }))
            .then(notificationRes => {
              axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/sendNotification`, notificationRes.data.createNotificationSubComment)
            })
            .catch(err => console.log(err))

        })
    } else {
      // delete like
      let currentLike = ''
      for (const like of subComment.likes.items) {
        if (like.user === rootState.auth.userData.name) {
          currentLike = like
        }
      }
      if (currentLike !== '') {
        API.graphql(graphqlOperation(deleteLikeSubComment, {
            input: {
              id: currentLike.id
            }
          }))
          .then(res => {
            commit('DELETE_LIKE_SUB_COMMENT', {
              comment,
              subCommentIndex,
              deletedLike: res.data.deleteLikeSubComment
            })
          })
          .catch(err => {
            console.log('deleteLikeSubComment', err)
          })
      }
    }
  },
  toggleViewAllComments({
    commit
  }, payload) {
    commit('TOGGLE_VIEW_ALL_COMMENTS', {
      feedIndex: payload.feedIndex
    })
  },
  increasePage({
    commit
  }) {
    commit('INCREASE_PAGE')
  },
  async getPostById({
    commit,
    rootState
  }, postId) {
    try {
      const res = await API.graphql(graphqlOperation(getPost, {
        id: postId
      }))
      const feed = res.data.getPost
      feed.mediaFiles = []
      if (feed.medias && feed.medias.length > 0) {
        const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
        feed.mediaFiles = mediaFiles
      }

      if (feed.video) {
        const url = await Storage.get(feed.video)
        feed.videoURL = url
      }

      if (feed.authorRef.isPartner) {
        const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
          user: feed.author
        }))
        feed.partnerData = partnerByUserRes.data.partnerByUser.items[0]
      }

      if (feed.sharePostID) {
        const sharePostRes = await API.graphql(graphqlOperation(getSharePost, {
          id: feed.sharePostID
        }))

        if (sharePostRes.data.getPost.medias.length > 0) {
          const mediaFiles = feed.medias.map(m => getCompressImageUrl(m, 600))
          sharePostRes.data.getPost.mediaFiles = mediaFiles
        }

        feed.sharePost = sharePostRes.data.getPost
      }
      commit('SET_CURRENT_POST', {
        currentPost: feed,
        user: rootState.auth.userData.name
      })
    } catch (err) {
      console.log('setCurrentPost', err)
    }
  },
  activateEditPostForm({
    commit
  }, payload) {
    commit('ACTIVATE_EDIT_POST_FORM', payload)
  },
  cancelEditPost({
    commit
  }) {
    commit('CANCEL_EDIT_POST')
  },
  reportPost({
    rootState,
    commit
  }, {
    feed,
    feedIndex,
    reports,
    notify
  }) {
    API.graphql(graphqlOperation(createReportPost, {
      input: {
        reporter: rootState.auth.userData.name,
        postID: feed.id,
        reason: reports
      }
    })).then(res => {
      commit('DELETE_POST', feedIndex)
      notify(createSuccessObject('Success', 'Report Submitted, thank you for your support!'))
    }).catch(err => console.log(err))
  },

  // async sendMail({
  //   commit
  // }, payload) {
  //   await axios.post('http://localhost:7001/api/sendNotificationMail', {

  //     user_name: payload.user_name,
  //     activity_user: payload.activity_user,
  //     activity: payload.activity,
  //     template_code: payload.template_code,
  //     activity_user_mail: payload.activity_user_mail,
  //     post_content: payload.post_content,
  //     activity_user_image_url: payload.activity_user_image_url,
  //     post_image_url: payload.post_image_url,
  //     profile_url: payload.profile_url,
  //     post_url: payload.post_url


  //   }).then(function (response) {
  //     console.log(response);
  //   })
  // },

  // async sendNftMail({
  //   commit
  // }, payload) {
  //   await axios.post('http://localhost:7001/api/sendNftNotificationMail', {
  //     activity:payload.activity,
  //     activity_user: payload.activity_user,
  //     activity_user_image_url: payload.activity_user_image_url,
  //     activity_user_mail: payload.activity_user_mail,
  //     post_content: payload.post_content,
  //     post_image_url: payload.post_image_url,
  //     post_url: payload.post_url,
  //     profile_link: payload.profile_link,
  //     profile_url: payload.profile_url,
  //     purchase_name: payload.purchase_name,
  //     template_code: payload.template_code,
  //     user_name: payload.user_name,
  //     user_email: payload.user_email,
  //     user_name:payload.user_name,
  //     amount:payload.amount,
  //     activity_user_profile_link:payload.activity_user_profile_link,
  //     wallet_address:payload.wallet_address

  //   }).then(function (response) {
  //     console.log(response);
  //   })
  // },

  // async sendAllUserMail({
  //   commit
  // }, payload) {
  //   await axios.post('http://localhost:7001/api/sendMailtoAllUsers', {

  //     creater_name: payload.creater_name,
  //     nft_image_url: payload.nft_image_url,
  //     creater_profile_link: payload.creater_profile_link,
  //     post_url: payload.post_url,
  //     creator_user_profile_image: payload.creator_user_profile_image,
  //     creator_user_profile_description: payload.creator_user_profile_description,
  //     creater_email: payload.creater_email,
  //     token_list:payload.token_list
    


  //   }).then(function (response) {
  //     console.log(response);
  //   })
  // },


  // async updateNotificationSettings({
  //   commit
  // }, payload) {
  //   await axios.post('http://localhost:7001/api/updateNotificationSettings', {

  //     is_enable_community_notifications: payload.is_enable_community_notifications,
  //     is_enable_nft_notification: payload.is_enable_nft_notification,
  //     user_email_id: payload.user_email_id,
 
    


  //   }).then(function (response) {
  //     console.log(response);
  //   })
  // },

//   async sendToken({
//     commit
//   }, payload) {
//     await axios.post("http://localhost:7001/api/sendZeroBalanceNftNotificationMail", {

//       user_name: payload.user_name,

//       template_code: payload.template_code,
//       user_mail: payload.user_mail,
//       wallet_account: payload.wallet_account,
//       post_url: payload.post_url


//     }).then(function (response) {
//       console.log(response);
//     })
//   },

}

export default actions
