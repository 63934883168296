export default {
  getUniqueFollowSet (state) {
    const followers = state.followers.items.map(f => { 
      return { name: f.follower, ref: f.followerInstance }
    })
    const filteredFollowing = state.followings.items.filter(f => {
      const names = followers.map(f => f.name)
      return !names.includes(f.user)
    })

    const followings = filteredFollowing.map(f => {
      return { name: f.user, ref: f.userInstance }
    })

    let uniqueSet = [...followers, ...followings]
    return uniqueSet
  }
}