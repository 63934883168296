
const mutations = {
  SET_INTEREST_CHANNELS (state, payload) {
    if (payload !== null) {
      for (const ic of payload) {
        if (ic.order_id === null) ic.order_id = 12 // missing data from sql database
        ic.isSelected = false
      }
    }
    payload.sort((a, b) => { return a.order_id - b.order_id })
    console.log(state.interestedChannels);
    state.interestedChannels = payload
  },
  UPDATE_INTEREST_CHANNELS (state, payload) {
    const res = []
    if (payload.length !== 0) {
      for (const channelID of payload) {
        let newChannel = ''
        for (const sc of state.suggestedChannels) {
          if (channelID === sc.channel_id) {
            newChannel = sc
            break
          }
        }
        for (const ic of state.interestedChannels) {
          if (channelID === ic.channel_id) {
            newChannel.isSelected = ic.isSelected
          }
        }
        if (newChannel !== '') {
          res.push(newChannel)
        }
      }

      state.interestedChannels = res
    }
  },
  SET_UNIVERSITY_CHANNELS (state, payload) {
    if (payload !== null) {
      for (const ic of payload) {
        if (ic.order_id === null) ic.order_id = 12 // missing data from sql database
        ic.isSelected = false
      }
    }
    payload.sort((a, b) => { return a.order_id - b.order_id })
    state.universityChannels = payload
  }, 
  UPDATE_UNIVERSITY_CHANNELS (state, payload) {
    state.universityChannels = payload
  }, 
  SET_COURSE_CHANNELS (state, payload) {
    if (payload !== null) {
      for (const ic of payload) {
        if (ic.order_id === null) ic.order_id = 12 // missing data from sql database
        ic.isSelected = false
      }
    }
    payload.sort((a, b) => { return a.order_id - b.order_id })
    state.courseChannels = payload
  }, 
  UPDATE_COURSE_CHANNELS (state, payload) {
    state.courseChannels = payload
  }, 
  SET_CURRENT_CHANNEL (state, channel) {
    state.currentChannel = channel
  },
  SET_UNIVERSITY_CHANNEL (state, payload) {
    payload.isSelected = false
    state.universityChannel = payload
  },
  SET_COURSE_CHANNEL (state, payload) {
    payload.isSelected = false
    state.courseChannel = payload
  },
  SET_COURSEFEED_CHANNEL (state, { channels, id }) {
    for (const channel of channels) {
      if (channel.id === id) {
        channel.isSelected = true
      } else {
        channel.isSelected = false
      }
    }
    
    state.courseFeedChannels = channels
  },
  SET_SUGGESTED_CHANNELS (state, payload) {
    if (payload !== null) {
      for (const sc of payload) {
        sc.isSelected = false
        sc.isActive = false
        if (state.interestedChannels.length !== 0) {
          for (const ic of state.interestedChannels) {
            if (sc.channel_id === ic.channel_id) {
              sc.isActive = true
              break
            }
          }
        }
      }
    }
    state.suggestedChannels = payload
  },
  SWITCH_ACTIVE_STATE (state, { channel, type }) {
    state.PUBLIC_CHANNEL.isSelected = false
    if (state.universityChannel) state.universityChannel.isSelected = false
    if (state.courseChannel) state.courseChannel.isSelected = false
    if (state.courseFeedChannels) {
      for (const courseChannel of state.courseFeedChannels) {
        courseChannel.isSelected = false
      }
    } 
    if (state.interestedChannels.length !== 0) {
      state.interestedChannels.map(ic => { ic.isSelected = false })
    }
    if (state.universityChannels.length !== 0) {
      state.universityChannels.map(ic => { ic.isSelected = false })
    }
    if (state.courseChannels.length !== 0) {
      state.courseChannels.map(ic => { ic.isSelected = false })
    }
    if (type === 'PUBLIC') {
      state.PUBLIC_CHANNEL.isSelected = true
    } else if (type === 'COURSE') {
      for (const cc of state.courseFeedChannels) {
        if (cc.id === channel.id) {
          cc.isSelected = true
          break
        }
      }
      state.courseFeedChannels.isSelected = true
    } else if (type === 'UNIVERSITY') {
      state.universityChannel.isSelected = true

    } else if (type === 'UNIVERSITYCOURSE') {
      state.courseChannel.isSelected = true
    } else if (type === 'INTEREST') {
      for (const ic of state.interestedChannels) {
        if (ic.channel_id === channel.channel_id) {
          ic.isSelected = true
          break
        }
      }
    }else if (type === 'UNIVERSITY_CHANNELS') {
      for (const ic of state.universityChannels) {
        if (ic.channel_id === channel.channel_id) {
          ic.isSelected = true
          break
        }
      }
    }else if (type === 'UNIVERSITYCOURSE_CHANNELS') {
      for (const ic of state.courseChannels) {
        if (ic.channel_id === channel.channel_id) {
          ic.isSelected = true
          break
        }
      }
    }
  },
  ACTIVATE_SUGGESTED_CHANNEL (state, channelIndex) {
    if (state.suggestedChannels[channelIndex] !== null) {
      state.suggestedChannels[channelIndex].isActive = !state.suggestedChannels[channelIndex].isActive
    }
  }
}

export default mutations