import {
    Base64
} from 'js-base64';
const cloudFrontUrl = 'https://d1b2hgagtlcmzj.cloudfront.net'
const BUCKET_NAME = 'coursepalconnectbucket165351-production'
export const getCompressImageUrl = (imageKey, width) => {
  const imageRequest = JSON.stringify({
    bucket: BUCKET_NAME,
    key: `public/${imageKey}`,
    edits: {
      resize: {
        width,
        fit: 'contain'
      },
      normalize: true
    }
  })
  // const url = `${cloudFrontUrl}/${btoa(imageRequest)}`
  const url = `${cloudFrontUrl}/${Base64.encode(imageRequest)}`
  return url
}

export const createErrorObject = (title, content) => {
  return { 
    title, 
    text: content, 
    iconPack: 'feather', 
    icon: 'icon-alert-circle', 
    color: 'danger', 
    position: 'top-right', 
  }
}

export const createSuccessObject = (title, content) => {
  return { 
    title, 
    text: content, 
    iconPack: 'feather', 
    icon: 'icon-check', 
    color: 'success', 
    position: 'top-right' }
}

export const createWarningObject = (title, content) => {
  return { 
    title, 
    text: content, 
    iconPack: 'feather', 
    icon: 'icon-alert-circle', 
    color: 'warning', 
    position: 'top-right' }
}

export const errorLog = (err) => {
  if (process.env === 'production') {
    console.log(err)
  }
}
