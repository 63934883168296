export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          items {
            follower
          }
          nextToken
        }
        following {
          items {
            user
          }
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;