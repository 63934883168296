export default {
  feeds: [],
  myFeeds: [],
  LIMIT: 50,
  currentPost: '',
  editPost: {},
  editFeedIndex: 0,
  isEditorActive: false,
  isLoading: true,
  postNextToken: '',
  likeNextToken: '',
  commentNextToken: '',
}