
import * as firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyB7lEluR7GAjmOAOzLUOBejT5eiSE5CDGo',
  authDomain: 'coursepal-12f95.firebaseapp.com',
  databaseURL: 'https://coursepal-12f95.firebaseio.com',
  projectId: 'coursepal-12f95',
  storageBucket: 'coursepal-12f95.appspot.com',
  messagingSenderId: '466443698194',
  appId: '1:466443698194:web:b408a20e54eebe24d918cb',
  measurementId: 'G-E9VMD61B7P'
}

firebase.initializeApp(firebaseConfig)
let messaging = null
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  messaging.usePublicVapidKey('BHaCrUQTSOQCKAcMteqeAmN-pKmoYBxWn5Ro14E1gUHU39Z-bD_VQyyV5abeOcjzG3Af5RnOzV-zkXGHajOHqoc')
}

export default messaging

