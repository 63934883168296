/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewsfeed = /* GraphQL */ `
  query GetNewsfeed(
    $name: String
    $channelID: String
    $limit: Int
    $postNextToken: String
    $likeNextToken: String
    $commentNextToken: String
  ) {
    getNewsfeed(
      name: $name
      channelID: $channelID
      limit: $limit
      postNextToken: $postNextToken
      likeNextToken: $likeNextToken
      commentNextToken: $commentNextToken
    ) {
      newsfeed {
        actor
        action
        postRef {
          id
          author
          sharePostID
          content
          medias
          
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
      }
      postNextToken
      likeNextToken
      commentNextToken
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($user: String, $limit: Int, $nextPage: Int) {
    getNotifications(user: $user, limit: $limit, nextPage: $nextPage) {
      id
      type
      ref
      content
      sender
      receiver
      seen
      timestamp
      refTable
    }
  }
`;
export const getSuggestedFollows = /* GraphQL */ `
  query GetSuggestedFollows(
    $user: String
    $university: String
    $course: String
    $channelID: String
    $channelType: CHANNEL_TYPE
  ) {
    getSuggestedFollows(
      user: $user
      university: $university
      course: $course
      channelID: $channelID
      channelType: $channelType
    ) {
      name
      displayName
      # email
      # loginType
      # university
      # universityName
      # studyArea
      # studyAreaName
      # course
      # courseName
      studyStatus
      about
      headline
      # interestedChannels
      # universityChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      # isPartner
      # isFirstLogin
      # birthday
      # gender
      # location
      uniEmail
      isUniEmailVerify
      # isIntroClosed
      # # stripeAccountKey
      # # stripeConnectedAccountKey
      # facebookLink
      # twitterLink
      # linkedinLink
      # instagramLink
      # webLink
      # enablePostNotification
      # enableNftNotification
      createdAt
      updatedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($name: String!) {
    getUser(name: $name) {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      # universityChannels
      photoURL
      coverPhotoURL
      # walletAccounts
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      stripeConnectedAccountKey
      facebookLink
      twitterLink
      linkedinLink
      instagramLink
      webLink
      enablePostNotification
      enableNftNotification
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $name: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUniEmailVerify = /* GraphQL */ `
  query GetUniEmailVerify($id: ID!) {
    getUniEmailVerify(id: $id) {
      id
      user
      code
      createdAt
      updatedAt
    }
  }
`;
export const listUniEmailVerifys = /* GraphQL */ `
  query ListUniEmailVerifys(
    $filter: ModelUniEmailVerifyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUniEmailVerifys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        skillLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducation = /* GraphQL */ `
  query GetEducation($id: ID!) {
    getEducation(id: $id) {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const listEducations = /* GraphQL */ `
  query ListEducations(
    $filter: ModelEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        university
        degree
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkExperience = /* GraphQL */ `
  query GetWorkExperience($id: ID!) {
    getWorkExperience(id: $id) {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const listWorkExperiences = /* GraphQL */ `
  query ListWorkExperiences(
    $filter: ModelWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        position
        company
        description
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        title
        issueBy
        issueDate
        location
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        phone
        overview
        website
        partnerType
        mainOffice
        companySize
        industry
        organizationType
        founded
        specialities
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducator = /* GraphQL */ `
  query GetEducator($id: ID!) {
    getEducator(id: $id) {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const listEducators = /* GraphQL */ `
  query ListEducators(
    $filter: ModelEducatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExpertise = /* GraphQL */ `
  query GetExpertise($id: ID!) {
    getExpertise(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listExpertises = /* GraphQL */ `
  query ListExpertises(
    $filter: ModelExpertiseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpertises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducatorService = /* GraphQL */ `
  query GetEducatorService($id: ID!) {
    getEducatorService(id: $id) {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const listEducatorServices = /* GraphQL */ `
  query ListEducatorServices(
    $filter: ModelEducatorServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducatorServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          items {
            id
            serviceID
            name
            description
            price
            discountPrice
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducatorServicePackage = /* GraphQL */ `
  query GetEducatorServicePackage($id: ID!) {
    getEducatorServicePackage(id: $id) {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const listEducatorServicePackages = /* GraphQL */ `
  query ListEducatorServicePackages(
    $filter: ModelEducatorServicePackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducatorServicePackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        name
        description
        price
        discountPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducatorSessionBooking = /* GraphQL */ `
  query GetEducatorSessionBooking($id: ID!) {
    getEducatorSessionBooking(id: $id) {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const listEducatorSessionBookings = /* GraphQL */ `
  query ListEducatorSessionBookings(
    $filter: ModelEducatorSessionBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducatorSessionBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        educatorServiceID
        serviceRef {
          id
          educatorID          
          educatorRef {
            id
            user
            languages
            skills
            yearOfExp
            profession
            servicesOffered
            category
            subjects
            pricing
            createdAt
            updatedAt
          }
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        dates
        timeslots
        purpose
        message
        packageID
        isAccepted
        zoomSignature
        zoomPassword
        isOnline
        createdAt
        stripeChargeId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSearchedToken = /* GraphQL */ `
  query GetSearchedToken($id: ID!) {
    getSearchedToken(id: $id) {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listSearchedTokens = /* GraphQL */ `
  query ListSearchedTokens(
    $filter: ModelSearchedTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchedTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        tokens
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportPost = /* GraphQL */ `
  query GetReportPost($id: ID!) {
    getReportPost(id: $id) {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const listReportPosts = /* GraphQL */ `
  query ListReportPosts(
    $filter: ModelReportPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reporter
        reporterRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        postID
        postRef {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOgMetaData = /* GraphQL */ `
  query GetOgMetaData($id: ID!) {
    getOgMetaData(id: $id) {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const listOgMetaDatas = /* GraphQL */ `
  query ListOgMetaDatas(
    $filter: ModelOgMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOgMetaDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        ogTitle
        ogDescription
        ogVideoUrl
        ogVideoHeight
        ogVideoWidth
        ogVideoType
        ogImageUrl
        ogImageHeight
        ogImageWidth
        ogImageType
        ogDate
        ogSiteName
        ogUrl
        requestUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      author
      authorRef {
        name
        # isMinting
        displayName
        # email
        # loginType
        # university
        # universityName
        isUniEmailVerify
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubComment = /* GraphQL */ `
  query GetSubComment($id: ID!) {
    getSubComment(id: $id) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listSubComments = /* GraphQL */ `
  query ListSubComments(
    $filter: ModelSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels          
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikeComment = /* GraphQL */ `
  query GetLikeComment($id: ID!) {
    getLikeComment(id: $id) {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLikeComments = /* GraphQL */ `
  query ListLikeComments(
    $filter: ModelLikeCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikeComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikeSubComment = /* GraphQL */ `
  query GetLikeSubComment($id: ID!) {
    getLikeSubComment(id: $id) {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLikeSubComments = /* GraphQL */ `
  query ListLikeSubComments(
    $filter: ModelLikeSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikeSubComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        subCommentID
        subComment {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        # interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        # isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        seen
        postID
        sender
        senderRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationComment = /* GraphQL */ `
  query GetNotificationComment($id: ID!) {
    getNotificationComment(id: $id) {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        # interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationComments = /* GraphQL */ `
  query ListNotificationComments(
    $filter: ModelNotificationCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        commentID
        commentRef {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationSubComment = /* GraphQL */ `
  query GetNotificationSubComment($id: ID!) {
    getNotificationSubComment(id: $id) {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationSubComments = /* GraphQL */ `
  query ListNotificationSubComments(
    $filter: ModelNotificationSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationSubComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        subCommentID
        subCommentRef {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          # createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationFollow = /* GraphQL */ `
  query GetNotificationFollow($id: ID!) {
    getNotificationFollow(id: $id) {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationFollows = /* GraphQL */ `
  query ListNotificationFollows(
    $filter: ModelNotificationFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationFollows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        sender
        senderRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFirebaseMessaging = /* GraphQL */ `
  query GetFirebaseMessaging($user: String!) {
    getFirebaseMessaging(user: $user) {
      id
      user
      token
      createdAt
      updatedAt
    }
  } 
`;
export const listFirebaseMessagings = /* GraphQL */ `
  query ListFirebaseMessagings(
    $user: String
    $filter: ModelFirebaseMessagingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFirebaseMessagings(
      user: $user
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollow = /* GraphQL */ `
  query GetFollow($user: String!, $follower: String!) {
    getFollow(user: $user, follower: $follower) {
      user
      follower
      userInstance {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        # studyStatus
        about
        headline
        # interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        # isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFollows = /* GraphQL */ `
  query ListFollows(
    $user: String
    $follower: ModelStringKeyConditionInput
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFollows(
      user: $user
      follower: $follower
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user
        follower
        userInstance {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          # photoURL
          # coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        followerInstance {
          name
          displayName
          email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserConversation = /* GraphQL */ `
  query GetUserConversation($id: ID!) {
    getUserConversation(id: $id) {
      id
      user
      userRef {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        # interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        # isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        # interestedChannels
        # universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        # isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listUserConversations = /* GraphQL */ `
  query ListUserConversations(
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          # about
          # headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationID
        sender
        senderRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        isSent
        isSeen
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserGroupConversation = /* GraphQL */ `
  query GetUserGroupConversation($id: ID!) {
    getUserGroupConversation(id: $id) {
      id
      user
      userRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const listUserGroupConversations = /* GraphQL */ `
  query ListUserGroupConversations(
    $filter: ModelUserGroupConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroupConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          groupName
          groupImageURL
          createdAt
          updatedAt
        }
        isDeleted
        isBlocked
        isAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupConversation = /* GraphQL */ `
  query GetGroupConversation($id: ID!) {
    getGroupConversation(id: $id) {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupConversations = /* GraphQL */ `
  query ListGroupConversations(
    $filter: ModelGroupConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupMessage = /* GraphQL */ `
  query GetGroupMessage($id: ID!) {
    getGroupMessage(id: $id) {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # # stripeAccountKey
        # # stripeConnectedAccountKey
        # facebookLink
        # twitterLink
        # linkedinLink
        # instagramLink
        # webLink
        # enablePostNotification
        # enableNftNotification
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listGroupMessages = /* GraphQL */ `
  query ListGroupMessages(
    $filter: ModelGroupMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupConversationID
        sender
        senderRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        isSeen
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookmarkJob = /* GraphQL */ `
  query GetBookmarkJob($id: ID!) {
    getBookmarkJob(id: $id) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookmarkJobs = /* GraphQL */ `
  query ListBookmarkJobs(
    $filter: ModelBookmarkJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarkJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        jobId
        jobRef {
          id
          title
          createdAt
          type
          location
          jobArea
          description
          author
          salary
          commitment
          length
          skills
          url
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventBookmark = /* GraphQL */ `
  query GetEventBookmark($id: ID!) {
    getEventBookmark(id: $id) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEventBookmarks = /* GraphQL */ `
  query ListEventBookmarks(
    $filter: ModelEventBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        eventID
        eventRef {
          id
          title
          category
          startDate
          endDate
          zoomLink
          city
          address
          presenter
          offerer
          description
          price
          discountPrice
          method
          url
          image
          videoUrl
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseBookmark = /* GraphQL */ `
  query GetCourseBookmark($id: ID!) {
    getCourseBookmark(id: $id) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseBookmarks = /* GraphQL */ `
  query ListCourseBookmarks(
    $filter: ModelCourseBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        courseID
        courseRef {
          id
          title
          category
          educator
          offerer
          description
          price
          discountPrice
          method
          city
          address
          image
          videoUrl
          status
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        name
        isWorking
        startDate
        endDate
        contributors
        associatedWith
        projectURL
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCertificate = /* GraphQL */ `
  query GetCertificate($id: ID!) {
    getCertificate(id: $id) {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const listCertificates = /* GraphQL */ `
  query ListCertificates(
    $filter: ModelCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        name
        description
        completeDate
        studyArea
        url
        grade
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseFeed = /* GraphQL */ `
  query GetCourseFeed($id: ID!) {
    getCourseFeed(id: $id) {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const listCourseFeeds = /* GraphQL */ `
  query ListCourseFeeds(
    $filter: ModelCourseFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseFeedSubscriber = /* GraphQL */ `
  query GetCourseFeedSubscriber($id: ID!) {
    getCourseFeedSubscriber(id: $id) {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const listCourseFeedSubscribers = /* GraphQL */ `
  query ListCourseFeedSubscribers(
    $filter: ModelCourseFeedSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseFeedSubscribers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseFeed
        courseFeedRef {
          id
          title
          user
          category
          topics
          headline
          description
          type
          price
          discountPrice
          image
          video
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        stripeChargeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByUniversity = /* GraphQL */ `
  query UserByUniversity(
    $university: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUniversity(
      university: $university
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmailAndLoginType = /* GraphQL */ `
  query UserByEmailAndLoginType(
    $email: String
    $loginType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmailAndLoginType(
      email: $email
      loginType: $loginType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        courseChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        stripeAccountKey
        stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const uniEmailVerifyByUser = /* GraphQL */ `
  query UniEmailVerifyByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUniEmailVerifyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uniEmailVerifyByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const skillByUser = /* GraphQL */ `
  query SkillByUser(
    $user: String
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillByUser(
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        skillLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const educationByUser = /* GraphQL */ `
  query EducationByUser(
    $user: String
    $from: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    educationByUser(
      user: $user
      from: $from
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        university
        degree
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const workExperienceByUser = /* GraphQL */ `
  query WorkExperienceByUser(
    $user: String
    $from: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workExperienceByUser(
      user: $user
      from: $from
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        position
        company
        description
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const achievementByUser = /* GraphQL */ `
  query AchievementByUser(
    $user: String
    $issueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    achievementByUser(
      user: $user
      issueDate: $issueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        title
        issueBy
        issueDate
        location
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const partnerByUser = /* GraphQL */ `
  query PartnerByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        overview
        website
        partnerType
        mainOffice
        companySize
        industry
        organizationType
        founded
        specialities
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const partnerByType = /* GraphQL */ `
  query PartnerByType(
    $partnerType: PARTNER_TYPE
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerByType(
      partnerType: $partnerType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        overview
        website
        partnerType
        mainOffice
        companySize
        industry
        organizationType
        founded
        specialities
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const educatorByUser = /* GraphQL */ `
  query EducatorByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    educatorByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const expertiseByName = /* GraphQL */ `
  query ExpertiseByName(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpertiseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expertiseByName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const educatorServicesByEducatorId = /* GraphQL */ `
  query EducatorServicesByEducatorId(
    $educatorID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducatorServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    educatorServicesByEducatorID(
      educatorID: $educatorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          items {
            id
            serviceID
            name
            description
            price
            discountPrice
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const packagesByServiceId = /* GraphQL */ `
  query PackagesByServiceId(
    $serviceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducatorServicePackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesByServiceID(
      serviceID: $serviceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        name
        description
        price
        discountPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionBookingByUser = /* GraphQL */ `
  query SessionBookingByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducatorSessionBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionBookingByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        educatorServiceID
        serviceRef {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        dates
        timeslots
        purpose
        message
        packageID
        isAccepted
        zoomSignature
        zoomPassword
        isOnline
        createdAt
        stripeChargeId
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionBookingByServiceId = /* GraphQL */ `
  query SessionBookingByServiceId(
    $educatorServiceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEducatorSessionBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionBookingByServiceID(
      educatorServiceID: $educatorServiceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        educatorServiceID
        serviceRef {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey'
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        dates
        timeslots
        purpose
        message
        packageID
        isAccepted
        zoomSignature
        zoomPassword
        isOnline
        createdAt
        stripeChargeId
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchedTokenByUser = /* GraphQL */ `
  query SearchedTokenByUser(
    $user: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSearchedTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchedTokenByUser(
      user: $user
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        tokens
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reportPostByReporter = /* GraphQL */ `
  query ReportPostByReporter(
    $reporter: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportPostByReporter(
      reporter: $reporter
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reporter
        reporterRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        postID
        postRef {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reportPostByPostId = /* GraphQL */ `
  query ReportPostByPostId(
    $postID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportPostByPostID(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reporter
        reporterRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        postID
        postRef {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ogDataByPost = /* GraphQL */ `
  query OgDataByPost(
    $postID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOgMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ogDataByPost(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        ogTitle
        ogDescription
        ogVideoUrl
        ogVideoHeight
        ogVideoWidth
        ogVideoType
        ogImageUrl
        ogImageHeight
        ogImageWidth
        ogImageType
        ogDate
        ogSiteName
        ogUrl
        requestUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByAuthor = /* GraphQL */ `
  query PostsByAuthor(
    $author: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByAuthor(
      author: $author
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        # isMinting
        mediaFiles
        author
        authorRef {
          name
          displayName
          #  email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByChannel = /* GraphQL */ `
  query PostsByChannel(
    $channelID: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByChannel(
      channelID: $channelID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentByPost = /* GraphQL */ `
  query CommentByPost(
    $postID: ID
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentByPost(
      postID: $postID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentByAuthor = /* GraphQL */ `
  query CommentByAuthor(
    $author: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentByAuthor(
      author: $author
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCommentByComment = /* GraphQL */ `
  query SubCommentByComment(
    $commentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SubCommentByComment(
      commentID: $commentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeByPost = /* GraphQL */ `
  query LikeByPost(
    $postID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeByPost(
      postID: $postID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeByUser = /* GraphQL */ `
  query LikeByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeByComment = /* GraphQL */ `
  query LikeByComment(
    $commentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLikeCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeByComment(
      commentID: $commentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeBySubComment = /* GraphQL */ `
  query LikeBySubComment(
    $subCommentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLikeSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeBySubComment(
      subCommentID: $subCommentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        subCommentID
        subComment {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByPost = /* GraphQL */ `
  query NotificationByPost(
    $postID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByPost(
      postID: $postID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        postID
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByReceiver = /* GraphQL */ `
  query NotificationByReceiver(
    $receiver: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByReceiver(
      receiver: $receiver
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        postID
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByComment = /* GraphQL */ `
  query NotificationByComment(
    $commentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByComment(
      commentID: $commentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        commentID
        commentRef {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByReceiverComment = /* GraphQL */ `
  query NotificationByReceiverComment(
    $receiver: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByReceiverComment(
      receiver: $receiver
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        commentID
        commentRef {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationBySubComment = /* GraphQL */ `
  query NotificationBySubComment(
    $subCommentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationBySubComment(
      subCommentID: $subCommentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        subCommentID
        subCommentRef {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByReceiverSubComment = /* GraphQL */ `
  query NotificationByReceiverSubComment(
    $receiver: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByReceiverSubComment(
      receiver: $receiver
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        subCommentID
        subCommentRef {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationBySenderFollow = /* GraphQL */ `
  query NotificationBySenderFollow(
    $sender: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationBySenderFollow(
      sender: $sender
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByReceiverFollow = /* GraphQL */ `
  query NotificationByReceiverFollow(
    $receiver: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByReceiverFollow(
      receiver: $receiver
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        seen
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowers = /* GraphQL */ `
  query GetFollowers(
    $user: String
    $follower: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFollowers(
      user: $user
      follower: $follower
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        follower
        userInstance {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          # createdAt
          # updatedAt
        }
        followerInstance {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          photoURL
          coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing(
    $follower: String
    $user: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFollowing(
      follower: $follower
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        follower
        userInstance {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          # uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        followerInstance {
          name
          displayName
          # email
          # loginType
          # university
          # universityName
          # studyArea
          # studyAreaName
          # course
          # courseName
          studyStatus
          about
          headline
          # interestedChannels
          # universityChannels
          # photoURL
          # coverPhotoURL
          # isPartner
          # isFirstLogin
          # birthday
          # gender
          # location
          uniEmail
          isUniEmailVerify
          # isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversationByUser = /* GraphQL */ `
  query ConversationByUser(
    $user: String
    $conversationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationByUser(
      user: $user
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversationByReceiver = /* GraphQL */ `
  query ConversationByReceiver(
    $receiver: String
    $conversationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationByReceiver(
      receiver: $receiver
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByConversation = /* GraphQL */ `
  query UserByConversation(
    $conversationID: ID
    $user: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByConversation(
      conversationID: $conversationID
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        receiver
        receiverRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          createdAt
          updatedAt
        }
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByConversation = /* GraphQL */ `
  query MessageByConversation(
    $conversationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByConversation(
      conversationID: $conversationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conversationID
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        isSent
        isSeen
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const groupConversationByUser = /* GraphQL */ `
  query GroupConversationByUser(
    $user: String
    $conversationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupConversationByUser(
      user: $user
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          groupName
          groupImageURL
          createdAt
          updatedAt
        }
        isDeleted
        isBlocked
        isAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByGroupConversation = /* GraphQL */ `
  query UserByGroupConversation(
    $conversationID: ID
    $user: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByGroupConversation(
      conversationID: $conversationID
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        conversationID
        conversationRef {
          id
          groupName
          groupImageURL
          createdAt
          updatedAt
        }
        isDeleted
        isBlocked
        isAdmin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messageByGroupConversation = /* GraphQL */ `
  query MessageByGroupConversation(
    $groupConversationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByGroupConversation(
      groupConversationID: $groupConversationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupConversationID
        sender
        senderRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        content
        isSeen
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookmarkJobByUser = /* GraphQL */ `
  query BookmarkJobByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookmarkJobByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        jobId
        jobRef {
          id
          title
          createdAt
          type
          location
          jobArea
          description
          author
          salary
          commitment
          length
          skills
          url
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bookmarkJobByJob = /* GraphQL */ `
  query BookmarkJobByJob(
    $jobId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookmarkJobByJob(
      jobId: $jobId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        jobId
        jobRef {
          id
          title
          createdAt
          type
          location
          jobArea
          description
          author
          salary
          commitment
          length
          skills
          url
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobByUser = /* GraphQL */ `
  query JobByUser(
    $author: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByUser(
      author: $author
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobByLocation = /* GraphQL */ `
  query JobByLocation(
    $location: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByLocation(
      location: $location
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          # email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          # birthday
          # gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # # stripeAccountKey
          # # stripeConnectedAccountKey
          # facebookLink
          # twitterLink
          # linkedinLink
          # instagramLink
          # webLink
          # enablePostNotification
          # enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobByType = /* GraphQL */ `
  query JobByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByCategory = /* GraphQL */ `
  query EventByCategory(
    $category: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByCity = /* GraphQL */ `
  query EventByCity(
    $city: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByCity(
      city: $city
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByPresenter = /* GraphQL */ `
  query EventByPresenter(
    $presenter: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByPresenter(
      presenter: $presenter
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByOfferer = /* GraphQL */ `
  query EventByOfferer(
    $offerer: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByOfferer(
      offerer: $offerer
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventBookmarkByUser = /* GraphQL */ `
  query EventBookmarkByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBookmarkByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        eventID
        eventRef {
          id
          title
          category
          startDate
          endDate
          zoomLink
          city
          address
          presenter
          offerer
          description
          price
          discountPrice
          method
          url
          image
          videoUrl
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventBookmarkByEvent = /* GraphQL */ `
  query EventBookmarkByEvent(
    $eventID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBookmarkByEvent(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        eventID
        eventRef {
          id
          title
          category
          startDate
          endDate
          zoomLink
          city
          address
          presenter
          offerer
          description
          price
          discountPrice
          method
          url
          image
          videoUrl
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseByCategory = /* GraphQL */ `
  query CourseByCategory(
    $category: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseByEducator = /* GraphQL */ `
  query CourseByEducator(
    $educator: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseByEducator(
      educator: $educator
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseByCity = /* GraphQL */ `
  query CourseByCity(
    $city: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseByCity(
      city: $city
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseByOfferer = /* GraphQL */ `
  query CourseByOfferer(
    $offerer: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseByOfferer(
      offerer: $offerer
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseBookmarkByUser = /* GraphQL */ `
  query CourseBookmarkByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseBookmarkByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        courseID
        courseRef {
          id
          title
          category
          educator
          offerer
          description
          price
          discountPrice
          method
          city
          address
          image
          videoUrl
          status
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseBookmarkByCourse = /* GraphQL */ `
  query CourseBookmarkByCourse(
    $courseID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseBookmarkByCourse(
      courseID: $courseID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        courseID
        courseRef {
          id
          title
          category
          educator
          offerer
          description
          price
          discountPrice
          method
          city
          address
          image
          videoUrl
          status
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectByUser = /* GraphQL */ `
  query ProjectByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        name
        isWorking
        startDate
        endDate
        contributors
        associatedWith
        projectURL
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const certificateByUser = /* GraphQL */ `
  query CertificateByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificateByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        name
        description
        completeDate
        studyArea
        url
        grade
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseFeedByUser = /* GraphQL */ `
  query CourseFeedByUser(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseFeedByUser(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseFeedSubscriptionByUser = /* GraphQL */ `
  query CourseFeedSubscriptionByUser(
    $user: String
    $courseFeed: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFeedSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseFeedSubscriptionByUser(
      user: $user
      courseFeed: $courseFeed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseFeed
        courseFeedRef {
          id
          title
          user
          category
          topics
          headline
          description
          type
          price
          discountPrice
          image
          video
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        stripeChargeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const courseFeedSubscriptionByCourseFeed = /* GraphQL */ `
  query CourseFeedSubscriptionByCourseFeed(
    $courseFeed: ID
    $user: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFeedSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    courseFeedSubscriptionByCourseFeed(
      courseFeed: $courseFeed
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseFeed
        courseFeedRef {
          id
          title
          user
          category
          topics
          headline
          description
          type
          price
          discountPrice
          image
          video
          createdAt
          status
          updatedAt
        }
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        stripeChargeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const topicsByCategory = /* GraphQL */ `
  query TopicsByCategory(
    $category: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicsByCategory(
      category: $category
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        universityChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        # stripeAccountKey
        # stripeConnectedAccountKey
        facebookLink
        twitterLink
        linkedinLink
        instagramLink
        webLink
        enablePostNotification
        enableNftNotification
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchJobs = /* GraphQL */ `
  query SearchJobs(
    $filter: SearchableJobFilterInput
    $sort: SearchableJobSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchJobs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchEvents = /* GraphQL */ `
  query SearchEvents(
    $filter: SearchableEventFilterInput
    $sort: SearchableEventSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEvents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: SearchableCourseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          universityChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          # stripeAccountKey
          # stripeConnectedAccountKey
          facebookLink
          twitterLink
          linkedinLink
          instagramLink
          webLink
          enablePostNotification
          enableNftNotification
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
