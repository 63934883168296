const mutations = {
  SET_SUGGESTED_FOLLOWS (state, payload) {
    let followers_with_image = []
    let followers_without_image = []
    for (let index = 0; index < payload.items.length; index++) {
        const element = payload.items[index];
        if(element.photoURL.split('/')[element.photoURL.split('/').length-1] !== 'default_dp.png'){
          followers_with_image.push(element);
        }else{
          followers_without_image.push(element);
        }
    }
    let suggestedFollows = followers_with_image.concat(followers_without_image); 
    state.suggestedFollows = suggestedFollows;
    state.nextToken = payload.nextToken
  },
  REMOVE_SUGGESTED_FOLLOW (state, index) {
    state.suggestedFollows.splice(index, 1)
  },
  SET_FOLLOWERS (state, payload) {
    state.followers = payload
  },
  SET_FOLLOWINGS (state, payload) {
    state.followings = payload
  },
  REMOVE_CURRENT_FOLLOW (state, userInstance) {
    let follows = state.followings.items.map(f => f.user)
    let removeIndex = follows.indexOf(userInstance.name)

    if (removeIndex > -1) {
      state.followings.items.splice(removeIndex, 1)
    }
  }
}

export default mutations