export const createSubComment = /* GraphQL */ `
  mutation CreateSubComment(
    $input: CreateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    createSubComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          items {
            follower
          }
          nextToken
        }
        following {
          items {
            user
          }
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;