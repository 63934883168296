export default {
  pages: {
    key: "title",
    data: [
      {
        title: "Dashboard",
        url: "/dashboard/analytics",
        icon: "HomeIcon",
        is_bookmarked: false
      },

      {
        title: "Personal info",
        url: "/myprofile",
        icon: "UserIcon",
        is_bookmarked: false
      },
      {
        title: "Applied",
        url: "/applied",
        icon: "BookmarkIcon",
        is_bookmarked: false
      },

      {
        title: "Enquired",
        url: "/enquired",
        icon: "BookmarkIcon",
        is_bookmarked: false
      },
      {
        title: "Shortlisted",
        url: "/bookmarks",
        icon: "BookmarkIcon",
        is_bookmarked: false
      },
      {
        title: "Account Info",
        url: "/MyAccount",
        icon: "Settings",
        is_bookmarked: false
      },
      {
        title: "Chats",
        url: "/apps/email",
        icon: "MessageCircleIcon",
        is_bookmarked: false
      },
      {
        title: "Home",
        url: "/",
        icon: "HomeIcon",
        is_bookmarked: true
      },
      {
        title: "How its works",
        url: "/howitworks",
        icon: "HelpCircleIcon",
        is_bookmarked: true
      },
      {
        title: "Course Search",
        url: "/AddCourse",
        icon: "SearchIcon",
        is_bookmarked: true
      }
    ]
  }
};
