/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      # universityChannels
      # courseChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      stripeConnectedAccountKey
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      universityChannels
      courseChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      # stripeConnectedAccountKey
      facebookLink
      twitterLink
      linkedinLink
      instagramLink
      webLink
      enablePostNotification
      enableNftNotification
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      name
      displayName
      email
      loginType
      university
      universityName
      studyArea
      studyAreaName
      course
      courseName
      studyStatus
      about
      headline
      interestedChannels
      photoURL
      coverPhotoURL
      posts {
        items {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          seen
          postID
          sender
          receiver
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      followers {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          user
          follower
          createdAt
          updatedAt
        }
        nextToken
      }
      isPartner
      isFirstLogin
      birthday
      gender
      location
      uniEmail
      isUniEmailVerify
      isIntroClosed
      stripeAccountKey
      # stripeConnectedAccountKey
      createdAt
      updatedAt
    }
  }
`;
// export const createUniemailVerify = /* GraphQL */ `
//   mutation CreateUniemailVerify(
//     $input: CreateUniemailVerifyInput!
//     $condition: ModelUniemailVerifyConditionInput
//   ) {
//     createUniemailVerify(input: $input, condition: $condition) {
//       id
//       user
//       code
//       createdAt
//       updatedAt
//     }
//   }
// `;
// export const updateUniemailVerify = /* GraphQL */ `
//   mutation UpdateUniemailVerify(
//     $input: UpdateUniemailVerifyInput!
//     $condition: ModelUniemailVerifyConditionInput
//   ) {
//     updateUniemailVerify(input: $input, condition: $condition) {
//       id
//       user
//       code
//       createdAt
//       updatedAt
//     }
//   }
// `;
// export const deleteUniemailVerify = /* GraphQL */ `
//   mutation DeleteUniemailVerify(
//     $input: DeleteUniemailVerifyInput!
//     $condition: ModelUniemailVerifyConditionInput
//   ) {
//     deleteUniemailVerify(input: $input, condition: $condition) {
//       id
//       user
//       code
//       createdAt
//       updatedAt
//     }
//   }
// `;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      user
      skillLabel
      createdAt
      updatedAt
    }
  }
`;
export const createEducation = /* GraphQL */ `
  mutation CreateEducation(
    $input: CreateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    createEducation(input: $input, condition: $condition) {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const updateEducation = /* GraphQL */ `
  mutation UpdateEducation(
    $input: UpdateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    updateEducation(input: $input, condition: $condition) {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const deleteEducation = /* GraphQL */ `
  mutation DeleteEducation(
    $input: DeleteEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    deleteEducation(input: $input, condition: $condition) {
      id
      user
      university
      degree
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const createWorkExperience = /* GraphQL */ `
  mutation CreateWorkExperience(
    $input: CreateWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    createWorkExperience(input: $input, condition: $condition) {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkExperience = /* GraphQL */ `
  mutation UpdateWorkExperience(
    $input: UpdateWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    updateWorkExperience(input: $input, condition: $condition) {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkExperience = /* GraphQL */ `
  mutation DeleteWorkExperience(
    $input: DeleteWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    deleteWorkExperience(input: $input, condition: $condition) {
      id
      user
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      user
      title
      issueBy
      issueDate
      location
      description
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      user
      phone
      overview
      website
      partnerType
      mainOffice
      companySize
      industry
      organizationType
      founded
      specialities
      createdAt
      updatedAt
    }
  }
`;
export const createEducator = /* GraphQL */ `
  mutation CreateEducator(
    $input: CreateEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    createEducator(input: $input, condition: $condition) {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const updateEducator = /* GraphQL */ `
  mutation UpdateEducator(
    $input: UpdateEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    updateEducator(input: $input, condition: $condition) {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const deleteEducator = /* GraphQL */ `
  mutation DeleteEducator(
    $input: DeleteEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    deleteEducator(input: $input, condition: $condition) {
      id
      user
      languages
      skills
      yearOfExp
      profession
      servicesOffered
      category
      subjects
      services {
        items {
          id
          educatorID
          title
          servicesOffered
          category
          subjects
          description
          date
          duration
          session
          availability
          images
          autoMessage
          createdAt
          status
          updatedAt
        }
        nextToken
      }
      pricing
      createdAt
      updatedAt
    }
  }
`;
export const createExpertise = /* GraphQL */ `
  mutation CreateExpertise(
    $input: CreateExpertiseInput!
    $condition: ModelExpertiseConditionInput
  ) {
    createExpertise(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateExpertise = /* GraphQL */ `
  mutation UpdateExpertise(
    $input: UpdateExpertiseInput!
    $condition: ModelExpertiseConditionInput
  ) {
    updateExpertise(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteExpertise = /* GraphQL */ `
  mutation DeleteExpertise(
    $input: DeleteExpertiseInput!
    $condition: ModelExpertiseConditionInput
  ) {
    deleteExpertise(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createEducatorService = /* GraphQL */ `
  mutation CreateEducatorService(
    $input: CreateEducatorServiceInput!
    $condition: ModelEducatorServiceConditionInput
  ) {
    createEducatorService(input: $input, condition: $condition) {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const updateEducatorService = /* GraphQL */ `
  mutation UpdateEducatorService(
    $input: UpdateEducatorServiceInput!
    $condition: ModelEducatorServiceConditionInput
  ) {
    updateEducatorService(input: $input, condition: $condition) {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const deleteEducatorService = /* GraphQL */ `
  mutation DeleteEducatorService(
    $input: DeleteEducatorServiceInput!
    $condition: ModelEducatorServiceConditionInput
  ) {
    deleteEducatorService(input: $input, condition: $condition) {
      id
      educatorID
      educatorRef {
        id
        user
        languages
        skills
        yearOfExp
        profession
        servicesOffered
        category
        subjects
        services {
          nextToken
        }
        pricing
        createdAt
        updatedAt
      }
      title
      servicesOffered
      category
      subjects
      description
      date
      duration
      packages {
        items {
          id
          serviceID
          name
          description
          price
          discountPrice
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        day
        from
        to
      }
      session
      availability
      images
      autoMessage
      createdAt
      status
      updatedAt
    }
  }
`;
export const createEducatorServicePackage = /* GraphQL */ `
  mutation CreateEducatorServicePackage(
    $input: CreateEducatorServicePackageInput!
    $condition: ModelEducatorServicePackageConditionInput
  ) {
    createEducatorServicePackage(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const updateEducatorServicePackage = /* GraphQL */ `
  mutation UpdateEducatorServicePackage(
    $input: UpdateEducatorServicePackageInput!
    $condition: ModelEducatorServicePackageConditionInput
  ) {
    updateEducatorServicePackage(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const deleteEducatorServicePackage = /* GraphQL */ `
  mutation DeleteEducatorServicePackage(
    $input: DeleteEducatorServicePackageInput!
    $condition: ModelEducatorServicePackageConditionInput
  ) {
    deleteEducatorServicePackage(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      price
      discountPrice
      createdAt
      updatedAt
    }
  }
`;
export const createEducatorSessionBooking = /* GraphQL */ `
  mutation CreateEducatorSessionBooking(
    $input: CreateEducatorSessionBookingInput!
    $condition: ModelEducatorSessionBookingConditionInput
  ) {
    createEducatorSessionBooking(input: $input, condition: $condition) {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const updateEducatorSessionBooking = /* GraphQL */ `
  mutation UpdateEducatorSessionBooking(
    $input: UpdateEducatorSessionBookingInput!
    $condition: ModelEducatorSessionBookingConditionInput
  ) {
    updateEducatorSessionBooking(input: $input, condition: $condition) {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const deleteEducatorSessionBooking = /* GraphQL */ `
  mutation DeleteEducatorSessionBooking(
    $input: DeleteEducatorSessionBookingInput!
    $condition: ModelEducatorSessionBookingConditionInput
  ) {
    deleteEducatorSessionBooking(input: $input, condition: $condition) {
      id
      educatorServiceID
      serviceRef {
        id
        educatorID
        educatorRef {
          id
          user
          languages
          skills
          yearOfExp
          profession
          servicesOffered
          category
          subjects
          pricing
          createdAt
          updatedAt
        }
        title
        servicesOffered
        category
        subjects
        description
        date
        duration
        packages {
          nextToken
        }
        schedule {
          day
          from
          to
        }
        session
        availability
        images
        autoMessage
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      dates
      timeslots
      purpose
      message
      packageID
      isAccepted
      zoomSignature
      zoomPassword
      isOnline
      createdAt
      stripeChargeId
      updatedAt
    }
  }
`;
export const createSearchedToken = /* GraphQL */ `
  mutation CreateSearchedToken(
    $input: CreateSearchedTokenInput!
    $condition: ModelSearchedTokenConditionInput
  ) {
    createSearchedToken(input: $input, condition: $condition) {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateSearchedToken = /* GraphQL */ `
  mutation UpdateSearchedToken(
    $input: UpdateSearchedTokenInput!
    $condition: ModelSearchedTokenConditionInput
  ) {
    updateSearchedToken(input: $input, condition: $condition) {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteSearchedToken = /* GraphQL */ `
  mutation DeleteSearchedToken(
    $input: DeleteSearchedTokenInput!
    $condition: ModelSearchedTokenConditionInput
  ) {
    deleteSearchedToken(input: $input, condition: $condition) {
      id
      user
      tokens
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createReportPost = /* GraphQL */ `
  mutation CreateReportPost(
    $input: CreateReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    createReportPost(input: $input, condition: $condition) {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const updateReportPost = /* GraphQL */ `
  mutation UpdateReportPost(
    $input: UpdateReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    updateReportPost(input: $input, condition: $condition) {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportPost = /* GraphQL */ `
  mutation DeleteReportPost(
    $input: DeleteReportPostInput!
    $condition: ModelReportPostConditionInput
  ) {
    deleteReportPost(input: $input, condition: $condition) {
      id
      reporter
      reporterRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      postID
      postRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      createdAt
      updatedAt
    }
  }
`;
export const createOgMetaData = /* GraphQL */ `
  mutation CreateOgMetaData(
    $input: CreateOgMetaDataInput!
    $condition: ModelOgMetaDataConditionInput
  ) {
    createOgMetaData(input: $input, condition: $condition) {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateOgMetaData = /* GraphQL */ `
  mutation UpdateOgMetaData(
    $input: UpdateOgMetaDataInput!
    $condition: ModelOgMetaDataConditionInput
  ) {
    updateOgMetaData(input: $input, condition: $condition) {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteOgMetaData = /* GraphQL */ `
  mutation DeleteOgMetaData(
    $input: DeleteOgMetaDataInput!
    $condition: ModelOgMetaDataConditionInput
  ) {
    deleteOgMetaData(input: $input, condition: $condition) {
      id
      postID
      ogTitle
      ogDescription
      ogVideoUrl
      ogVideoHeight
      ogVideoWidth
      ogVideoType
      ogImageUrl
      ogImageHeight
      ogImageWidth
      ogImageType
      ogDate
      ogSiteName
      ogUrl
      requestUrl
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      sharePostID
      content
      medias
      video
      comments {
        items {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          postID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      channelID
      ogMetaData {
        items {
          id
          postID
          ogTitle
          ogDescription
          ogVideoUrl
          ogVideoHeight
          ogVideoWidth
          ogVideoType
          ogImageUrl
          ogImageHeight
          ogImageWidth
          ogImageType
          ogDate
          ogSiteName
          ogUrl
          requestUrl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      subComments {
        items {
          id
          author
          content
          commentID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          commentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createSubComment = /* GraphQL */ `
  mutation CreateSubComment(
    $input: CreateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    createSubComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateSubComment = /* GraphQL */ `
  mutation UpdateSubComment(
    $input: UpdateSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    updateSubComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubComment = /* GraphQL */ `
  mutation DeleteSubComment(
    $input: DeleteSubCommentInput!
    $condition: ModelSubCommentConditionInput
  ) {
    deleteSubComment(input: $input, condition: $condition) {
      id
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      likes {
        items {
          id
          user
          subCommentID
          createdAt
          updatedAt
        }
        nextToken
      }
      timestamp
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      user
      postID
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLikeComment = /* GraphQL */ `
  mutation CreateLikeComment(
    $input: CreateLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    createLikeComment(input: $input, condition: $condition) {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLikeComment = /* GraphQL */ `
  mutation UpdateLikeComment(
    $input: UpdateLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    updateLikeComment(input: $input, condition: $condition) {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikeComment = /* GraphQL */ `
  mutation DeleteLikeComment(
    $input: DeleteLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    deleteLikeComment(input: $input, condition: $condition) {
      id
      user
      commentID
      comment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLikeSubComment = /* GraphQL */ `
  mutation CreateLikeSubComment(
    $input: CreateLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    createLikeSubComment(input: $input, condition: $condition) {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLikeSubComment = /* GraphQL */ `
  mutation UpdateLikeSubComment(
    $input: UpdateLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    updateLikeSubComment(input: $input, condition: $condition) {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikeSubComment = /* GraphQL */ `
  mutation DeleteLikeSubComment(
    $input: DeleteLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    deleteLikeSubComment(input: $input, condition: $condition) {
      id
      user
      subCommentID
      subComment {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      seen
      postID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      post {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        sharePostID
        content
        medias
        video
        comments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        channelID
        ogMetaData {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationComment = /* GraphQL */ `
  mutation CreateNotificationComment(
    $input: CreateNotificationCommentInput!
    $condition: ModelNotificationCommentConditionInput
  ) {
    createNotificationComment(input: $input, condition: $condition) {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationComment = /* GraphQL */ `
  mutation UpdateNotificationComment(
    $input: UpdateNotificationCommentInput!
    $condition: ModelNotificationCommentConditionInput
  ) {
    updateNotificationComment(input: $input, condition: $condition) {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationComment = /* GraphQL */ `
  mutation DeleteNotificationComment(
    $input: DeleteNotificationCommentInput!
    $condition: ModelNotificationCommentConditionInput
  ) {
    deleteNotificationComment(input: $input, condition: $condition) {
      id
      type
      seen
      commentID
      commentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        postID
        post {
          id
          author
          sharePostID
          content
          medias
          video
          timestamp
          isDeleted
          channelID
          createdAt
          updatedAt
        }
        subComments {
          nextToken
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationSubComment = /* GraphQL */ `
  mutation CreateNotificationSubComment(
    $input: CreateNotificationSubCommentInput!
    $condition: ModelNotificationSubCommentConditionInput
  ) {
    createNotificationSubComment(input: $input, condition: $condition) {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationSubComment = /* GraphQL */ `
  mutation UpdateNotificationSubComment(
    $input: UpdateNotificationSubCommentInput!
    $condition: ModelNotificationSubCommentConditionInput
  ) {
    updateNotificationSubComment(input: $input, condition: $condition) {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationSubComment = /* GraphQL */ `
  mutation DeleteNotificationSubComment(
    $input: DeleteNotificationSubCommentInput!
    $condition: ModelNotificationSubCommentConditionInput
  ) {
    deleteNotificationSubComment(input: $input, condition: $condition) {
      id
      type
      seen
      subCommentID
      subCommentRef {
        id
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        content
        commentID
        comment {
          id
          author
          content
          postID
          timestamp
          isDeleted
          createdAt
          updatedAt
        }
        likes {
          nextToken
        }
        timestamp
        isDeleted
        createdAt
        updatedAt
      }
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationFollow = /* GraphQL */ `
  mutation CreateNotificationFollow(
    $input: CreateNotificationFollowInput!
    $condition: ModelNotificationFollowConditionInput
  ) {
    createNotificationFollow(input: $input, condition: $condition) {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationFollow = /* GraphQL */ `
  mutation UpdateNotificationFollow(
    $input: UpdateNotificationFollowInput!
    $condition: ModelNotificationFollowConditionInput
  ) {
    updateNotificationFollow(input: $input, condition: $condition) {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationFollow = /* GraphQL */ `
  mutation DeleteNotificationFollow(
    $input: DeleteNotificationFollowInput!
    $condition: ModelNotificationFollowConditionInput
  ) {
    deleteNotificationFollow(input: $input, condition: $condition) {
      id
      type
      seen
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createFirebaseMessaging = /* GraphQL */ `
  mutation CreateFirebaseMessaging(
    $input: CreateFirebaseMessagingInput!
    $condition: ModelFirebaseMessagingConditionInput
  ) {
    createFirebaseMessaging(input: $input, condition: $condition) {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const updateFirebaseMessaging = /* GraphQL */ `
  mutation UpdateFirebaseMessaging(
    $input: UpdateFirebaseMessagingInput!
    $condition: ModelFirebaseMessagingConditionInput
  ) {
    updateFirebaseMessaging(input: $input, condition: $condition) {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const deleteFirebaseMessaging = /* GraphQL */ `
  mutation DeleteFirebaseMessaging(
    $input: DeleteFirebaseMessagingInput!
    $condition: ModelFirebaseMessagingConditionInput
  ) {
    deleteFirebaseMessaging(input: $input, condition: $condition) {
      id
      user
      token
      createdAt
      updatedAt
    }
  }
`;
export const createFollow = /* GraphQL */ `
  mutation CreateFollow(
    $input: CreateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    createFollow(input: $input, condition: $condition) {
      user
      follower
      userInstance {
        name
        displayName
        # email
        loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # stripeAccountKey
        # # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFollow = /* GraphQL */ `
  mutation UpdateFollow(
    $input: UpdateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    updateFollow(input: $input, condition: $condition) {
      user
      follower
      userInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollow = /* GraphQL */ `
  mutation DeleteFollow(
    $input: DeleteFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    deleteFollow(input: $input, condition: $condition) {
      user
      follower
      userInstance {
        name
        displayName
        # email
        # loginType
        # university
        # universityName
        # studyArea
        # studyAreaName
        # course
        # courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        # isFirstLogin
        # birthday
        # gender
        # location
        uniEmail
        isUniEmailVerify
        # isIntroClosed
        # stripeAccountKey
        # # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      followerInstance {
        name
        displayName
        # email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserConversation = /* GraphQL */ `
  mutation CreateUserConversation(
    $input: CreateUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    createUserConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateUserConversation = /* GraphQL */ `
  mutation UpdateUserConversation(
    $input: UpdateUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    updateUserConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserConversation = /* GraphQL */ `
  mutation DeleteUserConversation(
    $input: DeleteUserConversationInput!
    $condition: ModelUserConversationConditionInput
  ) {
    deleteUserConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      receiver
      receiverRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          conversationID
          sender
          content
          isSent
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      conversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSent
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createUserGroupConversation = /* GraphQL */ `
  mutation CreateUserGroupConversation(
    $input: CreateUserGroupConversationInput!
    $condition: ModelUserGroupConversationConditionInput
  ) {
    createUserGroupConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const updateUserGroupConversation = /* GraphQL */ `
  mutation UpdateUserGroupConversation(
    $input: UpdateUserGroupConversationInput!
    $condition: ModelUserGroupConversationConditionInput
  ) {
    updateUserGroupConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserGroupConversation = /* GraphQL */ `
  mutation DeleteUserGroupConversation(
    $input: DeleteUserGroupConversationInput!
    $condition: ModelUserGroupConversationConditionInput
  ) {
    deleteUserGroupConversation(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      conversationID
      conversationRef {
        id
        groupName
        groupImageURL
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      isDeleted
      isBlocked
      isAdmin
      createdAt
      updatedAt
    }
  }
`;
export const createGroupConversation = /* GraphQL */ `
  mutation CreateGroupConversation(
    $input: CreateGroupConversationInput!
    $condition: ModelGroupConversationConditionInput
  ) {
    createGroupConversation(input: $input, condition: $condition) {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupConversation = /* GraphQL */ `
  mutation UpdateGroupConversation(
    $input: UpdateGroupConversationInput!
    $condition: ModelGroupConversationConditionInput
  ) {
    updateGroupConversation(input: $input, condition: $condition) {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupConversation = /* GraphQL */ `
  mutation DeleteGroupConversation(
    $input: DeleteGroupConversationInput!
    $condition: ModelGroupConversationConditionInput
  ) {
    deleteGroupConversation(input: $input, condition: $condition) {
      id
      groupName
      groupImageURL
      messages {
        items {
          id
          groupConversationID
          sender
          content
          isSeen
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupMessage = /* GraphQL */ `
  mutation CreateGroupMessage(
    $input: CreateGroupMessageInput!
    $condition: ModelGroupMessageConditionInput
  ) {
    createGroupMessage(input: $input, condition: $condition) {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupMessage = /* GraphQL */ `
  mutation UpdateGroupMessage(
    $input: UpdateGroupMessageInput!
    $condition: ModelGroupMessageConditionInput
  ) {
    updateGroupMessage(input: $input, condition: $condition) {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupMessage = /* GraphQL */ `
  mutation DeleteGroupMessage(
    $input: DeleteGroupMessageInput!
    $condition: ModelGroupMessageConditionInput
  ) {
    deleteGroupMessage(input: $input, condition: $condition) {
      id
      groupConversationID
      sender
      senderRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      content
      isSeen
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createBookmarkJob = /* GraphQL */ `
  mutation CreateBookmarkJob(
    $input: CreateBookmarkJobInput!
    $condition: ModelBookmarkJobConditionInput
  ) {
    createBookmarkJob(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBookmarkJob = /* GraphQL */ `
  mutation UpdateBookmarkJob(
    $input: UpdateBookmarkJobInput!
    $condition: ModelBookmarkJobConditionInput
  ) {
    updateBookmarkJob(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookmarkJob = /* GraphQL */ `
  mutation DeleteBookmarkJob(
    $input: DeleteBookmarkJobInput!
    $condition: ModelBookmarkJobConditionInput
  ) {
    deleteBookmarkJob(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      jobId
      jobRef {
        id
        title
        createdAt
        type
        location
        jobArea
        description
        author
        authorRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        salary
        commitment
        length
        skills
        url
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      title
      createdAt
      type
      location
      jobArea
      description
      author
      authorRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      salary
      commitment
      length
      skills
      url
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      category
      startDate
      endDate
      zoomLink
      city
      address
      presenter
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      url
      image
      videoUrl
      status
      createdAt
      updatedAt
    }
  }
`;
export const createEventBookmark = /* GraphQL */ `
  mutation CreateEventBookmark(
    $input: CreateEventBookmarkInput!
    $condition: ModelEventBookmarkConditionInput
  ) {
    createEventBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEventBookmark = /* GraphQL */ `
  mutation UpdateEventBookmark(
    $input: UpdateEventBookmarkInput!
    $condition: ModelEventBookmarkConditionInput
  ) {
    updateEventBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventBookmark = /* GraphQL */ `
  mutation DeleteEventBookmark(
    $input: DeleteEventBookmarkInput!
    $condition: ModelEventBookmarkConditionInput
  ) {
    deleteEventBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      eventID
      eventRef {
        id
        title
        category
        startDate
        endDate
        zoomLink
        city
        address
        presenter
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        url
        image
        videoUrl
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      title
      category
      educator
      offerer
      offererRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      description
      price
      discountPrice
      method
      city
      address
      image
      videoUrl
      status
      url
      createdAt
      updatedAt
    }
  }
`;
export const createCourseBookmark = /* GraphQL */ `
  mutation CreateCourseBookmark(
    $input: CreateCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    createCourseBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseBookmark = /* GraphQL */ `
  mutation UpdateCourseBookmark(
    $input: UpdateCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    updateCourseBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseBookmark = /* GraphQL */ `
  mutation DeleteCourseBookmark(
    $input: DeleteCourseBookmarkInput!
    $condition: ModelCourseBookmarkConditionInput
  ) {
    deleteCourseBookmark(input: $input, condition: $condition) {
      id
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      courseID
      courseRef {
        id
        title
        category
        educator
        offerer
        offererRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        description
        price
        discountPrice
        method
        city
        address
        image
        videoUrl
        status
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      user
      name
      isWorking
      startDate
      endDate
      contributors
      associatedWith
      projectURL
      description
      createdAt
      updatedAt
    }
  }
`;
export const createCertificate = /* GraphQL */ `
  mutation CreateCertificate(
    $input: CreateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    createCertificate(input: $input, condition: $condition) {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const updateCertificate = /* GraphQL */ `
  mutation UpdateCertificate(
    $input: UpdateCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    updateCertificate(input: $input, condition: $condition) {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const deleteCertificate = /* GraphQL */ `
  mutation DeleteCertificate(
    $input: DeleteCertificateInput!
    $condition: ModelCertificateConditionInput
  ) {
    deleteCertificate(input: $input, condition: $condition) {
      id
      user
      name
      description
      completeDate
      studyArea
      url
      grade
      createdAt
      updatedAt
    }
  }
`;
export const createCourseFeed = /* GraphQL */ `
  mutation CreateCourseFeed(
    $input: CreateCourseFeedInput!
    $condition: ModelCourseFeedConditionInput
  ) {
    createCourseFeed(input: $input, condition: $condition) {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const updateCourseFeed = /* GraphQL */ `
  mutation UpdateCourseFeed(
    $input: UpdateCourseFeedInput!
    $condition: ModelCourseFeedConditionInput
  ) {
    updateCourseFeed(input: $input, condition: $condition) {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const deleteCourseFeed = /* GraphQL */ `
  mutation DeleteCourseFeed(
    $input: DeleteCourseFeedInput!
    $condition: ModelCourseFeedConditionInput
  ) {
    deleteCourseFeed(input: $input, condition: $condition) {
      id
      title
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      category
      topics
      headline
      description
      type
      price
      discountPrice
      image
      video
      createdAt
      status
      updatedAt
    }
  }
`;
export const createCourseFeedSubscriber = /* GraphQL */ `
  mutation CreateCourseFeedSubscriber(
    $input: CreateCourseFeedSubscriberInput!
    $condition: ModelCourseFeedSubscriberConditionInput
  ) {
    createCourseFeedSubscriber(input: $input, condition: $condition) {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseFeedSubscriber = /* GraphQL */ `
  mutation UpdateCourseFeedSubscriber(
    $input: UpdateCourseFeedSubscriberInput!
    $condition: ModelCourseFeedSubscriberConditionInput
  ) {
    updateCourseFeedSubscriber(input: $input, condition: $condition) {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseFeedSubscriber = /* GraphQL */ `
  mutation DeleteCourseFeedSubscriber(
    $input: DeleteCourseFeedSubscriberInput!
    $condition: ModelCourseFeedSubscriberConditionInput
  ) {
    deleteCourseFeedSubscriber(input: $input, condition: $condition) {
      id
      courseFeed
      courseFeedRef {
        id
        title
        user
        userRef {
          name
          displayName
          email
          loginType
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          headline
          interestedChannels
          photoURL
          coverPhotoURL
          isPartner
          isFirstLogin
          birthday
          gender
          location
          uniEmail
          isUniEmailVerify
          isIntroClosed
          stripeAccountKey
          # stripeConnectedAccountKey
          createdAt
          updatedAt
        }
        category
        topics
        headline
        description
        type
        price
        discountPrice
        image
        video
        createdAt
        status
        updatedAt
      }
      user
      userRef {
        name
        displayName
        email
        loginType
        university
        universityName
        studyArea
        studyAreaName
        course
        courseName
        studyStatus
        about
        headline
        interestedChannels
        photoURL
        coverPhotoURL
        posts {
          nextToken
        }
        notifications {
          nextToken
        }
        followers {
          nextToken
        }
        following {
          nextToken
        }
        isPartner
        isFirstLogin
        birthday
        gender
        location
        uniEmail
        isUniEmailVerify
        isIntroClosed
       # stripeAccountKey
   #     # stripeConnectedAccountKey
        createdAt
        updatedAt
      }
      stripeChargeId
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      category
      name
      createdAt
      updatedAt
    }
  }
`;
